import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RoofService} from 'src/app/_core/services/areas/roof.service';
import {ProfileService} from 'src/app/_core/services/profile.service';
import {ProjectService} from 'src/app/_core/services/project.service';
import {ChosenProfileService} from '../../_core/services/chosen-elements/chosen-profile.service';
import {ToolboxItemService} from '../../_core/services/toolbox-item.service';
import {SvgParams} from '../../_core/constants/constants';
import { ElementSide } from 'src/app/_core/interfaces/IElementSide';
import { LED } from 'src/app/_core/models/profiles/leds.model';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { LedPattern } from 'src/app/_core/models/leds-patterns';
import { LEDsTemplateDialog } from 'src/app/dialogs/leds-template/leds-template.dialog';
import { ElementType } from 'src/app/_core/models/ToolboxModel';
import { Common } from 'src/app/_core/models/common';

@Component({
	selector: 'card-leds-pattern',
	templateUrl: './card-leds-pattern.component.html',
})
export class LEDsPatternComponent implements OnInit {
	@ViewChild('numTop', { static: true }) numTop: NumTbxComponent;
	@ViewChild('numBot', { static: true }) numBot: NumTbxComponent;
	@ViewChild('patternDialog', { static: true }) patternDialog: LEDsTemplateDialog;

	current: LED;

	showPatterns: boolean = false;
	ledPatternChange(v: LedPattern) {
		if (v != null) {
			this.roofService.selectedLedPattern(v);
			this.projectService.freezeProjProp();
		} else {
			this.patternDialog.item = this.projectService.ledPattern;
		}
		this.showPatterns = false;
		if (!this.projectService.calculateRemote()) {
			this.projectService.emitChange();
		}
	}
	@Input() public set items(items: LED[]) {
		this.current = items[0];
		if (this.numTop.valueChanged.observers.length == 0) {
			this.numTop.valueChanged.subscribe(v => {
				this.distanceTop = v;
			});
		}
		if (this.numBot.valueChanged.observers.length == 0) {
			this.numBot.valueChanged.subscribe(v => {
				this.distanceBottom = v;
			});
		}
	}

	get locationX() {
		return (this.current.circle.x - SvgParams.START_X) / SvgParams.SCALE;
	}
	set locationX(location: number) {
		// readonly
	}

	get locationY() {
		return (this.current.circle.y - SvgParams.START_Y) / SvgParams.SCALE;
	}

	get distanceTop() {
		var rear = this.profileService.roofElements[ElementType.WallProfile][0];
		return Math.round(this.locationY - rear.rectOnRoof.h / SvgParams.SCALE);
	}
	set distanceTop (location: number) {
		var rear = this.profileService.roofElements[ElementType.WallProfile][0];
		this.current.circle.y = Common.round(SvgParams.START_Y + location * SvgParams.SCALE + rear.rectOnRoof.h);
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	get distanceBottom() {		
		var front = this.profileService.roofElements[ElementType.Front][0];
		var frontY = (front.rectOnRoof.y - SvgParams.START_Y) / SvgParams.SCALE;
		return Math.round(frontY - this.locationY);
	}
	set distanceBottom (location: number) {
		var front = this.profileService.roofElements[ElementType.Front][0];
		var newl = Common.round(front.rectOnRoof.y - location * SvgParams.SCALE);
		this.current.circle.y = newl;
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	get maxDistance(){
		var front = this.profileService.roofElements[ElementType.Front][0];
		var rear = this.profileService.roofElements[ElementType.WallProfile][0];
		var frontY = (front.rectOnRoof.y - SvgParams.START_Y - rear.rectOnRoof.h) / SvgParams.SCALE;
		return Math.round(frontY);
	}

	constructor(
		public roofService: RoofService,
		public profileService: ProfileService,
		public projectService: ProjectService,
		public chosenProfileService: ChosenProfileService,
		public toolboxItemService: ToolboxItemService,
	) {
	}
	ngOnInit(): void {
		this.patternDialog.item = this.projectService.ledPattern;
	}

	onLocationXBtnClick(side: 'Left' | 'Right') {
		this.chosenProfileService.changeLedsLocationX(ElementSide[side]);
		this.projectService.recalculateDimensions()
		this.projectService.emitChange();
	}

	changeLocationXTooltip(side: 'Left' | 'Right'): string {
		this.chosenProfileService.isLedsChangeLocationXBtnDisable(ElementSide[side]);
		return this.chosenProfileService.ledsTooltip;
	}

	isChangeLocationXBtnDisable(side: string): boolean {
		return this.chosenProfileService.isLedsChangeLocationXBtnDisable(ElementSide[side]);
	}

	getMinYLocation(): number {
		var minLoc = this.chosenProfileService.getLedsMinYLocation();
		return minLoc;
	}

	getMaxYLocation() {		
		var maxLoc = this.chosenProfileService.getLedsMaxYLocation();
		return maxLoc;
	}

	deleteLed() {
		this.projectService.ledPattern = undefined;
		this.chosenProfileService.setChosenProfile(null);
		this.projectService.deleteElement(this.current);
		this.projectService.recalculateDimensions();
	}

}
