import { Injectable } from "@angular/core";
import { SvgParams, Tags } from "../../constants/constants";
import { IPoint } from "../../interfaces/IPoint";
import { Orientation, ElementType, ToolboxItem, ToolboxItemType } from "../../models/ToolboxModel";
import { ProjectService } from "../project.service";
import { AreaType, CollectionName } from "../../interfaces/IAreaType";
import { ProfileService } from '../profile.service';
import { WallProfile } from '../../models/profiles/wall.model';
import { GutterProfile } from '../../models/profiles/gutter.model';
import { ColumnProfile } from '../../models/profiles/column.model';
import { BarProfile } from '../../models/profiles/bar.model';
import { Glass } from "../../models/glasses/glass.model";
import { ApiService } from "src/app/_core/services/api.service";
import { ICalcInput } from "src/app/_core/interfaces/ICalculations";
import { FrontService } from "./front.service";
import { LocationService } from "../location.service";
import { LeftSideService } from './left-side.service';
import { RightSideService } from './right-side.service';
import { FrontProfile } from "../../models/profiles/front.model";
import { ProjectTemplate } from "../../template";
import { ColumnParameter, GlassParameter, RoofWindowParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { Common } from '../../models/common';
import { LedPattern } from "../../models/leds-patterns";
import { RoofWindow } from '../../models/glasses/roofWindow.model';
import { ExtraOptionService } from "../extra-option.service";
import { IAreaCanvas, IAreaService } from "../../interfaces/IAreaService";
import { RoofVentilator } from "../../models/glasses/roofVentilator.model";
import { RoofGutter } from "../../models/glasses/roofGutter.model";
import { Space } from "../../models/space";
import { IPointer } from "../../interfaces/IPointer";
import { MarquiseVertical } from "../../models/marquises/marquise-vertical.model";
import { GlassWallBase } from "../../models/glasses/glassWall-base.model";
import { RearService } from "./rear.service";

@Injectable({
	providedIn: 'root'
})
export class RoofService implements IAreaCanvas, IAreaService {

	private _points: IPoint[] = [];

	private _workAreaPoints: IPoint[] = [];
	private _workAreaWidth: number;
	private _workAreaDepth: number;

	private _svg: string;

	private get width() {
		return this.projectService.template.width;
	}

	private get depth() {
		return this.projectService.realDepth;
	}

	private services: Map<AreaType, IAreaService>;

	constructor(
		private profileService: ProfileService,
		private projectService: ProjectService,
		private frontService: FrontService,
		private rearService: RearService,
		private leftSideService: LeftSideService,
		private rightSideService: RightSideService,
		private api: ApiService,
		private locationService: LocationService,
		private extraService: ExtraOptionService
	) {

		this.services = Common.getServices(
			[this.frontService, this.leftSideService, this.rightSideService, this.rearService]);

		this.projectService.templateChangedSubject.subscribe(t => {
			this.projectService.template = t;
			this.applyTemplate("", "");
		})
	}
	getGWDStartPoints(currentAreaElements?: any): IPoint[] {
		throw new Error("Method not implemented.");
	}
	manageFrameAfterGlassChange(glass: GlassWallBase) {
		throw new Error("Method not implemented.");
	}
	createMuntins(glass: GlassWallBase, muntinsCount: number) {
		throw new Error("Method not implemented.");
	}
	get areaType(): AreaType {
		return AreaType.Roof;
	}
	get rectOnSide(): string {
		return 'rectOnRoof';
	}

	// Main method for displaying all elements of roof area

	showArea() {
		if (this.profileService.roofElements === null) {
			this.profileService.roofElementsInit();
			this.calculatePoints();
			this.applyTemplate("", "");
		}
	}

	getColTopYPos(col: ColumnProfile): number {
		return this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - Math.round(col.depth * SvgParams.SCALE)
	}

	public changeArea(): void {
		this.profileService.roofElements = null;
		this.showArea();
		if (this.projectService.ledPattern) {
			this.projectService.addLedAndConnectorToBar(this.profileService.roofElements.Bar);
			this.projectService.arrangeLeds();
		}
	}

	public calculatePoints() {
		this._workAreaPoints = [];
		this._points = [];
		this._svg = "";

		if (this.width === 0 || this.depth === 0) {
			return;
		}

		this._points.push({ x: SvgParams.START_X, y: SvgParams.START_Y });
		this._points.push({ x: SvgParams.START_X + this.width * SvgParams.SCALE, y: SvgParams.START_Y });
		this._points.push({ x: SvgParams.START_X + this.width * SvgParams.SCALE, y: SvgParams.START_Y + this.depth * SvgParams.SCALE });
		this._points.push({ x: SvgParams.START_X, y: SvgParams.START_Y + this.depth * SvgParams.SCALE });

		const sideWidth = this.projectService.template.getBarSize().width;
		const frontDepth = this.projectService.template.getFrontSize().depth;
		const wallDepth = this.projectService.template.getRearSize().depth;

		const sw = sideWidth * SvgParams.SCALE;
		const wd = wallDepth * SvgParams.SCALE;
		const gd = frontDepth * SvgParams.SCALE;

		this._workAreaPoints.push({ x: this._points[SvgParams.LEFT_TOP_POINT_NUM].x + sw, y: this._points[SvgParams.LEFT_TOP_POINT_NUM].y + wd });
		this._workAreaPoints.push({ x: this._points[SvgParams.RIGHT_TOP_POINT_NUM].x - sw, y: this._points[SvgParams.RIGHT_TOP_POINT_NUM].y + wd });
		this._workAreaPoints.push({ x: this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - sw, y: this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - gd });
		this._workAreaPoints.push({ x: this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].x + sw, y: this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - gd });

		this._workAreaWidth = this.width - sideWidth * 2;
		this._workAreaDepth = this.depth - wallDepth - frontDepth;
	}

	public setBars(barsCount: number) {
		const template = this.projectService.template;
		const glassDef = template.getDefaultGlass();

		var firstBar = this.profileService.roofElements[ElementType.SideFinish][0];
		var lastBar = this.profileService.roofElements[ElementType.SideFinish][1];

		this.profileService.roofElements[ElementType.Bar] = [];
		this.profileService.roofElements[ElementType.Glass] = [];

		this.addGlassToRect(glassDef.id, this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y, this.width, this._workAreaDepth, barsCount, firstBar, lastBar);
		this.frontService.changeArea();
	}

	public calculateGlassWidth(barsCount: number, w: number) {
		const template = this.projectService.template;
		const barW = template.getBarSize().width;
		const glassDef = template.getDefaultGlass();
		const maxWidth = template.maxGlassWidth(glassDef.id) - glassDef.statics.paddingLeft - glassDef.statics.paddingRight
		var glassCount = barsCount - 1;
		var ledgesWidth = barsCount * barW;

		var glassWidth = Common.round(((w - ledgesWidth) / glassCount));
		while (glassWidth > maxWidth) {
			ledgesWidth = glassCount * barW;
			glassCount++;
			glassWidth = Common.round(((w - ledgesWidth) / glassCount));
		}

		return { glassWidth, glassCount };
	}

	private addGlassToRect(catId: string, y: number, w: number, h: number, barsCount: number, firstBar: BarProfile, lastBar: BarProfile) {

		const template = this.projectService.template;
		const cw = this.calculateGlassWidth(barsCount, w);
		const glassDef = template.getDefaultGlass();

		var glassCount = cw.glassCount;
		var glassWidth = cw.glassWidth;

		let glass: Glass;

		var bar: BarProfile = firstBar;

		const barY = y - glassDef.statics.paddingTop * SvgParams.SCALE;
		const barL = h + (glassDef.statics.paddingBottom + glassDef.statics.paddingTop);

		let x =
			firstBar.rectOnRoof
				?
				firstBar.rectOnRoof.x + firstBar.rectOnRoof.w
				:
				firstBar.lineOnRoof.x1 + firstBar.lineOnRoof.w / 2;

		for (var i = 0; i < glassCount; i++) {
			glass = this.projectService.generateGlass(catId, x, barY, glassWidth, barL, template.glassLength);
			glass.leftBar = bar;
			bar.rightGlass = glass;

			this.profileService.roofElements[ElementType.Glass].push(glass);
			var r = glass.rect;

			if (i < glassCount - 1) {
				const lx = r.x + r.w - Common.round(glass.stat.paddingRight * SvgParams.SCALE);
				bar = new BarProfile(this.projectService.currentArea, lx, barY, template, barL, Orientation.Vertical, AreaType.Roof, template.barId);
				this.profileService.roofElements[ElementType.Bar].push(bar);

				glass.rightBar = bar;
				bar.leftGlass = glass;
				x += Common.round(glassWidth * SvgParams.SCALE) + bar.rectOnRoof.w;
			}
		}
		if (lastBar == null) {
			const blx = glass.rect.x + glass.rect.w - Common.round(glass.stat.paddingRight * SvgParams.SCALE);
			lastBar = new BarProfile(this.projectService.currentArea, blx, barY, template, barL, Orientation.Vertical, AreaType.Roof, template.barId);
			this.profileService.roofElements[ElementType.Bar].push(lastBar);
		}
		bar = lastBar;
		glass.rightBar = bar;
		bar.leftGlass = glass;

		return bar;
	}

	changeColumnType(col: ColumnProfile, newId: string) {
		col.configId = newId;
		this._changeColumnType(col);

		const def = this.projectService.template.columns.find(c => c.id == newId);
		if (def.independent) {
			return;
		}

		var cols = this.profileService.getDependentColumns(this.projectService.template.columns).filter(c => c.id != col.id);

		cols.forEach(c => {
			if (c.isRear && col.isRear || !c.isRear && !col.isRear) {
				if (newId != c.configId) {
					c.configId = newId;
					this._changeColumnType(c);
				}
			}
		});
	}

	private _changeColumnType(col: ColumnProfile) {
		const coldef = this.projectService.template.columns.find(c => c.id == col.configId);
		const oldColW = col.width;
		col.changeType(coldef);

		if (!col.rectOnRoof) {
			return;
		}
		const cold = coldef.size.depth;
		col.rectOnRoof.w = col.width * SvgParams.SCALE;
		col.rectOnRoof.h = cold * SvgParams.SCALE;

		if (col.tag == Tags.LEFT_COLUMN_TAG) {
			const front = this.profileService.getFrontOnSide(this.profileService[CollectionName.get(AreaType.Left)], Tags.LEFT_FRONT_TAG);
			col.rectOnLeft.x = front.rectOnLeft.x;
		} else if (col.tag == Tags.LEFT_REAR_COLUMN_TAG) {
		} else if (col.tag == Tags.RIGHT_COLUMN_TAG
			|| this.profileService.rightSideElements[ElementType.Column].includes(col)) {
			col.rectOnRoof.x = col.rectOnRoof.x + (oldColW - col.width) * SvgParams.SCALE;
		} else {
			const center = this.locationService.getLocation(col, AreaType.Roof) + oldColW / 2;
			this.locationService.setLocation(col, center - col.width / 2, AreaType.Roof, false);
		}

		if (col.rectOnFront) {
			col.rectOnFront.w = col.rectOnRoof.w;
			col.rectOnFront.x = col.rectOnRoof.x;
		}
		if (col.rectOnRoof) {
			col.rectOnRoof.w = col.rectOnRoof.w;
			col.rectOnRoof.x = col.rectOnRoof.x;
		}
		if (!col.foundation || !this.projectService.template.hasFoundation(col.configId, col.foundation.id)) {
			col.foundation = this.projectService.template.getDefaultFoundationForColumn(col.configId);
		}

		var newh = coldef.size.height;

		if (newh < 0) {
			newh = col.isFront ? this.projectService.template.columnLength : this.projectService.template.backHeight;
			col.isWinkel = false;
		} else {
			col.isWinkel = true;
		}

		col.setLength(newh);
		newh *= SvgParams.SCALE;


		if (col.rectOnFront) {
			col.rectOnFront.h = newh;
		}
		if (col.rectOnRear) {
			col.rectOnRear.h = newh;
		}
		if (col.rectOnLeft) {
			col.rectOnLeft.h = newh;
			col.rectOnLeft.w = cold * SvgParams.SCALE;
		}
		if (col.rectOnRight) {
			col.rectOnRight.h = newh;
			col.rectOnRight.w = cold * SvgParams.SCALE;
		}
	}

	public applyTemplate(frontId?: string, rearId?: string, glassId?: string, elongLeft?: number, elongRight?: number) {
		var template = this.projectService.template;
		if (!template.toCalculate) {
			template.toCalculate = true;
			return;
		}
		// this._catId = template.glassId;

		if (elongLeft != null) {
			template.elongLeft = elongLeft;
		}
		if (elongRight != null) {
			template.elongRight = elongRight;
		}

		var fid: string = null;
		if (frontId != null) {
			if (frontId == "") {
				fid = template.frontId;
			} else {
				fid = frontId;
			}
		}

		var rid: string = null;
		if (rearId != null) {
			if (frontId == "") {
				rid = template.rearId;
			} else {
				rid = rearId;
			}
		}

		const input: ICalcInput = {
			frontId: fid,
			rearId: rid,
			glassId: glassId ? glassId : template.glassId,
			windZone: template.windZone,
			snowZone: template.snowZone,
			width: template.width,
			depth: template.depth,
			backHeight: template.backHeight,
			isStandalone: template.isStandalone,
			dropAngle: template.dropAngle,
			elongLeft: template.elongLeft,
			elongRight: template.elongRight,
			useStatics: this.projectService.useStatics
		};

		this.api.calculateConstruction(template.productId, template.brandName, input).subscribe(res => {
			if (res.ok) {
				var seg = res.segments[0];
				template.frontId = seg.frontId;
				template.rearId = seg.rearId;
				template.frontColumnId = template.fronts.find(f => f.id == seg.frontId).columns.find(c => c.isDefault).id;
				template.barLength = seg.barLength;
				template.glassLength = seg.glassLength;
				template.deckelLength = seg.deckelLength;
				template.columnLength = seg.columnLength;
				template.frontHeight = seg.frontHeight;
				template.deflections = seg.deflections;
				template.rearReinforcementDepth = seg.rearReinforcementDepth;

				template.barsCount = 0;
				res.segments.forEach(s => { template.barsCount += s.barsCount });
				// template.barsCount -= (res.segments.length - 1);

				if (glassId) {
					template.glassId = glassId;
				}
				const rear = template.rears.find(f => f.id == seg.rearId);
				if (template.isStandalone) {
					template.rearColumnId = rear.columns.find(c => c.isDefault).id;
				}
				template.barId = seg.barId;

				//#region Clear objects
				this.profileService.elementsInit();
				this.projectService.ledPattern = null;
				//#endregion

				this.calculatePoints();

				const frontColumn = template.columns.find(c => c.id == template.frontColumnId);
				const frontSize = this.projectService.template.getFrontSize();
				const rearColumn = template.columns.find(c => c.id == template.rearColumnId);
				const rearSize = this.projectService.template.getRearSize();
				const swSize = template.getBarSize();
				const glassDef = template.getDefaultGlass();

				var startX = this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].x;

				const swTop = this._points[SvgParams.LEFT_TOP_POINT_NUM].y + (rearSize.depth - glassDef.statics.paddingTop) * SvgParams.SCALE;
				const swBottom = this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - frontSize.depth * SvgParams.SCALE + glassDef.statics.paddingBottom * SvgParams.SCALE;

				this.profileService.createOrUpdateProfileElement(template, 'roofElements', 'lineOnRoof', Tags.LEFT_SIDEFINISH_TAG, ElementType.SideFinish,
					this._points[SvgParams.LEFT_TOP_POINT_NUM].x + (Math.round(swSize.width * SvgParams.SCALE) / 2), swTop,
					template.barLength, this.projectService.currentArea, AreaType.Roof, null,
					this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].x + (Math.round(swSize.width * SvgParams.SCALE) / 2), swBottom);

				this.profileService.createOrUpdateProfileElement(template, 'roofElements', 'lineOnRoof', Tags.RIGHT_SIDEFINISH_TAG, ElementType.SideFinish,
					this._points[SvgParams.RIGHT_TOP_POINT_NUM].x - (Math.round(swSize.width * SvgParams.SCALE) / 2), swTop,
					template.barLength, this.projectService.currentArea, AreaType.Roof, null,
					this._points[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - (Math.round(swSize.width * SvgParams.SCALE) / 2), swBottom);

				if (template.isStandalone) {
					var lrCol = this.createColumn(Tags.LEFT_REAR_COLUMN_TAG, template, startX, 0, rearColumn, rearSize.height, rearSize.height, null, true);
				}

				let prevFront: FrontProfile;
				let prevRear: WallProfile;
				const fy = this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - Common.round(frontSize.depth * SvgParams.SCALE);

				for (var fi = 0; fi < res.segments.length; fi++) {
					const s = res.segments[fi];

					var ell = 0;
					var elr = 0;

					if (fi == 0) {
						ell = template.elongLeft;
					}
					if (fi == res.segments.length - 1) {
						elr = template.elongRight;
					}

					var f: FrontProfile = new FrontProfile(this.projectService.currentArea, startX, fy, template, s.width, AreaType.Roof, template.frontId, ell, elr);
					f.tag = this.getFrontTag(res.segments.length, fi);

					this.profileService.roofElements[ElementType.Front].push(f);

					if (f.gutterSize) {
						var g = new GutterProfile(this.projectService.currentArea,
							startX, this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - Math.round(f.gutterSize.depth * SvgParams.SCALE),
							template, s.width, AreaType.Roof, template.frontId);

						g.frontId = f.id;
						f.gutter = g;
						this.profileService.roofElements[ElementType.Gutter].push(g);

					}

					if (fi == 0) {
						var lCol = this.createColumn(Tags.LEFT_COLUMN_TAG, template, startX, -template.elongLeft, frontColumn, frontSize.height, rearSize.height, f, false);
						f.columns.push(lCol);
						lCol.assignFront(f);
					}


					var w: WallProfile = new WallProfile(this.projectService.currentArea, startX, this._points[SvgParams.LEFT_TOP_POINT_NUM].y, template, s.width, AreaType.Roof, template.rearId);
					w.tag = this.getRearTag(res.segments.length, fi);
					this.profileService.roofElements[ElementType.WallProfile].push(w);

					if (fi > 0) {
						var col = this.createColumn('autoCol' + (fi * 100).toString(), template, startX, 0, frontColumn, frontSize.height, rearSize.height, f, false);
						col.connector = true;
						col.rightProfile = f;
						col.leftProfile = prevFront;
						f.columns.push(col);
						col.assignFront(f);
						prevFront.columns.push(col);
						prevFront.rightFront = f;
						f.leftFront = prevFront;
					}

					for (var i = 1; i < s.frontColumns.length - 1; i++) {
						var col = this.createColumn('autoCol' + (fi * 10 + i).toString(), template, startX, s.frontColumns[i], frontColumn, frontSize.height, rearSize.height, f, false);
						f.columns.push(col);
						col.assignFront(f);
					}

					if (template.isStandalone && s.rearColumns?.length > 0) {
						if (fi == 0) {
							w.columns.push(lrCol);
						}

						for (var i = 1; i < s.rearColumns.length - 1; i++) {
							var col = this.createColumn('rAutoCol' + (fi * 10 + i).toString(), template, startX, s.rearColumns[i], rearColumn, rearSize.height, rearSize.height, null, true);
							w.columns.push(col);
						}
						if (fi > 0) {
							var col = this.createColumn('rAutoCol' + (fi * 100).toString(), template, startX, 0, rearColumn, rearSize.height, rearSize.height, null, true);
							col.connector = true;
							col.rightProfile = w;
							col.leftProfile = prevRear;
							w.columns.push(col);
							prevRear.columns.push(col);
							prevRear.rightRear = w;
							w.leftRear = prevRear;
						}
						prevRear = w;
					}

					if (fi < res.segments.length - 1) {
						startX += s.width * SvgParams.SCALE;
					}
					prevFront = f;
				}

				var firstBar = this.profileService.roofElements[ElementType.SideFinish][0];
				var lastBar = this.profileService.roofElements[ElementType.SideFinish][1];
				this.addGlassToRect(this.projectService.template.glassId, this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y, template.width, this._workAreaDepth, template.barsCount, firstBar, lastBar);
				const lastSegment = res.segments[res.segments.length - 1];
				const lastColumn = lastSegment.frontColumns[lastSegment.frontColumns.length - 1];
				var rCol = this.createColumn(Tags.RIGHT_COLUMN_TAG, template, startX, lastColumn, frontColumn, frontSize.height, rearSize.height, f, false);
				f.columns.push(rCol);
				rCol.assignFront(f)

				var rwp: WallProfile;

				if (res.rearSegment) {
					this.profileService.roofElements[ElementType.WallProfile] = [];
					this.profileService.roofElements[ElementType.Column] = this.profileService.roofElements[ElementType.Column].filter(c => !c.isRear);

					startX = SvgParams.START_X;

					const rcls = res.rearSegment.rearColumns;

					var l = 0;
					for (var leftIdx = 0; leftIdx < rcls.length; leftIdx++) {
						var colTag = 'rAutoCol' + (leftIdx * 10).toString();
						if (leftIdx == 0) {
							colTag = Tags.LEFT_REAR_COLUMN_TAG;
						} else if (leftIdx == rcls.length - 1) {
							colTag = Tags.RIGHT_REAR_COLUMN_TAG;
						}

						var col = this.createColumn(colTag, template, SvgParams.START_X, rcls[leftIdx], rearColumn, rearSize.height, rearSize.height, null, true);
						if (leftIdx < rcls.length - 1) {
							l += Math.round(rcls[leftIdx + 1] - rcls[leftIdx]);
						}
						if (!rwp) {
							rwp = new WallProfile(this.projectService.currentArea, startX, this._points[SvgParams.LEFT_TOP_POINT_NUM].y, template, l, AreaType.Roof, template.rearId);
							this.profileService.roofElements[ElementType.WallProfile].push(rwp);
							rwp.tag = Tags.LEFT_REAR_TAG;
							rwp.columns.push(col);
						} else {
							rwp.columns.push(col);
							if (l <= rear.maxLength) {
								rwp.setLength(l);
							} else {
								if (leftIdx < rcls.length - 1) {
									startX = SvgParams.START_X + Math.round(rcls[leftIdx] * SvgParams.SCALE);
									l = Math.round(rcls[leftIdx + 1] - rcls[leftIdx]);
									var next = new WallProfile(this.projectService.currentArea, startX, this._points[SvgParams.LEFT_TOP_POINT_NUM].y, template, l, AreaType.Roof, template.rearId);
									this.profileService.roofElements[ElementType.WallProfile].push(next);
									next.tag = Tags.RIGHT_REAR_TAG;
									next.columns.push(col);

									col.connector = true;
									col.leftProfile = rwp;
									col.rightProfile = next;

									rwp = next;
								}
							}
						}
					}
					var wallProfs = this.profileService.roofElements[ElementType.WallProfile];
					if (wallProfs.length > 2) {
						for(var i = 1; i < wallProfs.length -1; i++) {
							wallProfs[i].tag = Tags.ANY_REAR_TAG;
						}
					}

				} else if (template.isStandalone) {
					var rrCol = this.createColumn(Tags.RIGHT_REAR_COLUMN_TAG, template, startX, lastColumn, rearColumn, rearSize.height, rearSize.height, null, true);
					w.columns.push(rrCol);
				}
				
				var wands = this.profileService.roofElements[ElementType.WallProfile];
				if (wands.length == 1) {
					wands[0].tag = Tags.ANY_REAR_TAG;
				} else {
					wands[0].tag = Tags.LEFT_REAR_TAG;
					for (var i = 1; i < wands.length - 1; i++) {
						wands[i].tag = Tags.ANY_REAR_TAG;
					}
					wands[wands.length - 1].tag = Tags.RIGHT_REAR_TAG;
				}

				if (res.frontSegments) {
					this.profileService.roofElements[ElementType.Front] = [];
					this.profileService.roofElements[ElementType.Column] = this.profileService.roofElements[ElementType.Column].filter(c => c.isRear);

					startX = SvgParams.START_X;
					fi = 0;

					prevFront = null;
					res.frontSegments.forEach(s => {
						ell = 0;
						elr = 0;

						if (fi == 0) {
							ell = res.elongLeft;
						}
						if (fi == res.frontSegments.length - 1) {
							elr = res.elongRight;
						}

						const frontWidth = s.width - ell - elr;
						var f: FrontProfile = new FrontProfile(this.projectService.currentArea,
							startX, this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - Common.round(frontSize.depth * SvgParams.SCALE),
							template, frontWidth, AreaType.Roof, template.frontId, ell, elr);
						this.profileService.roofElements[ElementType.Front].push(f);
						f.tag = this.getFrontTag(res.frontSegments.length, fi);

						if (fi == 0) {
							var lCol = this.createColumn(Tags.LEFT_COLUMN_TAG, template, startX, -res.elongLeft, frontColumn, frontSize.height, rearSize.height, f, false);
							f.columns.push(lCol);
							lCol.assignFront(f)
						}

						for (var i = 1; i < s.columns.length - 1; i++) {
							var col = this.createColumn('autoCol' + (fi * 10 + i).toString(), template, startX, s.columns[i], frontColumn, frontSize.height, rearSize.height, f, false)
							f.columns.push(col);
							col.assignFront(f)
						}

						if (fi > 0 && fi < res.frontSegments.length) {
							var col = this.createColumn('autoCol' + (fi * 100).toString(), template, startX, 0, frontColumn, frontSize.height, rearSize.height, f, false);
							col.connector = true;
							col.rightProfile = f;
							col.leftProfile = prevFront;
							f.columns.push(col);
							col.assignFront(f)
							prevFront.columns.push(col);
							prevFront.rightFront = f;
							f.leftFront = prevFront;
						}

						if (fi == res.frontSegments.length - 1) {
							var rCol = this.createColumn(Tags.RIGHT_COLUMN_TAG, template, startX, s.columns[s.columns.length - 1], frontColumn, frontSize.height, rearSize.height, f, false);
							f.columns.push(rCol);
							col.assignFront(f);
						}

						startX += frontWidth * SvgParams.SCALE;
						fi++;
						prevFront = f;
					});
				}

				template.barsCount = this.profileService.roofElements[ElementType.Bar].length;
				template.staticBarsCount = template.barsCount + 2;
				this.setBars(template.staticBarsCount);

				this.services.forEach(s => s.changeArea());

				var front: FrontProfile = this.profileService.roofElements[ElementType.Front][0];
				if (front.isShiftedDepthAllow) {
					const bar = template.getDefaultBar();
					this.projectService.showGutter(front);
					this.profileService.roofElements[ElementType.Front].forEach(f => {
						this.locationService.setFrontYLocation(f, bar.shiftedDepthMin * SvgParams.SCALE);
					});
					this.profileService.frontElements[ElementType.Column].forEach(c => {
						this.locationService.setColumnYLocation(c, bar.shiftedDepthMin * SvgParams.SCALE);
					});
				}

				if (!template.created) {
					template.created = true;
					this.extraService.setupRequiredExtras();
					this.projectService.projectReady.next(true);
				} else {
					this.extraService.clearAutoOptions();
					this.extraService.collectExtraOptions();
					this.projectService.elementsChanged.next(true);
				}
				this.projectService.clearCollections(ElementType.Dimension);
				this.profileService.justifyColumns(template.width, true);
				this.profileService.justifyColumns(template.width, false);
				this.services.forEach(s => s.changeArea()); // once again after columns justifying
				this.projectService.addBarsConnectors();
				this.profileService.getMarquisesVertical(AreaType.Right).forEach((m: MarquiseVertical) => m.refresh());
				this.projectService.emitChange();
			}
		});
	}

	private getFrontTag(segments: number, current: number) {
		var tag = Tags.ANY_FRONT_TAG;
		if (segments > 1) {
			if (current == 0) {
				tag = Tags.LEFT_FRONT_TAG;
			} else if (current == segments - 1) {
				tag = Tags.RIGHT_FRONT_TAG;
			}
		}

		return tag;
	}

	private getRearTag(segments: number, current: number) {
		var tag = Tags.ANY_REAR_TAG;
		if (segments > 1) {
			if (current == 0) {
				tag = Tags.LEFT_REAR_TAG;
			} else if (current == segments - 1) {
				tag = Tags.RIGHT_REAR_TAG;
			}
		}
		return tag;
	}

	private createColumn(tag: string, template: ProjectTemplate, startX: number, colI: number, column: ColumnParameter,
		guttH: number, rearH: number, front: FrontProfile, rear: boolean) {

		var shift = 0;
		if (tag == Tags.LEFT_COLUMN_TAG || tag == Tags.LEFT_REAR_COLUMN_TAG) {
			// Nothing to do
		} else if (tag == Tags.RIGHT_COLUMN_TAG || tag == Tags.RIGHT_REAR_COLUMN_TAG) {
			shift = column.size.width; // center
		} else {
			shift = column.size.width / 2; // right align
		}

		var y = 0;
		var h = 0;
		const bar = template.getDefaultBar();

		if (front) {
			const frontDepth = front.depth * SvgParams.SCALE;
			y = this._points[SvgParams.LEFT_BOTTOM_POINT_NUM].y - frontDepth;
			h = this.projectService.template.columnLength;
		} else {
			y = this._points[SvgParams.LEFT_TOP_POINT_NUM].y;
			h = this.projectService.template.backHeight;
		}

		var col = this.profileService.createOrUpdateProfileElement(template, 'roofElements', 'rectOnRoof', tag, ElementType.Column,
			startX + ((colI - shift) * SvgParams.SCALE),
			y,
			h,
			this.projectService.currentArea, AreaType.Roof, column.id) as ColumnProfile;

		col.isFront = front != null;
		col.isRear = rear;
		col.foundation = template.getDefaultFoundation();

		return col;
	}

	public createRoofVentilator(glass: Glass, roofWindowInfo: RoofWindowParameter) {
		const validationMessage = this.ventilatorValidate(glass, roofWindowInfo);
		if (validationMessage) {
			this.projectService.showTemporaryMessageSubj.next({ message: validationMessage, hideAfter: 3000, style: 'error' });
			return;
		}

		const x = glass.rect.x + glass.stat.paddingLeft * SvgParams.SCALE;
		const w = glass.rect.w / SvgParams.SCALE - (glass.stat.paddingLeft + glass.stat.paddingRight);

		var nrw = new RoofVentilator(x, 0, w, roofWindowInfo, this.projectService.template.connectorDef);
		this.locationService.adjustRoofwindow(nrw);
		this.profileService.roofElements[ElementType.RoofVentilator].push(nrw);
		glass.ventilator = nrw;

		const newY = Common.round(nrw.maxYPoint - roofWindowInfo.padding * SvgParams.SCALE);
		glass.rect.h -= Common.round(newY - glass.rect.y);
		glass.rect.y = newY;
		nrw.bottomGlass = glass;

		this.projectService.emitChange();

	}

	public createRoofWindow(glass: Glass, def: RoofWindowParameter) {
		const validationMessage = def.type == ToolboxItemType.RoofWindow ? this.roofWindowValidate(glass, def) : this.roofGutterValidate(glass, def);
		if (validationMessage) {
			this.projectService.showTemporaryMessageSubj.next({ message: validationMessage, hideAfter: 3000, style: 'error' });
			return;
		}

		const x = glass.rect.x + glass.stat.paddingLeft * SvgParams.SCALE;
		const w = glass.rect.w / SvgParams.SCALE - (glass.stat.paddingLeft + glass.stat.paddingRight);

		var y = 0;
		if (glass.ventilator) {
			y = glass.ventilator.connectorBottom.rect.y + glass.ventilator.connectorBottom.rect.h - glass.stat.paddingTop;
		}
		let nrw: RoofWindow;

		if (def.type == ToolboxItemType.RoofWindow) {
			nrw = new RoofWindow(x, y, w, def, this.projectService.template.connectorDef);
			this.profileService.roofElements[ElementType.RoofWindow].push(nrw);
		} else {
			nrw = new RoofGutter(x, y, w, def, this.projectService.template.connectorDef);
			this.profileService.roofElements[ElementType.RoofGutter].push(nrw);
		}

		this.locationService.adjustRoofwindow(nrw);

		if (glass.height - (glass.stat.paddingTop + glass.stat.paddingBottom) === def.depth) {
			this.projectService.deleteElement(glass);
		}

		if (nrw.connectorBottom.visible) {
			const newY = Common.round(nrw.maxYPoint - def.padding * SvgParams.SCALE);
			glass.rect.h -= Common.round(newY - glass.rect.y);
			glass.rect.y = newY;
			nrw.bottomGlass = glass;

			if (nrw.connectorTop.visible) {
				this.createTopGlassForWindow(glass.configId, nrw);
			}

		} else { // window near front
			glass.rect.h = nrw.minYPoint - SvgParams.START_Y;
			nrw.topGlass = glass;
		}
		this.projectService.changeMarquiseHandlers();

		this.projectService.emitChange();
		this.projectService.calculateRemote();
	}

	public createTopGlassForWindow(catId: string, rw: RoofWindow) {
		const glass = this.projectService.template.glasses.find(g => g.id == catId);
		const newY = this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y - glass.statics.paddingTop * SvgParams.SCALE;
		const newGlass: Glass = this.projectService.generateGlass(catId,
			rw.rect.x,
			newY,
			rw.width,
			(rw.minYPoint - newY) / SvgParams.SCALE,
			0,
			glass.statics.paddingTop,
			rw.padding);
		this.profileService.roofElements[ElementType.Glass].push(newGlass);
		rw.topGlass = newGlass;
	}

	public createBottomGlassForWindow(catId: string, rw: RoofWindow) {
		const glass = this.projectService.template.glasses.find(g => g.id == catId);
		const newY = rw.maxYPoint;
		const newY2 = this._workAreaPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		const newGlass: Glass = this.projectService.generateGlass(catId,
			rw.rect.x,
			newY,
			rw.width,
			(newY2 - rw.maxYPoint) / SvgParams.SCALE - rw.padding + glass.statics.paddingBottom,
			0,
			rw.padding,
			glass.statics.paddingBottom);
		this.profileService.roofElements[ElementType.Glass].push(newGlass);
		rw.bottomGlass = newGlass;
	}

	public deleteRoofWindow(rw: RoofWindow) {
		let glassDef: GlassParameter;
		let sinleGlass: Glass;
		// const rearDef = this.projectService.template.getDefaultRear();

		if (rw.topGlass && rw.bottomGlass) {
			glassDef = this.projectService.template.glasses.find(g => g.id == rw.topGlass.configId);
			sinleGlass = rw.bottomGlass;
			// sinleGlass.rect.y = rw.rect.y;
			// sinleGlass.rect.h = ((this._workAreaDepth + glassDef.statics.paddingBottom * 2 + glassDef.statics.paddingTop) * SvgParams.SCALE) - (rw.rect.y - SvgParams.START_Y) + rearDef.size.depth;
			this.projectService.deleteElement(rw.topGlass);
		} else if (rw.topGlass) {
			glassDef = this.projectService.template.glasses.find(g => g.id == rw.topGlass.configId);
			sinleGlass = rw.topGlass;
		} else {
			glassDef = this.projectService.template.glasses.find(g => g.id == rw.bottomGlass.configId);
			sinleGlass = rw.bottomGlass;
		}

		sinleGlass.rect.y = this._workAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y;
		sinleGlass.rect.h = (this._workAreaDepth + glassDef.statics.paddingBottom * 2 + glassDef.statics.paddingTop) * SvgParams.SCALE;
		sinleGlass.stat.paddingTop = glassDef.statics.paddingTop;
		sinleGlass.stat.paddingBottom = glassDef.statics.paddingBottom;

		if (sinleGlass.shouldBeDivided) {
			this.projectService.divideGlassHorizontal([sinleGlass]);
		}

		if (rw.type == ElementType.RoofVentilator) {
			sinleGlass.ventilator = null;
		}
		this.projectService.changeMarquiseHandlers();
		this.projectService.calculateRemote();
	}

	private ventilatorValidate(glass: Glass, ventInfo: RoofWindowParameter) {
		if (glass.ventilator) {
			return $localize`:Roof ventilator|Validation message:Cannot add second ventilator to the glass`;
		}

		if (glass.topBar) {
			return $localize`:Roof ventilator|Validation message:Ventilator can be placed only near rear profile`;
		}

		if (glass.height < ventInfo.depth) {
			return $localize`:Roof ventilator|Validation message:Not enough space for ventilator`;
		}

		// var rw = this.checkRoofWindowOnGlass(glass, ElementSide.Top);
		// if (rw && rw.rect.y < glass.rect.y + ventInfo.depth * SvgParams.SCALE) {
		// 	return $localize`:Roof ventilator|Validation message:Not enough space for ventilator`;
		// }
		var rw = this.checkRoofWindowOnGlass(glass);
		if (rw) {
			return $localize`:Roof ventilator|Validation message:Cannot add ventilator with roof gutter or window`;
		}

		if (glass.width - (glass.stat.paddingLeft + glass.stat.paddingRight) < ventInfo.minWidth) {
			return $localize`:Roof ventilator|Validation message:Roof ventilator can't be narrower then` + ` ${ventInfo.minWidth}mm`;
		}

		if (glass.width - (glass.stat.paddingLeft + glass.stat.paddingRight) > ventInfo.maxWidth) {
			return $localize`:Roof ventilator|Validation message:Roof ventilator can't be wider then` + ` ${ventInfo.maxWidth}mm`;
		}

		return null;
	}

	private roofWindowValidate(glass: Glass, roofWindowInfo: RoofWindowParameter) {
		if (this.checkRoofWindowOnGlass(glass)) {
			return $localize`:Roof window|Validation message:Cannot add window with another window or roof gutter`;
		}
		if (glass.ventilator) {
			return $localize`:Roof window|Validation message:Cannot add roof window with ventilator`;
		}

		if (glass.height - (glass.stat.paddingTop + glass.stat.paddingBottom) < roofWindowInfo.depth) {
			return $localize`:Roof window|Validation message:Roof window can't be shorter then` + ` ${roofWindowInfo.depth}mm`;
		}
		if (glass.width - (glass.stat.paddingLeft + glass.stat.paddingRight) < roofWindowInfo.minWidth) {
			return $localize`:Roof window|Validation message:Roof window can't be narrower then` + ` ${roofWindowInfo.minWidth}mm`;
		}

		if (glass.width - (glass.stat.paddingLeft + glass.stat.paddingRight) > roofWindowInfo.maxWidth) {
			return $localize`:Roof window|Validation message:Roof window can't be wider then` + ` ${roofWindowInfo.maxWidth}mm`;
		}

		return null;
	}

	private roofGutterValidate(glass: Glass, roofWindowInfo: RoofWindowParameter) {
		if (this.checkRoofWindowOnGlass(glass)) {
			return $localize`:Roof gutter|Validation message:Cannot add roof gutter with window or another roof gutter`;
		}

		if (glass.ventilator) {
			return $localize`:Roof gutter|Validation message:Cannot add roof gutter with ventilator`;
		}

		if (glass.height - (glass.stat.paddingTop + glass.stat.paddingBottom) < roofWindowInfo.depth) {
			return $localize`:Roof gutter|Validation message:Roof gutter can't be shorter then` + ` ${roofWindowInfo.depth}mm`;
		}
		if (glass.width - (glass.stat.paddingLeft + glass.stat.paddingRight) < roofWindowInfo.minWidth) {
			return $localize`:Roof gutter|Validation message:Roof gutter can't be narrower then` + ` ${roofWindowInfo.minWidth}mm`;
		}

		return null;
	}

	checkRoofWindow() {
		return this.profileService.roofElements[ElementType.RoofWindow].length > 0;
	}

	checkRoofWindowOnGlass(glass: Glass): RoofWindow {
		const types = [ElementType.RoofWindow, ElementType.RoofGutter];
		let rw: RoofWindow;
		types.every(t => {
			rw = this.profileService.roofElements[t].find((rw: RoofWindow) => rw.bottomGlass == glass || rw.topGlass == glass);
			if (!rw) {
				return true;
			}
		})
		return rw;
	}

	getSvg(): string {
		if (this._svg != "") {
			return this._svg;
		}
		this._svg = ProjectService.getPath(this._points);

		return this._svg;
	}

	getShapeAreaPoints() {
		return this._points;
	}

	getWorkAreaPoints() {
		return this._workAreaPoints;
	}

	// getColumnsPlaceholders() {

	// 	var phs: IColumnPlaceholder[] = [];
	// 	this.profileService.roofElements[ElementType.Bar].forEach((b: BarProfile) => {
	// 		var r = b.getColumnPlaceholder(this.profileService, this.projectService.getCurrentAreaRect());
	// 		if (r) {
	// 			phs.push({
	// 				rect: r,
	// 				bar: b
	// 			});
	// 		}
	// 	});

	// 	return phs;
	// }

	private addGlass(catId: string) {
		this.applyTemplate("", "", catId)
	}

	onDrop(ptr: IPointer, currentTool: ToolboxItem) {
		if (currentTool == null) {
			return;
		}
		switch (currentTool.type) {
			case ToolboxItemType.Glass:
				this.addGlass(currentTool.id);
				break;
		}
	}

	selectedLedPattern(template: LedPattern) {
		this.projectService.ledPattern = template;
		this.projectService.arrangeLeds();
	}

	barCount(): number {
		return this.profileService.roofElements.Bar.filter(b => b.orientation == Orientation.Vertical).length;
	}

	public createMarquise(ptr: MouseEvent, currentTool: ToolboxItem) {
		throw "Not implemented";
	}
	public createDoor(ptr: MouseEvent, currentTool: ToolboxItem) {
		throw "Not implemented";
	}

	public getSpace(ptr: IPointer): Space {
		throw "Not implemented";
	}
}
