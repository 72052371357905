import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GlassResizeMode} from '../../_core/interfaces/IGlassResizeMode';
import {CardType, IPropertyCard} from '../../_core/interfaces/IPropertyCard';
import {Glass} from '../../_core/models/glasses/glass.model';
import {ProjectService} from '../../_core/services/project.service';
import {ChosenGlassService} from '../../_core/services/chosen-elements/chosen-glass.service';
import {ToolboxItemService} from '../../_core/services/toolbox-item.service';
import {ProfileService} from '../../_core/services/profile.service';
import {ElementType, ToolboxItemType} from '../../_core/models/ToolboxModel';
import {GlassPart} from '../../_core/models/glasses/glassPart.model';
import {GlassPartDiamond} from '../../_core/models/glasses/glassDiamondPart';
import {RoofWindow} from '../../_core/models/glasses/roofWindow.model';
import {SvgParams} from '../../_core/constants/constants';
import {RoofService} from '../../_core/services/areas/roof.service';
import { Common } from 'src/app/_core/models/common';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';

@Component({
	selector: 'card-glass',
	templateUrl: './card-glass.component.html',
	styleUrls: ['../card.component.css']
})
export class CardGlassComponent implements IPropertyCard, OnInit {
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numHeight', { static: true }) numHeight: NumTbxComponent;
	@ViewChild('numDis', { static: false }) numDis: NumTbxComponent;
	@ViewChild('numLocX', { static: true }) numLocX: NumTbxComponent;
	@ViewChild('numLocY', { static: false }) numLocY: NumTbxComponent;

	type: CardType = CardType.Glass;

	@Input() public set items(items: any[]) {
		this._items = items;
		this.initItem();
	}

	//#region Fields

	public current: Glass | GlassPart | GlassPartDiamond | RoofWindow;
	public currentDef: string;
	public errors: string[] = [];

	public maxWidth: number = 0;
	public minWidth: number = 0;

	public maxHeight: number = 0;
	public minHeight: number = 0;
	public maxLocationY: number = 0;
	public minLocationY: number = 0;

	public maxDistance: number = 0;
	public minDistance: number = 0;

	private _items: Glass[] | RoofWindow[];
	private _rearDepth: number;

	public resizeMode: GlassResizeMode = GlassResizeMode.Right;

	public changeWidthDisabled: boolean;
	public changeHeightDisabled: boolean;

	public openDialog: boolean = false;
	public dialogMessage: string;
	
	//#endregion

	//#region Properties

	public get currentGlassWidth() {
		const v = this.chosenGlassService.getWidth();
		return v;
	}
	public set currentGlassWidth(width: number) {
		const v = Math.round(this.currentGlassWidth);
		const w = Math.round(width); 
		if (v == w) {
			return;
		}
		if (this.current.type == ElementType.Glass) {
			const g = this.current as Glass;
			const message = $localize`:Messages|Increase glass width:Cannot increase glass width in this mode!`;
			if (g.isLast && w > v && (this.resizeMode == GlassResizeMode.Right || this.resizeMode == GlassResizeMode.Both)) {
				this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
				this.numWidth.rebind(v);
				return;
			}
			if (g.isFirst && w > v && (this.resizeMode == GlassResizeMode.Left || this.resizeMode == GlassResizeMode.Both)) {
				this.projectService.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
				this.numWidth.rebind(v);
				return;
			}

		}

		this.chosenGlassService.setWidth(w);

		this.projectService.addBarsConnectors();
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get currentGlassHeight() {
		return this.chosenGlassService.getHeight();
	}
	public set currentGlassHeight(height: number) {
		if (height == this.currentGlassHeight) {
			return;
		}		
		this.chosenGlassService.setHeight(height);

		this.maxHeight = this.getMaxHeight();

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get locationX() {
		return this.chosenGlassService.getLocationX();
	}
	public set locationX(value: number) {
		this.chosenGlassService.setLocationX(value);

		this.maxWidth = this.getMaxWidth();

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	public get distance() {
		return this.chosenGlassService.getLocationY() - this._rearDepth;
	}
	public set distance(value: number) {
		this.locationY = value + this._rearDepth;
		this.numDis.rebind(Math.round(this.distance));

	}

	public get locationY() {
		return this.chosenGlassService.getLocationY();
	}
	public set locationY(value: number) {
		this.chosenGlassService.setLocationY(value);

		this.maxHeight = this.getMaxHeight();

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.numLocY.rebind(this.locationY);
		this.projectService.emitChange();
	}

	public get isWidthChangeDisabled(): boolean {
		var ret =  Boolean(
			this.projectService.readOnly
			|| this.changeWidthDisabled
			|| this.current.type == ElementType.GlassPart
			|| this.current.type == ElementType.GlassPartDiamond
			|| this.checkFreezingElements()
			);

		return ret;
	}
	public get isHeightChangeDisabled(): boolean {
		var ret = Boolean(
			this.projectService.readOnly
			|| this.changeHeightDisabled
			|| this.current.type == ElementType.GlassPart
			|| this.current.type == ElementType.GlassPartDiamond
			|| this.checkFreezingElements()
		);

		return ret;
	}
	//#endregion

	constructor(
		public projectService: ProjectService,
		public roofService: RoofService,
		public chosenGlassService: ChosenGlassService,
		public toolBSrvc: ToolboxItemService,
		public profileService: ProfileService
	) {	}

	ngOnInit(): void {
		this.chosenGlassService.isWidthDifferentSubj.subscribe((isWidthDifferent: boolean) => {
			this.changeWidthDisabled = isWidthDifferent
				|| (this.current as Glass).locked
				|| this.toolBSrvc.getCurrTool() != null
				|| this.checkFreezingElements();
		})
		this.chosenGlassService.isHeightDifferentSubj.subscribe((isHeightDifferent: boolean) => {
			this.changeHeightDisabled = isHeightDifferent
				|| (this.current as Glass).locked
				|| this.toolBSrvc.getCurrTool() != null
				|| this.checkFreezingElements();
		})
	}

	private initItem() {
		if (this._items.length >= 0) {
			this.current = this._items[0];
			if (this.current instanceof Glass) {
				this.current.resizeMode = this.resizeMode;
			}

			this.currentDef = this.current.configId;

			this.maxWidth = this.getMaxWidth();
			this.minWidth = this.getMinWidth();

			this.maxHeight = this.getMaxHeight();
			this.minHeight = this.getMinHeight();

			this.maxLocationY = this.getMaxLocationY();
			this.minLocationY = this.getMinLocationY();

			this._rearDepth = this.projectService.template.getDefaultRear().size.depth;

			this.minDistance = this.minLocationY - this._rearDepth;
			this.maxDistance = this.maxLocationY - this._rearDepth;

			setTimeout(() => {
				if (this.numDis && this.numDis.valueChanged.observers.length == 0) {
					this.numDis.valueChanged.subscribe(v => {
						this.distance = v;
					});
				}

				if (this.numLocX && this.numLocX.valueChanged.observers.length == 0) {
					this.numLocX.valueChanged.subscribe(v => {
						this.locationX = v;
					});
				}

				if (this.numLocY && this.numLocY.valueChanged.observers.length == 0) {					
					this.numLocY.valueChanged.subscribe(v => {
						this.locationY = v;
					});
				}
			}, 10);



			this.numWidth.disabled = this.projectService.readOnly || this.isWidthChangeDisabled;
			if (this.numWidth.valueChanged.observers.length == 0) {
				this.numWidth.valueChanged.subscribe(v => {
					this.currentGlassWidth = v;
				});
			}

			this.numHeight.disabled = this.projectService.readOnly || this.isHeightChangeDisabled;
			if (this.numHeight.valueChanged.observers.length == 0) {
				this.numHeight.valueChanged.subscribe(v => {
					this.currentGlassHeight = v;
				});
			}

		} else {
			this.current == null;
		}
	}

	private getMaxWidth() {
		switch (this.current.type) {
			case ElementType.Glass:
				return (this.current as Glass).maxWidth;

			default:
				return this.maxWidth;
		}
	}

	private getMinWidth() {
		switch (this.current.type) {
			case ElementType.GlassWall:
			case ElementType.Glass:
				return (this.current as Glass).minWidth;

			default:
				return this.minWidth;
		}
	}

	private getMaxHeight() {
		switch (this.current.type) {
			case ElementType.Glass:
				const wallD = this.projectService.template.getRearSize().depth;
				const frontD = this.projectService.template.getFrontSize().depth;
				const glassId = this.projectService.template.glassId;
				const glassInfo = this.projectService.template.glasses.find(g => g.id === glassId);
				return this.projectService.template.depth - wallD - frontD + glassInfo.statics.paddingBottom + glassInfo.statics.paddingTop;

			default:
				return this.maxHeight;
		}
	}

	private getMinHeight() {
		switch (this.current.type) {
			case ElementType.GlassWall:
			case ElementType.Glass:
				return 200;

			default:
				return this.minHeight;
		}
	}


	private getMaxLocationY() {
		switch (this.current.type) {
			case ElementType.RoofGutter:
			case ElementType.RoofWindow:
				const frontD = this.projectService.template.getFrontSize().depth;
				const windowD = this.current.height;
				const chosenW = (this.current as RoofWindow);
				const nextWindows = this.profileService.roofElements[ElementType.RoofWindow]
					.sort((e1: RoofWindow, e2: RoofWindow) => e1.rect.y - e2.rect.y)
					.filter((e:RoofWindow) => (e.rect.x === chosenW.rect.x) && (e.rect.y > chosenW.rect.y))

				let nextW = this.getClosestWindow(nextWindows);

				return nextW ? (nextW.rect.y - SvgParams.START_Y - chosenW.rect.h) / SvgParams.SCALE : this.projectService.template.depth - frontD - windowD;

			default:
				return this.maxLocationY;
		}
	}

	private getMinLocationY() {
		switch (this.current.type) {
			case ElementType.RoofGutter:
			case ElementType.RoofWindow:
				const wallD = this.projectService.template.getRearSize().depth;
				const chosenW = (this.current as RoofWindow);
				const previousWindows = this.profileService.roofElements[ElementType.RoofWindow]
					.sort((e1: RoofWindow, e2: RoofWindow) => e1.rect.y - e2.rect.y)
					.filter((e:RoofWindow) => (e.rect.x === chosenW.rect.x) && (e.rect.y < chosenW.rect.y))

				let prevW = this.getClosestWindow(previousWindows);

				return prevW ? (prevW.rect.y - SvgParams.START_Y + prevW.rect.h) / SvgParams.SCALE : wallD;

			default:
				return this.minLocationY;
		}
	}

	private getClosestWindow(previousWindows: RoofWindow[]) {
		const chosenW = (this.current as RoofWindow);

		if(previousWindows.length > 0) {
			return  previousWindows.reduce((prevW, currW) => {
				return (Math.abs(currW.rect.y - chosenW.rect.y) < Math.abs(prevW.rect.y - chosenW.rect.y) ? currW : prevW);
			})
		}
	}

	setResizeMode(mode: string) {
		this.resizeMode = GlassResizeMode[mode];
		(this.current as Glass).resizeMode = GlassResizeMode[mode];
		this.projectService.freezeProjProp();
	}

	getGlassesTypeData() {
		switch (this.current.type) {
			case ElementType.RoofWindow:
				return this.projectService.template.roofWindows.filter(r => r.type == ToolboxItemType.RoofWindow);

			case ElementType.Glass:
				return this.projectService.template.glasses;

			case ElementType.GlassPart:
				return this.projectService.template.wallGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);

			case ElementType.GlassPartDiamond:
				return this.projectService.template.diamondGlasses.filter(w =>
					Common.isInLocation(w.locations, this.projectService.currentArea)
				);
		}
	}

	typeChanged() {
		this.chosenGlassService.setType(this.currentDef);
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	setLock(isLocked: boolean) {
		this.chosenGlassService.setLock(isLocked);
		this.projectService.freezeProjProp();
	}

	showParameter() {
		return this.current.type != ElementType.RoofWindow;
	}

	deleteGlass() {
		this.chosenGlassService.setChosenGlass(null);
		for (const item of this._items) {
			this.roofWindowDeleting(item as RoofWindow);
			if (item instanceof GlassPart || item instanceof GlassPartDiamond) {
				this.projectService.deleteElement(item.master);
			} else {
				this.projectService.deleteElement(item);
			}
		}
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.projectService.changeMarquiseHandlers();

	}

	private roofWindowDeleting(item: RoofWindow) {
		if (!item) {
			return;
		}
		if (item.type != ElementType.RoofWindow && item.type != ElementType.RoofVentilator && item.type != ElementType.RoofGutter) {
			return;
		}
		this.roofService.deleteRoofWindow(item);
	}

	checkFreezingElements(): boolean {
		if (this.current.type == ElementType.Glass) {
			return this.projectService.checkMarquise()
				|| this.roofService.checkRoofWindow()
				|| (this.current as Glass).ventilator != null;
		}
		return false;
	}

	allowChange(): void {
		this.dialogMessage = $localize`:Messages|Delete all confirmation:All freezing elements will be deleted. Are you sure?`;
		this.openDialog = true;
	}

	onDialogAction(status: boolean) {
		this.openDialog = false;

		if (!status) return;

		if (this.roofService.checkRoofWindow()){
			this.roofService.applyTemplate("", "");
		} else if (this.projectService.checkMarquise()) {
			this.projectService.clearCollections(ElementType.MarquiseTopUp);
			this.projectService.clearCollections(ElementType.MarquiseTopBottom);
		}
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}
	get currentGlassViewWidth() {
		if (this.current.type === ElementType.RoofWindow) {
			return undefined;
		}
		var g = this.current as Glass;
		return Common.round(this.currentGlassWidth - (g.stat ? g.stat.paddingLeft + g.stat.paddingRight : 0));
	}

	onAfterLocationYValueChange(value: number): void {
		
	}

}
