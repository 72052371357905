<div class="row" style="padding-bottom: 10px;">
	<div class="col col-4"><span i18n="Marquise|Marquise type">Marquise type</span>:</div>
	<div class="col col-8">
		{{currMarqInfo.name}}
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Width">Width</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox [value]="currentMarquiseWidth" [disabled]="true"></app-numeric-textbox>&nbsp;mm&nbsp;
		<div kendoTooltip>
			<span [attr.title]="changeWidthTooltip('Left')">
				<button kendoButton (click)="onChangeWidthBtnClick('Left')"
					[disabled]="projectService.readOnly || isChangeWidthBtnDisable('Left')"
					[svgIcon]="projectService.svgIcons.caretAltLeftIcon"></button>
			</span>
			<span [attr.title]="changeWidthTooltip('Right')">
				<button kendoButton (click)="onChangeWidthBtnClick('Right')"
					[disabled]="projectService.readOnly || isChangeWidthBtnDisable('Right')"
					[svgIcon]="projectService.svgIcons.caretAltRightIcon"></button>
			</span>
		</div>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Marquise|Left handler">Left handler</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="chosenMarquiseService.leftHandlers" textField="name" valueField="id"
			[(ngModel)]="leftHandler" [valuePrimitive]="true" [disabled]="projectService.readOnly">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Marquise|Right handler">Right handler</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="chosenMarquiseService.rightHandlers" textField="name" valueField="id"
			[(ngModel)]="rightHandler" [valuePrimitive]="true" [disabled]="projectService.readOnly">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row" *ngIf="currMarq.correctionBack > 0">
	<div class="col col-4">
		<span i18n="Marquise|Expand back">Expand back</span>:
	</div>
	<div class="col col-8">
		<kendo-buttongroup selection="single" [ngClass]="{'required' : currMarq.isExpandedBack === null }" kendoTooltip>
			<button kendoButton [selected]="currMarq.isExpandedBack === true" [toggleable]="true" [svgIcon]="projectService.svgIcons.uploadIcon"
				[disabled]="projectService.readOnly || currentMarquiseYLocation > minYLocation"
				(selectedChange)="expandBack(true)"
				i18n-title="Marquise|Tooltip:Set back expansion" title="Set back expansion">
			</button>
			<button kendoButton [selected]="currMarq.isExpandedBack === false" [toggleable]="true" [svgIcon]="projectService.svgIcons.uploadIcon"
				[disabled]="projectService.readOnly"
				style="transform: rotate(180deg);"
				(selectedChange)="expandBack(false)"
				i18n-title="Marquise|Tooltip:Remove back expansion" title="Remove back expansion">
			</button>
		</kendo-buttongroup>
	</div>

</div>
<div class="row">
	<div class="col col-4"><span i18n="Common|Depth">Depth</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numDepth [value]="currentMarquiseDepth" [min]="minDepth" [max]="maxDepth" [disabled]="projectService.readOnly"></app-numeric-textbox> mm
	</div>
</div>

<div class="row" *ngIf="currMarq.type === 'MarquiseTopUp'">
	<div class="col col-4"><span i18n="Marquise|Extension">Extension</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox [value]="currentMarquiseExtension" [disabled]="true"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location x">Location x</span>:</div>
	<div class="col col-8" style="display: flex; flex-wrap: wrap;">
		<app-numeric-textbox [value]="currentMarquiseXLocation" [disabled]="true"></app-numeric-textbox>&nbsp;mm&nbsp;
		<div kendoTooltip>
			<span [attr.title]="changePositionTooltip('Left')">
				<button kendoButton (click)="onPositionBtnClick('Left')"
					[disabled]="projectService.readOnly || isChangePositionBtnDisable('Left')"
					[svgIcon]="projectService.svgIcons.rewindIcon"></button>
			</span>
			<span [attr.title]="changePositionTooltip('Right')" >
				<button kendoButton (click)="onPositionBtnClick('Right')"
					[disabled]="projectService.readOnly || isChangePositionBtnDisable('Right')"
					[svgIcon]="projectService.svgIcons.forwardIcon"></button>
			</span>
		</div>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Common|Location y">Location y</span>:</div>
	<div class="col col-8">
		<app-numeric-textbox #numLocY [value]="currentMarquiseYLocation" [disabled]="projectService.readOnly || currMarq.isExpandedBack == true"
			[min]="minYLocation" [max]="maxYLocation"></app-numeric-textbox> mm
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Marquise|Engine type">Engine type</span>:</div>
	<div class="col col-8">
		<kendo-dropdownlist style="width: 100%" [data]="currMarqInfo.engines" textField="name" valueField="id"
			[(ngModel)]="engineId" [valuePrimitive]="true" [disabled]="projectService.readOnly">
		</kendo-dropdownlist>
	</div>
</div>

<div class="row">
	<div class="col col-4"><span i18n="Marquise|Engine location">Engine location</span>:</div>
	<div class="col col-8">
		<kendo-buttongroup selection="single" [disabled]="projectService.readOnly" kendoTooltip>
			<button kendoButton [selected]="currMarq.engine.location == 'Left'" [toggleable]="true" [svgIcon]="projectService.svgIcons.loginIcon" style="transform: rotate(180deg);"
				(selectedChange)="relocateEngine('Left')" i18n-title="Marquise|Tooltip:Move engine to the left" title="Move engine to the left"></button>
			<button kendoButton [selected]="currMarq.engine.location == 'Right'" [toggleable]="true" [svgIcon]="projectService.svgIcons.loginIcon"
				(selectedChange)="relocateEngine('Right')" i18n-title="Marquise|Tooltip:Move engine to the right" title="Move engine to the right"></button>
		</kendo-buttongroup>
	</div>
</div>

<app-color-palette [colorsPalette]="currMarqInfo.colors" [element]="currMarq"></app-color-palette>

<div class="row">
	<div class="col col-4"><span i18n="Common|Delete">Delete</span>:</div>
	<div class="col col-8">
		<button kendoButton [svgIcon]="projectService.svgIcons.trashIcon" (click)="deleteElement()" [disabled]="projectService.readOnly" i18n="Common|Delete">Delete</button>
	</div>
</div>

<div *ngIf="projectService.showDebugInfo" class="testMode">
	<div class="row">
		<div class="col col-4">Left handler type:</div>
		<div class="col col-8">
			{{ currMarq.leftHandler.name }}<br>
			<span *ngIf="currMarq.type == 'MarquiseTopUp'" >roofWindow: {{ currMarq.leftHandler.roofWindow }}<br></span>
			type: {{ currMarq.leftHandler.double ? "double" : "single" }}
			<br>
			width correction: {{ currMarq.leftHandler.widthCorrection }}
		</div>
	</div>
	<div class="row">
		<div class="col col-4">Right handler type:</div>
		<div class="col col-8">
			{{ currMarq.rightHandler.name }}<br>
			<span *ngIf="currMarq.type == 'MarquiseTopUp'" >roofWindow: {{ currMarq.rightHandler.roofWindow }}<br></span>
			type: {{ currMarq.rightHandler.double ? "double" : "single" }}
			<br>
			width correction: {{ currMarq.rightHandler.widthCorrection }}
		</div>
	</div>
	<div class="row">
		<div class="col col-4">Location x:</div>
		<div class="col col-8">{{ currentMarquiseXLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Location y:</div>
		<div class="col col-8">{{ currentMarquiseYLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min y Location:</div>
		<div class="col col-8">{{ minYLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max y Location:</div>
		<div class="col col-8">{{ maxYLocation | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Width:</div>
		<div class="col col-8">{{ rawWidth | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Width (ERP):</div>
		<div class="col col-8">{{ chosenMarquiseService.widthToERP | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Depth (UI):</div>
		<div class="col col-8">{{ currMarq.getUIDepth() | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Depth (ERP):</div>
		<div class="col col-8">{{ currMarq.getErpDepth() | number:'1.2-2' : currLng }} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min width:</div>
		<div class="col col-8">{{minWidth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max width:</div>
		<div class="col col-8">{{maxWidth}} mm</div>
	</div>
	<div class="row"  *ngIf="currMarq.type === 'MarquiseTopUp'">
		<div class="col col-4">Min cover:</div>
		<div class="col col-8">{{minCover}} mm</div>
	</div>
	<div class="row"  *ngIf="currMarq.type === 'MarquiseTopUp'">
		<div class="col col-4">Max extension:</div>
		<div class="col col-8">{{maxExtension}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Min depth:</div>
		<div class="col col-8">{{minDepth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Max depth:</div>
		<div class="col col-8">{{maxDepth}} mm</div>
	</div>
	<div class="row">
		<div class="col col-4">Channels:</div>
		<div class="col col-8">{{currMarqInfo.channels}}</div>
	</div>
</div>
