import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { AreaType, CollectionName, RectName } from '../interfaces/IAreaType';
import { IGroupable } from '../interfaces/IGroupable';
import { AnchorType, IMarker } from '../interfaces/IMarker';
import { IPoint } from '../interfaces/IPoint';
import { IStatics } from '../interfaces/IStatics';
import { Tags, SvgParams, GLASS_PART_PADDING, LED_STRIP_MAX_LENGTH, UrlParams, ColorImgParams, OUTFLOW_SURFACE_TRESHOLD, MarquisesParams, DoorParams, CUSTOMER_PLATE_HEIGHT } from '../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { GroupManager } from '../models/groupmanages';
import { ElementType, Orientation, ToolboxItem, ToolboxItemType } from '../models/ToolboxModel';
import { Profile } from '../models/profiles/profile.model';
import { ProfileService } from './profile.service';
import { BarProfile } from '../models/profiles/bar.model';
import { Glass } from '../models/glasses/glass.model';
import { ProjectTemplate } from '../template';
import { ColumnProfile } from '../models/profiles/column.model';
import { Common } from 'src/app/_core/models/common';
import { ICheckInput, ICheckResult } from 'src/app/_core/interfaces/ICalculations';
import { BarParameter, FrontParameter, GlassWallParameter, RearParameter, WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { FrontProfile } from '../models/profiles/front.model';
import { WallProfile } from '../models/profiles/wall.model';
import { Marquise } from 'src/app/_core/models/marquises/marquise.model';
import { IPrintoutEventArgs, IPrintoutFile, PrintoutType } from 'src/app/_core/models/boms/bom.model';
import { SideBaseService } from './areas/side-base.service';
import { FrontService } from './areas/front.service';
import { GlassWall } from '../models/glasses/glassWall.model';
import { GlassPart } from '../models/glasses/glassPart.model';
import { MuntinProfile } from '../models/profiles/muntin.model';
import { FrameProfile } from '../models/profiles/frame.model';
import { IRect } from '../interfaces/IRect';
import { RoofService } from './areas/roof.service';
import { LedPattern } from '../models/leds-patterns';
import { MarquiseCreator } from '../models/marquises/marquise-creator';
import { GlassPartDiamond } from '../models/glasses/glassDiamondPart';
import { ElementSide, Montage } from '../interfaces/IElementSide';
import { LedStripe } from '../models/profiles/led-stripe.model';
import { IAreaService } from '../interfaces/IAreaService';
import { IPointer } from '../interfaces/IPointer';
import { switchMap } from 'rxjs/operators';
import { Color, ColorOfPalette, ICurrentColor } from '../models/colors/color.model';
import { LED } from '../models/profiles/leds.model';
import { ActivatedRoute } from '@angular/router';
import { ProfileConnector } from '../models/profiles/connector.model';
import { MarquiseTop } from '../models/marquises/marquise-top.model';
import { RoofWindow } from '../models/glasses/roofWindow.model';
import { MarquiseVertical } from '../models/marquises/marquise-vertical.model';
import { ChosenMarquiseService } from './chosen-elements/chosen-marquise.service';
import { WallGutterProfile } from '../models/profiles/wall-gutter.model';
import { IProjectInfo } from '../interfaces/IProjectInfo';
import * as icons from '@progress/kendo-svg-icons';
import { GraphicsService } from './graphics-service';
import { Door } from '../models/doors/door.model';
import { DoorCreator } from '../models/doors/door-creator';
import { DoorType, IDoorInfo } from '../interfaces/IDoorInfo';
import { VerticalElement, VirtualVertical } from "../models/vertical-element";
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Wall } from '../models/walls/wall.model';
import { WallCreator } from '../models/walls/wall-creator';
import { Shape } from '../models/shape';
import { LayerLevel } from '../interfaces/LayerLevel';
import { ISideLayout } from '../interfaces/ISideLayout';
import { LeftSideService } from './areas/left-side.service';
import { RightSideService } from './areas/right-side.service';
import { GlassWallBase } from '../models/glasses/glassWall-base.model';
import { GlassWallDiamond } from '../models/glasses/glassWallDiamond.model';
import { GlassWallDiamondService } from './glass-walls/glass-wall-diamond.service';
import { PrintChannel } from 'src/app/main/svg-component/svg.component';
import { StraightService } from './areas/straight.service';
import { Space } from '../models/space';
import { RearService } from './areas/rear.service';
import { ICheckable } from '../interfaces/ICheckable';

@Injectable({
	providedIn: 'root'
})
export class ProjectService {

	public elementsChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public areaChanged: BehaviorSubject<AreaType> = new BehaviorSubject<AreaType>(AreaType.None);
	public layersChanged: BehaviorSubject<LayerLevel[]> = new BehaviorSubject<LayerLevel[]>(null);
	public elementDeleted: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);
	public projectReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public colorChanged: BehaviorSubject<Color | ColorOfPalette> = new BehaviorSubject<Color | ColorOfPalette>(null);
	public printProjectSubj = new Subject<IPrintoutEventArgs>();
	public printoutReady = new Subject<IPrintoutFile>();
	public showDimensionsSubj = new Subject<boolean>();
	public isShowDimensions: boolean = false;
	public showTemporaryMessageSubj = new Subject < { message: string, hideAfter: number, style: 'error' | 'info' | 'none' | 'success' | 'warning', vertical?: string }>();
	public showInfoDialogSubj = new Subject<string>();

	public erpExportMode: boolean = false;
	public errorSubject = new Subject<string>();
	public templateChangedSubject = new Subject<ProjectTemplate>();
	public info: IProjectInfo;
	private marquiseCreator = new MarquiseCreator(this);
	public projectWeight: number;

	public svgIcons = icons;
	public plateLogoSrc: string;
	
	public get useStatics() {
		return this.template?.useStatics ?? true;
	}
	public set useStatics(v: boolean) {
		this.template.useStatics = v;
	}

	private _brightness: number = -1;
	public get brightness(): number {
		return this._brightness;
	}
	public set brightness(v: number) {
		this._brightness = v;
	}

	public get frameColor() : string {

		if (this._brightness <= 0) {
			return "#000000";
		}
		if (GraphicsService.isDark(this._brightness)) {
			return ColorImgParams.LIGHT_FRAME;
		} else {
			return ColorImgParams.DARK_FRAME;
		}

	}

	private _currentColor: ICurrentColor;
	private _selectedColor: Color | ColorOfPalette;	
	public get selectedColor(): Color | ColorOfPalette {
		return this._selectedColor;
	}
	public set selectedColor(v: Color | ColorOfPalette)  {
		this._selectedColor = v;
	}

	public remote: WizardParameter;
	public channelsTotal: number;
	public userRemote: boolean = false;

	//#region Current area
	public get areaType() {
		return this._currentArea;
	}

	private _currentArea: AreaType;
	public get currentArea() {
		return this._currentArea;
	}
	public set currentArea(area: AreaType) {
		if(area != this._currentArea) {
			this._currentArea = area;
			this.areaChanged.next(area);
		}
	}
	//#endregion
	public CurrentAreaService: IAreaService;

	public grouper: GroupManager = new GroupManager();

	private _readOnly: boolean;
	public get readOnly(): boolean {
		if (this._readOnly == undefined) {
			this._readOnly = this.route.snapshot.queryParams[UrlParams.ReadOnly] == 'true';
		}
		return this._readOnly;
	}
	public set readOnly(v: boolean) {
		this._readOnly = v;
	}

	public get isSupervisor(): boolean {
		if (environment.production && this.authService.user != null) {
			return this.authService.user.eMail.toLowerCase().endsWith('@imtosoftware.pl');
		}
		return !environment.production;
	}

	public get showDebugInfo(): boolean {
		return this.isSupervisor && localStorage.getItem("debugInfo") == "1";
	}

	public set showDebugInfo(v: boolean) {
		if (this.isSupervisor) {
			localStorage.setItem("debugInfo", v ? "1" : "0");
		}
	}
	public get draftMode(): boolean {
		return this.template.isDraft;
	}

	private isProjPropFreeze: boolean = false;

	//#region Template fields
	private _snowZone: string;
	private _windZone: string;
	private _realDepth: number;

	public get realDepth() {
		if (!this._realDepth) {
			const frontDepth = this.template.getFrontSize().depth;
			const colDepth = this.template.getFrontColumnSize().depth;
			this._realDepth = this.template.depth + (frontDepth - colDepth);
		}
		return this._realDepth;
	}
	//#endregion

	//#region getPlaceholder helper fields
	private _orderCode: string;
	public get orderCode(): string {
		if (!this._orderCode) {
			this._orderCode = this.route.snapshot.queryParams[UrlParams.OrderCode];
		}
		return this._orderCode;
	}

	public set orderCode(v: string) {
		if (!this._orderCode) {
			this._orderCode = v;
		}
		this._orderCode = v;
	}

	private _productId: string;
	public get productId(): string {
		if (!this._productId) {
			this._productId = this.route.snapshot.queryParams[UrlParams.ProductId];
		}
		return this._productId;
	}
	public set productId(v: string) {
		this._productId = v;
	}

	private _brandName: string;
	public get brandName(): string {
		if (!this._brandName) {
			this._brandName = this.route.snapshot.queryParams[UrlParams.BrandName] ?? "Aluterr";
		}
		return this._brandName;
	}
	public set brandName(v: string) {
		this._brandName = v;
	}

	//#endregion

	//#region Template properties
	public get snowZoneUrl() {
		return this.route.snapshot.queryParams[UrlParams.SnowZone];
	}
	public get snowZone() {
		if (!this._snowZone) {
			this._snowZone = this.snowZoneUrl;
		}
		return this._snowZone;
	}
	public set snowZone(v: string) {
		this._snowZone = v;
	}

	public get windZoneUrl() {
		return this.route.snapshot.queryParams[UrlParams.WindZone];
	}
	public get windZone() {
		if (!this._windZone) {
			this._windZone = this.windZoneUrl;
		}
		return this._windZone;
	}
	public set windZone(v: string) {
		this._windZone = v;
	}
	public resetZones() {
		this._windZone = this.windZoneUrl;
		this._snowZone = this.snowZoneUrl;
		this.template.snowZone = this._snowZone;
		this.template.windZone = this._windZone;
	}

	public get currentFront(): FrontParameter {
		return this.template.fronts.find(f => f.id == this.template.frontId);
	}

	public get currentRear(): RearParameter {
		return this.template.rears.find(f => f.id == this.template.rearId);
	}

	public get currentBar(): BarParameter {
		return this.template.bars.find(f => f.id == this.template.barId);
	}

	private _markers: IMarker[] = [];
	public get markers(): IMarker[] {
		if (this._markers.length == 0) {

			const b1: IMarker = {
				text: "B1", 
				anchor: AnchorType.middle,
				loc: { 
					x: SvgParams.START_X + (this.template.width / 2) * SvgParams.SCALE,
					y: SvgParams.START_Y + (this.template.depth * SvgParams.SCALE) + 120 }
				}
			this._markers.push(b1);

			const b2: IMarker = {
				text: "B2",
				anchor: AnchorType.middle,
				loc: {
					x: b1.loc.x,
					y: SvgParams.START_Y - 100
				}
			}
			this._markers.push(b2);

			const t1: IMarker = {
				text: "T1",
				anchor: AnchorType.middle,
				loc: {
					x: SvgParams.START_X - 100,
					y: SvgParams.START_Y + (this.template.depth / 2) * SvgParams.SCALE
				}
			}
			this._markers.push(t1);

			const t2: IMarker = {
				text: "T2",
				anchor: AnchorType.middle,
				loc: {
					x: SvgParams.START_X + this.template.width * SvgParams.SCALE + 100,
					y: t1.loc.y
				}
			}
			this._markers.push(t2);
		}

		return this._markers;
	}

	public get isValid(): boolean {
		const ers = this.errors;
		return ers != null && ers.length == 0;
	}
	public get errors(): string[] {
		if (this.profileService.roofElements == null) {
			return null;
		}

		var r: string[] = [];

		const types = [ElementType.MarquiseTopUp, ElementType.MarquiseTopBottom, ElementType.MarquiseVertical];
		var valid = true;
		types.every(t => {
			var elements = this.profileService.roofElements[t];
			elements = elements.concat(this.profileService.getMarquisesVertical());
			if (elements.some(m => !m.fabric)) {
				const message = $localize`:Common|Project validation message:Please select fabric for all marquises`;
				r.push(message);
				valid = false;
			}
			return valid;
		});

		if (this.profileService.roofElements[ElementType.MarquiseTopBottom].some(((m: MarquiseTop) => m.correctionBack > 0 && m.isExpandedBack === null))) {
			const message = $localize`:Common|Project validation message:Please select back expand for marquise`;
			r.push(message);
		}

		const outflowsDef = this.template.columns.find(c => c.outflowId != null && c.outflowId != '');
		if (outflowsDef) {
			const outflowsCount = this.profileService.roofElements[ElementType.Column].filter((c: ColumnProfile) => c.outflow != ElementSide.None).length;
			const minOutflows = this.template.surface > OUTFLOW_SURFACE_TRESHOLD ? 2 : 1;
			if (outflowsCount < minOutflows) {
				const message = minOutflows == 1 ? $localize`:Common|Project validation message:Please select at least one outflow` : $localize`:Common|Project validation message:Please select at least two outflows`;
				r.push(message);
			}
		}

		return r;
	}

	public get warnings(): string[] {
		var w: string[] = [];

		if(!this.useStatics){
			const message = $localize`:Common|Project validation message:Statics are disabled`;
			w.push(message);
		}

		return w;
	}
	//#endregion

	//#region Led
	public ledPattern: LedPattern;
	public ledSpotName: string;
	public ledStripeName: string;
	public _bars: any;
	public _sideFinish: any;
	public template: ProjectTemplate = new ProjectTemplate(false);
	//#endregion

	constructor(
		private authService: AuthService,
		private api: ApiService,
		public profileService: ProfileService,
		private route: ActivatedRoute,
		private graphicsService: GraphicsService) {

		this.showDimensionsSubj.subscribe((status: boolean) => {
			this.isShowDimensions = status;
		});

		this.graphicsService.imageChanged.subscribe((c: ICurrentColor) => {
			if (c == null) {
				return;
			}
			if (c.elementId == 'logoPlate') {
				this.plateLogoSrc = c.imageBase64;
			}
			if (!c.elementId) {
				this._brightness = c.brightness;
				this._currentColor = c;
			}
		});

		this.errorSubject.subscribe(message => {
			if (message == null) {
				return;
			}
			this.showTemporaryMessageSubj.next({message, hideAfter: 1000, style: 'error'})
		});


		this.graphicsService.getBackgroud('logoPlate', { image: "assets/images/logop.jpg" }, 500, 500);
	}
	getGWDStartPoints(currentAreaElements?: any): IPoint[] {
		throw new Error('Method not implemented.');
	}

	emitChange(elChanged: boolean = true) {
		this.calculateWeight();
		if (this.readOnly) {
			return;
		}
		if (elChanged) {
			this.elementsChanged.next(true);
		}
	}

	calculateWeight(){
		var weight = 0;
		var elements = [];

		const types = [ElementType.Column, ElementType.Bar, ElementType.SideFinish, ElementType.Front, ElementType.WallProfile, ElementType.Glass];

		types.forEach(cn => {
			this.profileService.roofElements[ElementType[cn]].forEach(e => {
				if (e?.id) {
					var element = elements.find(f => f?.id == e?.id);
					if(element == null) {
						elements.push(e);
					}
				}
			})
		});

		elements.forEach(c => {
			if(c["weight"]){
				if(c["cuttingable"] && c.type != ElementType.Glass){
					if (c.type == ElementType.Column && c.foundation) {
						weight += c.weight * (c.length + c.foundation.size.height) / 1000;
					} else {
						weight += c.weight * c.length / 1000;
					}
				} else {
					weight += c.weight;
				}		
			}
		});

		this.projectWeight = Common.round(weight);
	}

	getShapeAreaPoints() {
		throw new Error('Method not implemented.');
	}

	getFreezeStatusOfProjProp(): boolean {
		return this.readOnly || this.isProjPropFreeze;
	}

	freezeProjProp(): void {
		this.isProjPropFreeze = true;
	}

	unfreezeProjProp(): void {
		this.isProjPropFreeze = false;
	}

	public setupTemplate(pr: ProjectTemplate) {
		combineLatest([
			this.api.getFullColors(pr.productId, pr.brandName),
			this.api.getElements(pr.productId, pr.brandName),
			this.api.getColors(pr.productId, pr.brandName).pipe(
				switchMap((colors) => {
					const colorsWithPalette = this.api.getColorsWithPalette(colors, pr.productId, pr.brandName);
					return forkJoin(colorsWithPalette);
				})
			)
		]).subscribe(([paletteColors, elems, colors]: [ColorOfPalette[], any, Color[]]) => {

			pr.fronts = elems.fronts;
			pr.frontId = pr.fronts.find((f) => f.isDefault).id;

			pr.bars = elems.bars;
			pr.barId = pr.bars.find((f) => f.isDefault).id;

			pr.columns = elems.columns;
			
			var fc = pr.columns.find((f) => f.isDefault);
			if (!fc) {
				fc = pr.columns[0];
			}
			pr.frontColumnId = fc.id;

			pr.formSides = elems.formSides;
			if (!pr.formSideId) {
				pr.formSideId = pr.formSides.find((f) => f.isDefault).id;
			}
			
			pr.rears = elems.rears;
			var defaultRear: RearParameter;
			if (pr.isStandalone) {
				defaultRear = elems.rears.filter(f => f.standalone)[0];
			} else {
				defaultRear = elems.rears.filter(f => f.wall)[0];
			}
			pr.rearId = defaultRear.id;
			var defCol = defaultRear.columns.find(c => c.isDefault);
			if (!defCol) {
				defCol = defaultRear.columns.length > 0 ? defaultRear.columns[0] : null;
			}
			pr.rearColumnId = defCol?.id;

			pr.fastenings = elems.fastenings;
			if (!pr.fasteningId) {
				pr.fasteningId = pr.fastenings.find((f) => f.isDefault).id;
			}

			pr.formTops = elems.formTops;
			if (!pr.formTopId) {
				pr.formTopId = pr.formTops.find((f) => f.isDefault).id;
			}

			pr.glasses = elems.glasses;
			if (!pr.glassId) {
				pr.glassId = pr.glasses.find((f) => f.isDefault).id;
			}

			pr.wallGlasses = elems.wallGlasses;
			pr.diamondGlasses = elems.diamondGlasses;
			pr.hiddenExtras = elems.hiddenExtras;
			pr.extras = elems.extras;
			pr.roofWindows = elems.roofWindows;
			pr.roofAddons = elems.roofAddons;
			
			pr.doors = elems.doors;
			pr.walls = elems.walls;
			pr.wallGutters = elems.wallGutters;
			pr.connectorDef = elems.connector;
			pr.marquises = elems.marquises;
			pr.remotes = elems.remotes;

			pr.colors = colors;
			pr.paletteColors = paletteColors;

			if (!pr.windZone) {
				pr.windZone = this.windZone;
			}

			if (!pr.snowZone) {
				pr.snowZone = this.snowZone;
			}

			this._windZone = pr.windZone;
			this._snowZone = pr.snowZone;
			this.template = pr;
			this.template.getHydroIsolations();

			this.templateChangedSubject.next(pr);

		});

	}

	public getElementsForCheck(self: VerticalElement = null, area: AreaType = AreaType.None) {
		const searchBy = area == AreaType.None ? this.currentArea : area;
		var currentAreaElements = this.getCurrentAreaElements(searchBy);
		var elements = [];
		elements[ElementType.Frame] = [];

		[ElementType.Frame, ElementType.Column, ElementType.SideFinish, ElementType.Front, ElementType.WallProfile].forEach(type => {
			if (!elements[type]) {
				elements[type] = [];
			}
			currentAreaElements[type].forEach(el => {
				elements[type].push(el);
			})
		});

		this.profileService.getVerticals(searchBy).filter(v => self == null || v.id != self.id).forEach(el => {
			if (!elements[el.type]) {
				elements[el.type] = [];
			}
			elements[el.type].push(el);
		})

		this.profileService.getVerticals(searchBy).filter(v => self == null || v.id != self.id).forEach(el => {
			var y = searchBy == AreaType.Left ? el.points[SvgParams.LEFT_TOP_POINT_NUM].y : el.points[SvgParams.RIGHT_TOP_POINT_NUM].y;
			if (el.leftProfile) {
				el.leftProfile["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(el.leftProfile);
			} else {
				var lframe = new FrameProfile(el.leftX, y, el.height, { width: 0, depth: 0, height: 0 }, "", Orientation.Vertical, "");
				lframe["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(lframe);

			}
			if (el.rightProfile) {
				el.rightProfile["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(el.rightProfile);
			} else {
				var rframe = new FrameProfile(el.rightX, y, el.height, { width: 0, depth: 0, height: 0 }, "", Orientation.Vertical, "");
				rframe["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(rframe);
			}
			if (el.topProfile) {
				el.topProfile["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(el.topProfile);
			} else {
				var tframe = new FrameProfile(el.leftX, y, el.width, { width: 0, depth: 0, height: 0 }, "", Orientation.Horizontal, "");
				tframe["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(tframe);
			}
			if (el.bottomProfile) {
				el.bottomProfile["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(el.bottomProfile);
			} else {
				var bframe = new FrameProfile(el.leftX, el.bottomY, el.width, { width: 0, depth: 0, height: 0 }, "", Orientation.Horizontal, "");
				bframe["masterId"] = el.id; // for debug purpose only
				elements[ElementType.Frame].push(bframe);
			}
		});

		return elements;
	}
	
	public getCurrentAreaElements(area: AreaType = AreaType.None) {
		const searchBy = (area === AreaType.None) ? this._currentArea : area;
		return this.profileService[CollectionName.get(searchBy)];
	}

	public getCurrentAreaRect(): string {
		return RectName.get(this._currentArea);
	}

	public getXPositionHeight(xPosition: number, drop?: number): number {
		if (!drop) {
			drop = this.template.dropAngle;
		}
		const needlessHeight = Math.round(xPosition * Math.tan(Common.toRadians(drop)));
		return this.template.backHeight - needlessHeight;
	}

	public getProfileY(p: Profile, area: AreaType) {
		const sn = CollectionName.get(area);
		const rc = RectName.get(area);
		const sideFin: BarProfile = this.profileService[sn][ElementType.SideFinish][0];
		const xPosition = area === AreaType.Left ? (p.rectOnLeft.x - SvgParams.START_X) / SvgParams.SCALE : this.template.depth - (p.rectOnRight.x - SvgParams.START_X) / SvgParams.SCALE;
		const colPosH = this.getXPositionHeight(xPosition);
		var y = SvgParams.START_Y + (this.template.backHeight - colPosH + sideFin.height + ((sideFin[rc].y - SvgParams.START_Y) / SvgParams.SCALE)) * SvgParams.SCALE;
		return y;
	}

	// ! marquises

	public checkMarquise(): boolean {
		return this.profileService.roofElements[ElementType.MarquiseTopUp].length > 0 ||
			this.profileService.roofElements[ElementType.MarquiseTopBottom].length > 0
	}

	public checkVerticalElements(column: ColumnProfile = null): boolean {
		var velems = this.profileService.getVerticals();

		if (!column) {
			return velems.length > 0;
		}

		return velems.find(v => v.leftProfile.id == column.id || v.rightProfile.id == column.id) != null;
	}

	public createMarquiseTop(selectedGlasses: Glass[], configId: string, marquiseType: ElementType,
		roofAreaPoints: IPoint[], frontAreaPoints: IPoint[],
		leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
		click: boolean, hover: boolean, ptr: MouseEvent): void {

		this.marquiseCreator.createMarquiseTop(selectedGlasses, configId, marquiseType, roofAreaPoints, frontAreaPoints, leftAreaPoints, rightAreaPoints, click, hover, ptr);
		if (click) {
			// this.setMarquiseHandlers(marquiseType);
		}
	}

	private anyWindowBelowMarquise() {
		var rwExists = false;

		this.profileService.roofElements[ElementType.MarquiseTopUp].every((m: MarquiseTop) => {
			this.profileService.roofElements[ElementType.RoofWindow].every((rw: RoofWindow) => {
				if (rw.leftX >= m.leftBar.leftX && rw.rightX <= m.rightBar.rightX) {
					rwExists = true;
					return false;
				}
				return true;
			});
			return !rwExists;
		});

		return rwExists;
	}

	public changeMarquiseHandlers() {
		var rwExists = this.anyWindowBelowMarquise();
		this.profileService.roofElements[ElementType.MarquiseTopUp].forEach(m => {
			var leftHandler = m.marquiseInfo.handlers.find(h => (h.roofWindow == rwExists && h.type == m.leftHandler.type && h.widthCorrection == m.leftHandler.widthCorrection));
			var rightHandler = m.marquiseInfo.handlers.find(h => (h.roofWindow == rwExists && h.type == m.rightHandler.type && h.widthCorrection == m.rightHandler.widthCorrection));
			m.leftHandler = leftHandler;
			m.rightHandler = rightHandler;
		});
	}
	
	// ! glasses-wall

	public checkGlassWalls(): boolean {
		return this.profileService.frontElements[ElementType.GlassWall].length > 0
			|| this.profileService.leftSideElements[ElementType.GlassWall].length > 0
			|| this.profileService.leftSideElements[ElementType.GlassWallDiamond].length > 0
			|| this.profileService.rightSideElements[ElementType.GlassWall].length > 0
			|| this.profileService.rightSideElements[ElementType.GlassWallDiamond].length > 0
	}

	public createGlassWall(pointerEvent: IPointer, currentTool: ToolboxItem, srv: IAreaService = null) {
		if (srv == null) {
			srv = this.CurrentAreaService;
			const error = !this.isPossibleToAddGlassWall(pointerEvent, srv.areaType);
			if (error) {
				return;
			}	
		}
		const r = this.getPlaceholder(pointerEvent, null, srv);
		
		var def: GlassWallParameter;
		if (currentTool?.type == ToolboxItemType.GlassWall) {
			def = this.template.wallGlasses.find(g => g.id === currentTool.id);
		} else {
			def = this.template.wallGlasses.find(g => g.isDefault == true) ?? this.template.wallGlasses[0];
		}		

		if (r.h / SvgParams.SCALE < def.minHeight) {
			const message = $localize`:Glass wall|Validation message:Not enough space for glass wall!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return;
		}

		const newGlassWall: GlassWall = this.generateGlassWall(srv, def.id, r);
		this.profileService.addVertical(newGlassWall);

		if (srv.areaType === AreaType.Left || srv.areaType === AreaType.Right) {
			if (!newGlassWall.getNeib(ElementSide.Top)) {
				this.createOrUpdateTopFrame(newGlassWall);
			}
		}

		this.createMuntins(newGlassWall);
		return newGlassWall;
	}

	public getPlaceholder(ptr: IPointer, glass?: GlassWall, srv: IAreaService = null): IRect {
		if (srv == null) {
			srv = this.CurrentAreaService;
		}

		if (!ptr) {
			ptr = { offsetX: glass?.rect.x + 1, offsetY: glass?.rect.y + 1 };
		}
		const wp = srv.getWorkAreaPoints();

		var y = Common.round(wp[0].y);

		const verts = this.profileService.getVerticals(srv.areaType);
		const currentAreaElements = this.getCurrentAreaElements(srv.areaType);
		const cr = srv.rectOnSide;

		const alone = verts.length == 0 || (verts.length == 1 && verts[0].id == glass?.id);
		y = Common.round(wp[0].y);
		if (this.CurrentAreaService instanceof SideBaseService) {
			y = this.CurrentAreaService.getFrontCornerY();
		} else if (this.CurrentAreaService instanceof StraightService) {
			y = this.CurrentAreaService.getTopProfileBottomY();
		}

		var r: IRect = {
			x: Common.round(wp[0].x),
			y: y,
			w: Common.round(wp[1].x - wp[0].x),
			h: Common.round(wp[2].y - wp[1].y),
		};
		var le: number = 0;
		var ri: number = 0;

		if (!alone) {
			const vv = new VirtualVertical(this, ptr, srv.areaType);
			vv.refreshSurround(this.profileService);
			
			const nl = vv.getNeib(ElementSide.Left);
			if (nl) {
				le = nl.rightX;
			}

			const nr = vv.getNeib(ElementSide.Right);
			if (nr) {
				ri = nr.leftX;
			}

			const nt = vv.getNeib(ElementSide.Top);
			if (nt) {
				r.y = Common.round(nt.bottomY);
				r.h = Common.round(wp[2].y - r.y);
			}

			const nb = vv.getNeib(ElementSide.Bottom);
			if (nb) {
				r.h = Common.round(nb.topY - r.y);
			}
		}

		const verticals = [ElementType.Frame, ElementType.Column];

		verticals.forEach(et => {
			const left = currentAreaElements[et]
				.sort((c1: Profile, c2: Profile) => c1[cr].x < c2[cr].x ? 1 : -1)
				.find((c: Profile) => glass?.id != c.id
					&& glass?.leftFrame?.id !== c.id && c[cr].x + c[cr].w <= ptr.offsetX && (c[cr].y <= ptr.offsetY
					&& c[cr].y + c[cr].h >= ptr.offsetY)
					&& c[cr].x + c[cr].w > le);
			if (left && left[cr].x + left[cr].w > r.x) {
				le = left[cr].x + left[cr].w;
			}
		});

		// right can be calculated after left calculation
		verticals.forEach(et => {
			const right = currentAreaElements[et]
				.sort((c1: Profile, c2: Profile) => c1[cr].x > c2[cr].x ? 1 : -1)
				.find((c: Profile) => {
					return (glass?.id != c.id
						&& glass?.rightFrame?.id !== c.id
						&& glass?.topFrame?.id !== c.id
						&& glass?.bottomFrame?.id !== c.id)
						&& c[cr].x >= ptr.offsetX
						&& (c[cr].y <= ptr.offsetY
						&& c[cr].y + c[cr].h >= ptr.offsetY)
						&& (ri == 0 || c[cr].x < ri);
				});

			if (right && right[cr].x < r.x + r.w && ((right.orientation === Orientation.Horizontal) ? right[cr].y >= glass?.rect.y : true)) {
				ri = right[cr].x;
			}
		});

		if (le > 0) {
			r.x = le;
			r.w = Common.round(wp[1].x - r.x);
		}

		if (ri > 0) {
			r.w = Common.round(ri - r.x);
		}

		// If right profile does not exist, width should be adjusted
		if (r.x + r.w > wp[1].x && wp[1].x >= r.x) {
			r.w = wp[1].x - r.x;
		}

		// If bottom profile does not exist, height should be adjusted
		if (r.y + r.h > wp[2].y) {
			r.h = Common.round(wp[2].y - r.y);
		}

		return r;
	}

	public tuneVerticalToFront(at : AreaType, crp: Space, y: number) {
		var frontDoor = this.profileService.getDoors(AreaType.Front)[0];
		const cntHeight = this.template.hiddenExtras.find(profile => profile.id == crp.topProfile.configId).size.height * SvgParams.SCALE;
		const gw = this.profileService.getGetDiamonds(at).length > 0 || (this.profileService.getWalls(at) as Wall[]).filter(w => w.shape.isDiamond).length > 0;

		if (frontDoor && !gw) {
			if (at == AreaType.Front) {
				crp.topProfile.rect.h = frontDoor.topProfile.rect.h;
				crp.topProfile.rect.y = frontDoor.topProfile.rect.y;
			} else {
				crp.topProfile.rect.h = cntHeight;
				crp.topProfile.rect.y = frontDoor.topProfile.rect.y;	
			}
			crp.bottomProfile.rect.y = frontDoor.bottomProfile.rect.y;
			crp.bottomProfile.rect.h = frontDoor.bottomProfile.rect.h;
		} else if (gw && frontDoor) {
			if (frontDoor.topProfile.rect.y > y) {
				crp.topProfile.rect.h = cntHeight;
				crp.topProfile.rect.y = frontDoor.topProfile.rect.y;
			} else {
				crp.topProfile.rect.h = 0;
				crp.topProfile.rect.y = y;
			}
		} else if (gw && !frontDoor) {
			// crp.topProfile.rect.h = 0;
			// crp.topProfile.rect.y = r.y;
		} else {
			crp.topProfile.rect.h = cntHeight;

			const rc = RectName.get(this.areaType);
			if (this.areaType == AreaType.Right && crp.leftProfile instanceof ColumnProfile) {
				crp.topProfile.rect.y = crp.leftProfile[rc].y;
			} else if (this.areaType == AreaType.Left && crp.rightProfile instanceof ColumnProfile) {
				crp.topProfile.rect.y = crp.rightProfile[rc].y;
			}	
		}
		return crp;
	}

	private isPossibleToAddGlassWall(ptr: IPointer, area: AreaType = AreaType.None): boolean {
		if (area == AreaType.None) {
			area = this.currentArea;
		}
		const ar = RectName.get(area);

		const columnBeforeClickedPoint: ColumnProfile = this.getColumnBeforeClickedPoint(ptr, ar);
		const columnAfterClickedPoint: ColumnProfile = this.getColumnAfterClickedPoint(ptr, ar);

		if (!columnBeforeClickedPoint && (area === AreaType.Front || area === AreaType.Right)) {
			const message = $localize`:Glass wall|Validation message:Cannot place glass wall before first column!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		} else if (!columnAfterClickedPoint && (area === AreaType.Front || area === AreaType.Left)) {
			const message = $localize`:Glass wall|Validation message:Cannot place glass wall after last column!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		if (columnBeforeClickedPoint?.isWinkel || columnAfterClickedPoint?.isWinkel) {
			const message = $localize`:Glass wall|Validation message:Cannot mount glass wall to a winkel!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		const mq = this.profileService.getMarquisesVertical(area).find((m: MarquiseVertical) => 
			m.leftProfile[ar].x <= ptr.offsetX && m.rightProfile[ar].x >= ptr.offsetX && m.montage == Montage.Middle);

		if (mq) {
			const message = $localize`:Glass wall|Validation message:Cannot place glass wall on a marquise!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 5000, style: "error" });
			return false;
		}

		return true;
	}

	public getColumnBeforeClickedPoint(pointerEvent: IPointer, currentAreaRect: string): ColumnProfile {
		return this.getCurrentAreaElements()[ElementType.Column]
			.sort((c1: ColumnProfile, c2: ColumnProfile) => c1[currentAreaRect].x < c2[currentAreaRect].x ? 1 : -1)
			.find((c: ColumnProfile) => c[currentAreaRect].x + c[currentAreaRect].w <= pointerEvent.offsetX);
	}

	public getColumnAfterClickedPoint(pointerEvent: IPointer, currentAreaRect: string): ColumnProfile {
		return this.getCurrentAreaElements()[ElementType.Column]
			.sort((c1: ColumnProfile, c2: ColumnProfile) => c1[currentAreaRect].x > c2[currentAreaRect].x ? 1 : -1)
			.find((c: ColumnProfile) => c[currentAreaRect].x >= pointerEvent.offsetX);
	}

	public sortColumns(elements: ColumnProfile[]) {
		var currentAreaRect = this.getCurrentAreaRect();
		return elements.sort((c1: ColumnProfile, c2: ColumnProfile) => c1[currentAreaRect].x > c2[currentAreaRect].x ? 1 : -1)
	}

	// * working with muntins

	public createMuntins(wall: GlassWall, muntinsCount: number = null) {
		wall.muntins = [];
		wall.parts = [];
		
		if (!wall.muntinsRequired && muntinsCount == null) {
			let part = new GlassPart(this.template, wall.def, wall, wall.rect.x, wall.rect.y, wall.width, wall.height);
			wall.parts.push(part);
			wall.minMuntinsCount = 0;
			return;
		}

		let wi = wall.width;
		let surfaceArea = Common.calculateGlassWallSurface(wi, wall.height, GLASS_PART_PADDING);
		let i = (muntinsCount ?? 0) + 1;
		if (muntinsCount == null) {
			while (surfaceArea > wall.maxM2) {
				wi = wall.width / ++i;
				surfaceArea = Common.calculateGlassWallSurface(wi, wall.height, GLASS_PART_PADDING);
			}
		}

		let x = wall.rect.x;
		let w = Common.round(wall.width / i);
		let mnt: MuntinProfile;
		const padding = GLASS_PART_PADDING;

		for (let p = 0; p < i; p++) {
			let part = new GlassPart(this.template, wall.def, wall, x, wall.rect.y, w, wall.height);
			if (mnt) {
				mnt.rightPart = part;
			}
			wall.parts.push(part);
			x += w * SvgParams.SCALE;
			if (p < i - 1) {
				mnt = new MuntinProfile(x - padding, wall.rect.y + padding, wall.height - padding * 2 / SvgParams.SCALE, Orientation.Vertical, wall.id);
				wall.muntins.push(mnt);
				mnt.leftPart = part;
			}
		}

		if (muntinsCount == null) {
			wall.minMuntinsCount = wall.muntins.length;
		}
	}

	public adjustMuntins(mnt: MuntinProfile, newLocation: number) {
		const current = Math.round((mnt.rect.x - SvgParams.START_X) / SvgParams.SCALE);
		const diff = current - newLocation;
		const sd = diff * SvgParams.SCALE;
		const leftPart: GlassPart = (mnt.leftPart as GlassPart);
		const rightPart: GlassPart = (mnt.rightPart as GlassPart);
		if (diff < 0) { // move right
			if (leftPart.muntinsRequired) { // check max m2
				this.showTemporaryMessageSubj.next({ message: "Left glass is too big", hideAfter: 1000, style: 'error' })
				return;
			}
		} else { // move left
			if (rightPart.muntinsRequired) { // check max m2
				this.showTemporaryMessageSubj.next({ message: "Right glass is too big", hideAfter: 1000, style: 'error' })
				return;
			}
		}
		leftPart.rect.w -= sd;
		rightPart.rect.x -= sd;
		rightPart.rect.w += sd;
		mnt.rect.x -= sd;
	}

	// * working with frame


	private createOrUpdateTopFrame(currentGlass: GlassWall) {
		const extrasInfo = this.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		const connectorSize = extrasInfo.size.width * SvgParams.SCALE;
		const r = this.getPlaceholder(null, currentGlass);

		var yPos = currentGlass.topY - connectorSize;
		var xPos = r.x;
		var length = r.w;

		if (!currentGlass.topFrame) {
			const size = extrasInfo.size;
			const color = extrasInfo.color;
			const newFrame = new FrameProfile(xPos, yPos, length, size, color, Orientation.Horizontal, currentGlass.connectorId);
			currentGlass.topFrame = newFrame;
		}

		currentGlass.topFrame.setLength(length / SvgParams.SCALE);
		currentGlass.topFrame.rect.w = length;
		currentGlass.topFrame.rect.y =  yPos;
		currentGlass.topFrame.rect.x = xPos;

	}

	private createOrUpdateBottomFrame(currentGlass: GlassWallBase) {
		var xPos = currentGlass.points[SvgParams.LEFT_BOTTOM_POINT_NUM].x;
		var yPos = currentGlass.bottomY;
		var length = currentGlass.rect.w;

		if (!currentGlass.bottomFrame) {
			const extrasInfo = this.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
			const size = extrasInfo.size;
			const color = extrasInfo.color;
			const newFrame = new FrameProfile(xPos, yPos, length, size, color, Orientation.Horizontal, currentGlass.connectorId);
			currentGlass.bottomFrame = newFrame;
		}

		currentGlass.bottomFrame.setLength(length / SvgParams.SCALE);
		currentGlass.bottomFrame.rect.w = length;
		currentGlass.bottomFrame.rect.y = yPos;
		currentGlass.bottomFrame.rect.x = xPos;
	}

	private updateVerticalFrame(currentGlass: GlassWallBase, side: ElementSide) {

		var xPos = 0;
		if (side == ElementSide.Left) {
			const extrasInfo = this.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
			xPos = currentGlass.rect.x - extrasInfo.size.width * SvgParams.SCALE;
		} else {
			xPos = currentGlass.rect.x + currentGlass.rect.w;
		}

		const yPos = (side == ElementSide.Left) ? currentGlass.points[SvgParams.LEFT_TOP_POINT_NUM].y : currentGlass.points[SvgParams.RIGHT_TOP_POINT_NUM].y;
		var length = 0;
		const neib = currentGlass.getNeib(ElementSide.Bottom);
		if (neib) {
			length = (neib.topY - yPos) / SvgParams.SCALE;
		} else {
			const workAreaPoints = this.CurrentAreaService.getWorkAreaPoints();
			length = (workAreaPoints[SvgParams.LEFT_BOTTOM_POINT_NUM].y - yPos) / SvgParams.SCALE;
		}

		const extrasInfo = this.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		const size = extrasInfo.size;
		const color = extrasInfo.color;
		const orientation = Orientation.Vertical;

		const newFrame = new FrameProfile(xPos, yPos, length, size, color, orientation, currentGlass.connectorId);
		const frameString = side.toLowerCase() + 'Frame';
		currentGlass[frameString] = newFrame;

	}

	public manageFrameAfterGlassChange(currentGlass: GlassWallBase) {
		const extrasInfo = this.template.hiddenExtras.find(profile => profile.id == currentGlass.connectorId);
		if (Math.round(currentGlass.width) == Math.round(currentGlass.maxWidth)) {
			this.removeFrame(currentGlass, 'rightFrame');
		} else {
			// console.debug('manageFrameAfterGlassChange right', currentGlass.width, currentGlass.maxWidth);
			this.updateVerticalFrame(currentGlass, ElementSide.Right);
		}

		if (Math.round(currentGlass.locationX) == Math.round(currentGlass.minLocationX)) {
			this.removeFrame(currentGlass, 'leftFrame');
		} else {
			// console.debug('manageFrameAfterGlassChange left', currentGlass.locationX, currentGlass.minLocationX);
			this.updateVerticalFrame(currentGlass, ElementSide.Left);
		}

		if (currentGlass instanceof GlassWall) {			
			if (Math.round(currentGlass.locationY) == Math.round(currentGlass.minLocationY) && (currentGlass.area == AreaType.Front || currentGlass.area == AreaType.Rear || currentGlass.getNeib(ElementSide.Top))) {
				this.removeFrame(currentGlass, 'topFrame');
			} else {
				this.createOrUpdateTopFrame(currentGlass);
			}
		}

		if ((Math.round(currentGlass.height) == Math.round(currentGlass.maxHeight) || currentGlass.getNeib(ElementSide.Bottom, true)) || currentGlass.hanging) {
			this.removeFrame(currentGlass, 'bottomFrame');
		} else {
			this.createOrUpdateBottomFrame(currentGlass);
		}

	}

	public removeFrame(currentGlass: GlassWallBase, sideFrame: string) {
		const currentAreaElements = this.getCurrentAreaElements();

		if (!currentGlass[sideFrame]) {
			return
		}

		currentAreaElements[ElementType.Frame] = currentAreaElements[ElementType.Frame].filter(frame => {
			return frame.id !== currentGlass[sideFrame].id;
		});
		currentGlass[sideFrame] = null;
	}

	// * getting extreme points for glass-wall

	getMaxWidthOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		const distance = (currentGlass.rect.x - r.x) / SvgParams.SCALE;
		return Math.round(r.w / SvgParams.SCALE) - distance;
	}

	getMaxHeightOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		var distance = Math.round((currentGlass.rect.y - r.y) / SvgParams.SCALE);
		return Math.round(r.h / SvgParams.SCALE) - distance;
	}

	getMaxYLocationOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		var distance = Math.round((r.y - SvgParams.START_Y) / SvgParams.SCALE);
		return distance + Math.round((r.h - currentGlass.rect.h) / SvgParams.SCALE);
	}

	getMinYLocationOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		return Math.round((r.y - SvgParams.START_Y) / SvgParams.SCALE);
	}

	getMaxXLocationOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		var distance = Math.round((r.x - SvgParams.START_Y) / SvgParams.SCALE);
		return distance + Math.round((r.w - currentGlass.rect.w) / SvgParams.SCALE);
	}

	getMinXLocationOfGlassWall(currentGlass: GlassWall): number {
		const r = this.getPlaceholder(null, currentGlass);
		return Math.round((r.x - SvgParams.START_X) / SvgParams.SCALE);
	}
	
	// ! glasses

	public addLeftGlass(firstGlass: Glass) {
		const currArRect = this.getCurrentAreaRect();

		var newBar: BarProfile = new BarProfile(
			this.currentArea,
			firstGlass.rect.x,
			firstGlass.rightBar[currArRect].y,
			this.template,
			firstGlass.rightBar.getSVGLength(),
			Orientation.Vertical,
			AreaType.None,
			this.template.barId
		);

		const barW = newBar.getBarsDefaultWidth(this.template);
		const barH = newBar.getBarsDefaultHeight(this.template);

		if (!firstGlass.locked) {
			firstGlass.rect.w -= barW * SvgParams.SCALE;
		}

		var area = this.getCurrentAreaElements();

		let bottomGlass: Glass;
		let topGlass: Glass;
		let newBtBar: ProfileConnector

		// firstGlass.rightBar.removeGlass(ElementSide.Left, firstGlass);
		var sideFin = firstGlass.leftBar;
		var glasses = sideFin.getGlasses(ElementSide.Right);
		glasses.forEach((g: Glass) => {
			var newGlass = this.generateGlass(
				g.configId,
				g.rect.x + Common.round(g.stat.paddingLeft * SvgParams.SCALE),
				g.rect.y + Common.round(g.stat.paddingTop * SvgParams.SCALE),
				0,
				g.height - g.stat.paddingBottom - g.stat.paddingTop,
				this.template.glassLength
			);

			newBar.addGlass(ElementSide.Left, newGlass);
			newBar.addGlass(ElementSide.Right, g);

			newGlass.leftBar = sideFin;
			newGlass.rightBar = newBar;

			g.leftBar = newBar;

			sideFin.removeGlass(ElementSide.Right, g);
			sideFin.addGlass(ElementSide.Right, newGlass);

			area[ElementType.Glass].push(newGlass);
			if (g.topBar) {
				bottomGlass = newGlass;
			}
				if (g.bottomBar) {
				topGlass = newGlass;
				newBtBar = new ProfileConnector(
					this.currentArea,
					newGlass.rect.x,
					g.rect.y + g.rect.h - g.stat.paddingBottom * SvgParams.SCALE,
					this.template,
					newGlass.width,
					Orientation.Horizontal,
					AreaType.Roof,
					this.template.connectorDef.id
				);

				newGlass.bottomBar = newBtBar;

				area[ElementType.ProfileConnector].push(newBtBar);
			}
		});

		if (newBtBar) {
			newBtBar.topGlass = topGlass;
			newBtBar.bottomGlass = bottomGlass;
			topGlass.bottomBar = newBtBar;
			bottomGlass.topBar = newBtBar;
		}
		area[ElementType.Bar].push(newBar);

		if (this.currentArea === AreaType.Roof) {
			const bh = this.template.backHeight;
			const fh = this.template.frontHeight;

			newBar.rectOnFront = {
				x: newBar.rectOnRoof.x,
				y: SvgParams.START_Y + (bh - fh) * SvgParams.SCALE,
				w: barW * SvgParams.SCALE,
				h: barH * SvgParams.SCALE,
			}

			this.profileService.frontElements[ElementType.Bar].push(newBar);
		}
	}

	public addRightGlass(lastGlass: Glass, width: number = null): void {
		const currArRect = "rectOnRoof";
		const barW = lastGlass.leftBar.getBarsDefaultWidth(this.template);
		const barH = lastGlass.leftBar.getBarsDefaultHeight(this.template);

		const wd = width == null ? lastGlass.rect.w : width;
		if (!lastGlass.locked) {
			lastGlass.rect.w = wd - barW * SvgParams.SCALE;
		}

		let newBar: BarProfile = new BarProfile(
			this.currentArea,
			lastGlass.rect.x + wd - lastGlass.stat.paddingLeft * SvgParams.SCALE,
			lastGlass.leftBar[currArRect].y,
			this.template,
			lastGlass.leftBar.getSVGLength(),
			Orientation.Vertical,
			AreaType.None,
			this.template.barId
		);

		let area = this.profileService.roofElements;

		let bottomGlass: Glass;
		let topGlass: Glass;
		let newBtBar: ProfileConnector

		lastGlass.rightBar.getGlasses(ElementSide.Left).forEach((g: Glass) => {

			let newGlass = this.generateGlass(
				g.configId,
				newBar[currArRect].x + newBar[currArRect].w - Common.round(g.stat.paddingLeft * SvgParams.SCALE),
				g.rect.y + Common.round(g.stat.paddingTop * SvgParams.SCALE),
				g.minWidth,
				g.height - g.stat.paddingBottom - g.stat.paddingTop,
				this.template.glassLength
			);

			newBar.addGlass(ElementSide.Right, newGlass);
			newBar.addGlass(ElementSide.Left, g);

			newGlass.leftBar = newBar;
			newGlass.rightBar = lastGlass.rightBar;

			area[ElementType.Glass].push(newGlass);

			if (g.topBar) {
				bottomGlass = newGlass;
			}
			if (g.bottomBar) {
				topGlass = newGlass;
				newBtBar = new ProfileConnector(
					this.currentArea,
					newGlass.rect.x,
					g.rect.y + g.rect.h - g.stat.paddingBottom * SvgParams.SCALE,
					this.template,
					newGlass.width,
					Orientation.Horizontal,
					AreaType.Roof,
					this.template.connectorDef.id
				);
				area[ElementType.ProfileConnector].push(newBtBar);
			}
		});

		if (newBtBar) {
			newBtBar.topGlass = topGlass;
			newBtBar.bottomGlass = bottomGlass;
			topGlass.bottomBar = newBtBar;
			bottomGlass.topBar = newBtBar;
		}


		lastGlass.rightBar = newBar;
		area[ElementType.Bar].push(newBar);

		if (this.currentArea === AreaType.Roof) {
			const bh = this.template.backHeight;
			const fh = this.template.frontHeight;

			newBar.rectOnFront = {
				x: newBar.rectOnRoof.x,
				y: SvgParams.START_Y + (bh - fh) * SvgParams.SCALE,
				w: barW * SvgParams.SCALE,
				h: barH * SvgParams.SCALE,
			}

			this.profileService.frontElements[ElementType.Bar].push(newBar);
		}
	}

	public generateGlass(catId: string, x: number, y: number, w: number, h: number, l: number,
			paddingTop?: number, paddingBottom?: number): Glass {
		if (!catId) {
			catId = this.template.glassId;
		}
		let glassInfo = this.template.glasses.find(g => g.id === catId);
		return new Glass(this.template, glassInfo, x, y, w, h, l, paddingTop, paddingBottom);
	}

	public generateGlassWall(sideService: IAreaService, catId: string, r: IRect, hanging: boolean = false): GlassWall {
		let wallDef = this.template.wallGlasses.find(g => g.id === catId);
		let glassDef = this.template.glasses.find(g => g.id == wallDef.glassComponentId);
		return new GlassWall(this, sideService, wallDef, glassDef, r.x, r.y, r.w, r.h, sideService.areaType, hanging);
	}

	public divideGlassVertical(glass: Glass) {
		// const barW = BarProfile.getDefaultWidth(this.template);
		// const barH = BarProfile.getDefaultHeight(this.template);

		const currArRect = this.getCurrentAreaRect();

		let newBar: BarProfile;
		let newGlass: Glass
		const barPattern = glass.isFirst ? glass.rightBar : glass.leftBar;
		const barW = barPattern.getBarsDefaultWidth(this.template);
		const barH = barPattern.getBarsDefaultHeight(this.template);

		glass.rect.w = glass.rect.w / 2 - (barPattern[currArRect].w / 2) * SvgParams.SCALE;

		const newBarXLocation = glass.rect.x + glass.rect.w - glass.stat.paddingLeft * SvgParams.SCALE

		newBar = new BarProfile(
			this.currentArea,
			newBarXLocation,
			barPattern[currArRect].y,
			this.template,
			barPattern.getLength(),
			Orientation.Vertical,
			AreaType.None,
			this.template.barId
		);

		newGlass = this.generateGlass(
			glass.configId,
			newBar[currArRect].x + newBar[currArRect].w - glass.stat.paddingLeft * SvgParams.SCALE,
			glass.rect.y + glass.stat.paddingTop * SvgParams.SCALE,
			glass.width - glass.stat.paddingLeft - glass.stat.paddingRight,
			glass.height - glass.stat.paddingBottom - glass.stat.paddingTop,
			this.template.glassLength
		);

		newGlass.leftBar = newBar;
		newGlass.rightBar = glass.rightBar;
		newBar.leftGlass = glass;
		newBar.rightGlass = newGlass;
		glass.rightBar = newBar;

		var area = this.getCurrentAreaElements();
		if (this.currentArea === AreaType.Roof) {
			newBar.rectOnFront = {
				x: newBarXLocation,
				y: SvgParams.START_Y + (this.template.backHeight - this.template.frontHeight) * SvgParams.SCALE,
				w: Math.round(barW * SvgParams.SCALE),
				h: Math.round(barH * SvgParams.SCALE)
			};
			this.profileService.frontElements[ElementType.Bar].push(newBar);
		}

		area[ElementType.Bar].push(newBar);
		area[ElementType.Glass].push(newGlass);

		this.arrangeLeds();
		this.emitChange();
	}

	public divideGlassHorizontal(glasses: Glass[]) {
		const currArRect = "rectOnRoof";
		var area = this.profileService.roofElements;

		let bars: IGroupable[] = [];
		let topGlasses: IGroupable[] = [];
		let bottGlasses: IGroupable[] = [];

		glasses.forEach((glass: Glass) => {
			glass.rect.h = glass.rect.h / 2 - glass.stat.paddingTop / 2 * SvgParams.SCALE;
			glass.length = glass.rect.h / SvgParams.SCALE;

			var newBar: ProfileConnector = new ProfileConnector(
				this.currentArea,
				glass.rect.x,
				glass.rect.y + glass.rect.h - glass.stat.paddingBottom * SvgParams.SCALE,
				this.template,
				glass.width,
				Orientation.Horizontal,
				AreaType.Roof,
				this.template.connectorDef.id
			);

			var newGlass: Glass = this.generateGlass(
				glass.configId,
				newBar[currArRect].x + glass.stat.paddingLeft * SvgParams.SCALE,
				newBar[currArRect].y + newBar[currArRect].h,
				glass.width - glass.stat.paddingLeft - glass.stat.paddingRight,
				glass.height - glass.stat.paddingBottom - glass.stat.paddingTop,
				glass.length
			);

			if (glass.leftBar) {
				newGlass.leftBar = glass.leftBar;
				newGlass.leftBar.addGlass(ElementSide.Right, newGlass);
			}

			if (glass.rightBar) {
				newGlass.rightBar = glass.rightBar;
				newGlass.rightBar.addGlass(ElementSide.Left, newGlass);
			}
			newGlass.topBar = newBar;

			glass.bottomBar = newBar;

			newBar.topGlass = glass;
			newBar.bottomGlass = newGlass;

			area[ElementType.ProfileConnector].push(newBar);
			area[ElementType.Glass].push(newGlass);

			this.displayRoofBarOnLeft(glass, newBar)
			this.displayRoofBarOnRight(glass, newBar)

			bars.push({ id: newBar.id });
			topGlasses.push({ id: glass.id });
			bottGlasses.push({ id: newGlass.id });
		});

		this.grouper.createGroup(bars);
		this.grouper.createGroup(topGlasses);
		this.grouper.createGroup(bottGlasses);
	}

	private displayRoofBarOnLeft(glass: Glass, newBar: BarProfile) {
		if (this.currentArea === AreaType.Roof && glass.leftBar.type === ElementType.SideFinish) {
			const barW = newBar.getBarsDefaultWidth(this.template);
			const barH = newBar.getBarsDefaultHeight(this.template);
			const bh = this.template.backHeight;
			const ph = this.getXPositionHeight((newBar.rectOnRoof.y - SvgParams.START_Y) / SvgParams.SCALE);

			newBar.rectOnLeft = {
				x: newBar.rectOnRoof.y,
				y: SvgParams.START_Y + (bh - ph) * SvgParams.SCALE + 1,
				w: Math.round(barW * SvgParams.SCALE),
				h: Math.round(barH * SvgParams.SCALE)
			};
			this.profileService.leftSideElements[ElementType.Bar].push(newBar);
		}
	}

	private displayRoofBarOnRight(glass: Glass, newBar: BarProfile) {
		if (this.currentArea === AreaType.Roof && glass.rightBar.type === ElementType.SideFinish) {
			const barW = newBar.getBarsDefaultWidth(this.template);
			const barH = newBar.getBarsDefaultHeight(this.template);
			const bh = this.template.backHeight;
			const ph = this.getXPositionHeight((newBar.rectOnRoof.y - SvgParams.START_Y) / SvgParams.SCALE);

			newBar.rectOnRight = {
				x: this.template.depth * SvgParams.SCALE + SvgParams.START_X - (newBar.rectOnRoof.y - SvgParams.START_Y) - Math.round(barW * SvgParams.SCALE),
				y: SvgParams.START_Y + (bh - ph) * SvgParams.SCALE + 1,
				w: Math.round(barW * SvgParams.SCALE),
				h: Math.round(barH * SvgParams.SCALE)
			};
			this.profileService.rightSideElements[ElementType.Bar].push(newBar);
		}
	}

	public calcNewGlassWidthOnRight(profile: BarProfile, diff: number) {
		const currArRect = this.getCurrentAreaRect();

		let gla = profile.rightGlass;

		let barsSize = 0;
		let glassCount = 0;
		let lockedSize = 0;
		let startX = 0;

		let stat: IStatics;
		if (gla != null || this._currentArea !== AreaType.Roof) {
			stat = gla.stat;
			startX = gla.leftBar[currArRect].x + gla.leftBar[currArRect].w;
		} else {
			stat = profile.leftGlass.stat;
			startX = profile.lineOnRoof.x1 - profile.lineOnRoof.w / 2;
		}
		let stopX = startX;

		while (gla != null) {
			if (gla.locked) {
				lockedSize += gla.width - stat.paddingLeft - stat.paddingRight;
			} else {
				glassCount++;
			}
			if (gla.isLast) {
				// if (this._currentArea === AreaType.Roof) {
				if (gla.rightBar.type === ElementType.SideFinish) {
					stopX = gla.rightBar.lineOnRoof.x1 - this.template.getBarSize().width * SvgParams.SCALE / 2
				} else {
					stopX = gla.rightBar[currArRect].x;
				}
				break;
			}
			barsSize += gla.rightBar[currArRect].w;
			gla = gla.rightBar.rightGlass;
			if (glassCount > 50) {
				break;
			}
		}
		return this.calcNewGlassWidth(stat, diff, startX, stopX, barsSize, glassCount, lockedSize);
	}

	public calcNewGlassWidthOnLeft(leftProfile: BarProfile, diff: number) {
		const currArRect = this.getCurrentAreaRect();

		let gla = leftProfile.leftGlass;

		let barsSize = 0;
		let glassCount = 0;
		let lockedSize = 0;
		let stat = gla.stat;
		let stopX = leftProfile[currArRect].x;
		let startX = stopX;

		while (gla != null) {
			if (gla.locked) {
				lockedSize += gla.width - stat.paddingLeft - stat.paddingRight;
			} else {
				glassCount++;
			}
			if (gla.isFirst) {
				if (gla.leftBar.type === ElementType.SideFinish) {
					startX = gla.leftBar.lineOnRoof.x1 + gla.leftBar.lineOnRoof.w / 2;
				} else {
					startX = gla.leftBar[currArRect].x + gla.leftBar[currArRect].w;
				}
				break;
			}
			barsSize += gla.leftBar[currArRect].w;
			gla = gla.leftBar.leftGlass;
		}
		return this.calcNewGlassWidth(stat, diff, startX, stopX, barsSize, glassCount, lockedSize);
	}

	private calcNewGlassWidth(stat: IStatics, diff: number, start: number, stop: number, barsSize: number, glassCount: number, lockedSize: number) {
		if (glassCount == 0) { // everything is locked
			return 0;
		}

		const p = (stat.paddingRight + stat.paddingLeft) * SvgParams.SCALE;
		var glassSize = ((stop - start - barsSize + glassCount * p) / SvgParams.SCALE) - lockedSize;
		glassSize = Math.round(glassSize * 100) / 100;
		const newSize = Math.round(((glassSize) / glassCount) * 100) / 100;

		return newSize;
	}

	private move(bar: BarProfile, diff: number) {
		const currArRect = this.getCurrentAreaRect();

		// find extreme left glass (no bar on the left or locked bar)
		var gla = bar.leftGlass;
		while (!gla.isFirst) {
			gla = gla.leftBar.leftGlass;
		}
		const d = diff * SvgParams.SCALE;
		while (true) {
			gla.rect.x += d;
			if (gla.rightBar.id == bar.id) {
				break;
			}
			gla.rightBar[currArRect].x += d;
			gla = gla.rightBar.rightGlass;
		}
	}

	public deleteLeftBar(bar: BarProfile) {
		const currArRect = this.getCurrentAreaRect();

		let lb: BarProfile;
		if (bar.leftGlass != null && bar.leftGlass.leftBar != null) {
			lb = bar.leftGlass.leftBar;
			// const barDataObj = bar.leftGlass.isFirst && this.currentArea === AreaType.Roof ? "lineOnRoof" : currArRect
			const barDataObj = lb.type === ElementType.SideFinish ? "lineOnRoof" : currArRect
			if (lb[barDataObj].x + lb[barDataObj].w > bar[currArRect].x) {
				var rg = lb.rightGlass;
				var lg = lb.leftGlass;

				lg.rightBar = bar;
				bar.leftGlass = lg;

				if (bar.leftGlass.locked) {
					bar.leftGlass.rect.x += lb[currArRect].w + lg.stat.paddingRight * SvgParams.SCALE;
				} else {
					bar.leftGlass.rect.w += lb[currArRect].w + lg.stat.paddingRight * SvgParams.SCALE;
				}

				var area = this.getCurrentAreaElements();
				const lbId = lb.id;
				area[ElementType.Bar] = this.profileService.roofElements[ElementType.Bar].filter(b => b.id != lbId);
				area[ElementType.Glass] = this.profileService.roofElements[ElementType.Glass].filter(g => g.id != rg.id);
				if (this.currentArea === AreaType.Roof) {
					this.profileService.frontElements[ElementType.Bar] = this.profileService.frontElements[ElementType.Bar].filter(b => b.id != lbId);
				}

				return true;
			}
		}
		return false;
	}

	public adjustLeft(chosenGlass: Glass, diff: number, iter: number = 0) {
		const currArRect = this.getCurrentAreaRect();
		const newGlassWidth = this.calcNewGlassWidthOnLeft(chosenGlass.leftBar, diff);

		// if (newGlassWidth == 0) { // everything on the left is locked, no size change, only move
		// 	this.move(chosenGlass.leftBar, diff);
		// 	return;
		// }

		let invalid = false;
		let gla = this.getFirstGlass(chosenGlass);
		// this.scanGlasses(gla);

		let x = gla.rect.x;
		while (gla != null) {
			let ngw = Math.round(newGlassWidth * 100 * SvgParams.SCALE) / 100;
			gla.rightBar.getGlasses(ElementSide.Left).forEach((g: Glass) => {
				g.rect.x = x;
				if (g.topBar) {
					g.topBar.rectOnRoof.x = x;
				} else if (g.bottomBar) {
					g.bottomBar.rectOnRoof.x = x;
				}
			});
			if (!gla.locked) {
				if (!invalid && !gla.validate(newGlassWidth, gla.height)) {
					invalid = true;
				}
				gla.rightBar.getGlasses(ElementSide.Left).forEach((g: Glass) => {
					g.rect.w = ngw;
					if (g.topBar) {
						g.topBar.rectOnRoof.w = ngw;
					} else if (g.bottomBar) {
						g.bottomBar.rectOnRoof.w = ngw;
					}
				});
			}
			if (gla.locked && gla.isFirst) {
				invalid = true;
			}
			if (gla.rightBar.id !== chosenGlass.leftBar.id) {
				if (gla.rightBar[currArRect]) {
					gla.rightBar[currArRect].x = gla.rect.x + gla.rect.w - (gla.stat.paddingLeft * SvgParams.SCALE);
				}
				if (this.currentArea === AreaType.Roof) {
					gla.rightBar.rectOnFront.x = gla.rect.x + gla.rect.w - (gla.stat.paddingLeft * SvgParams.SCALE);
				}

				x = gla.rightBar[currArRect].x + gla.rightBar[currArRect].w - (gla.stat.paddingLeft * SvgParams.SCALE);
				gla = gla.rightBar.rightGlass;
			} else {
				gla = null;
			}
		}

		if (invalid && iter == 0) {
			let firstGlass = this.getFirstGlass(chosenGlass);
			this.addLeftGlass(firstGlass);
			this.adjustLeft(chosenGlass, diff, iter + 1);			
		}
	}

	public deleteRightBar(bar: BarProfile) {
		const currArRect = this.getCurrentAreaRect();

		let rb: BarProfile;
		if (bar.rightGlass != null && bar.rightGlass.rightBar != null && bar.rightGlass.rightBar.type != ElementType.SideFinish) {
			rb = bar.rightGlass.rightBar;
			if (rb.leftX < bar.rightX) {
				var rg = rb.rightGlass;
				var lg = rb.leftGlass;

				lg.rightBar = bar;
				// bar.leftGlass = lg;

				if (bar.leftGlass.locked) {
					bar.leftGlass.rect.x += rb[currArRect].w + lg.stat.paddingRight * SvgParams.SCALE;
				} else {
					bar.leftGlass.rect.w += rb[currArRect].w + lg.stat.paddingRight * SvgParams.SCALE;
				}

				var area = this.getCurrentAreaElements();
				const rbId = rb.id;
				area[ElementType.Bar] = this.profileService.roofElements[ElementType.Bar].filter(b => b.id != rbId);
				area[ElementType.Glass] = this.profileService.roofElements[ElementType.Glass].filter(g => g.id != rg.id);
				if (this.currentArea === AreaType.Roof) {
					this.profileService.frontElements[ElementType.Bar] = this.profileService.frontElements[ElementType.Bar].filter(b => b.id != rbId);
				}

				return true;
			}
		}
		return false;
	}

	public adjustCurrent(glass: Glass) {
		glass.leftBar.getGlasses(ElementSide.Right).forEach((g: Glass) => {
			g.rect.x = glass.rect.x;
			if (g.topBar) {
				g.topBar.rectOnRoof.x = glass.rect.x;
			} else if (g.bottomBar) {
				g.bottomBar.rectOnRoof.x = glass.rect.x;
			}

			g.rect.w = glass.rect.w;
			if (g.topBar) {
				g.topBar.rectOnRoof.w = glass.rect.w;
			} else if (g.bottomBar) {
				g.bottomBar.rectOnRoof.w = glass.rect.w;
			}

		});

	}
	public adjustRight(glass: Glass, diff: number, iter: number = 0) {
		const currArRect = this.getCurrentAreaRect();

		var newGlassWidth = this.calcNewGlassWidthOnRight(glass.rightBar, diff);
		if (newGlassWidth < glass.minWidth) {
			return;
		}

		var gla = glass.rightBar.rightGlass;
		var invalid = false;
		var x: number;

		if (gla != null) {
			x = glass.rightBar[currArRect].x + glass.rightBar[currArRect].w - (glass.stat.paddingLeft * SvgParams.SCALE);
		}
		while (gla != null) {
			var ngw = Common.round(newGlassWidth * SvgParams.SCALE);
			gla.leftBar.getGlasses(ElementSide.Right).forEach((g: Glass) => {
				g.rect.x = x;
				if (g.topBar) {
					g.topBar.rectOnRoof.x = x;
				} else if (g.bottomBar) {
					g.bottomBar.rectOnRoof.x = x;
				}

			});
			if (!gla.locked) {
				if (!invalid && !gla.validate(newGlassWidth, gla.height)) {
					invalid = true;
				}
				gla.leftBar.getGlasses(ElementSide.Right).forEach((g: Glass) => {
					g.rect.w = ngw;
					if (g.topBar) {
						g.topBar.rectOnRoof.w = ngw;
					} else if (g.bottomBar) {
						g.bottomBar.rectOnRoof.w = ngw;
					}
				});
			}
			if (gla.locked && gla.isLast) {
				invalid = true;
			}
			if (!gla.isLast) {
				gla.rightBar[currArRect].x = gla.rect.x + gla.rect.w - (gla.stat.paddingLeft * SvgParams.SCALE);
				if (this.currentArea === AreaType.Roof) {
					gla.rightBar.rectOnFront.x = gla.rect.x + gla.rect.w - (gla.stat.paddingLeft * SvgParams.SCALE);
				}

				x = gla.rightBar[currArRect].x + gla.rightBar[currArRect].w - (gla.stat.paddingLeft * SvgParams.SCALE);
				gla = gla.rightBar.rightGlass;
			} else {
				gla = null;
			}
		}

		if (invalid && iter == 0) {
			let lastGlass = this.getLastGlass(glass)
			this.addRightGlass(lastGlass);
			this.adjustRight(glass, diff, iter + 1);
		}
	}

	public addBarsConnectors() {
		const glasses = this.profileService.roofElements[ElementType.Glass].filter((g: Glass) => {
			return g.shouldBeDivided;
		});
		glasses.forEach((g: Glass) => {
			this.divideGlassHorizontal([g]);
		});
		if (glasses.length > 0) {
			this.emitChange();
		}

	}

	private getFirstGlass(glass: Glass): Glass {
		while (!glass.isFirst) {
			glass = glass.leftBar.leftGlass;
		}
		return glass
	}

	public scanGlasses(glass: Glass) {
		// console.debug("=== scan glasses ===");
		// console.debug('glass', glass.id, 'l:', glass.leftBar.id, 'r:', glass.rightBar.id, glass.isFirst, glass.isLast);
		// console.debug('   rightBar leftGlasses', glass.rightBar.getGlasses(ElementSide.Left), 'rightGlasses', glass.rightBar.getGlasses(ElementSide.Right));
		// var i = 0;
		// while (glass && !glass.isLast && i++ <= 20) {
		// 	glass = glass.rightBar.rightGlass;
		// 	console.debug('glass', glass.id, 'l:', glass.leftBar.id, 'r:', glass.rightBar.id, glass.isFirst, glass.isLast);
		// 	console.debug('   rightBar leftGlasses', glass.rightBar.getGlasses(ElementSide.Left), 'rightGlasses', glass.rightBar.getGlasses(ElementSide.Right));
		// }
		console.debug("");

		console.debug("=== all glasses ===");
		this.profileService.roofElements[ElementType.Glass].forEach(glass => {
			console.debug('glass', glass.id, 'l:', glass.leftBar.id, 'r:', glass.rightBar.id, glass.isFirst, glass.isLast);
		});
		console.debug("");
	}

	private getLastGlass(glass: Glass): Glass {
		while (!glass.isLast) {
			glass = glass.rightBar.rightGlass;
		}
		return glass
	}

	private getLeftUnlockedGlass(glass: Glass): Glass {
		while (glass !== null && glass.locked) {
			glass = glass.leftBar.leftGlass;
		}
		return glass
	}

	private getRightUnlockedGlass(glass: Glass): Glass {
		while (glass !== null && glass.locked) {
			glass = glass.rightBar.rightGlass;
		}
		return glass
	}

	// private getLeftGlassWithAllowableWidth (glass: Glass): Glass {
	// 	while(glass !== null && glass.locked || glass.width <= glass.stat.minWidth + 150) {
	// 		glass = glass.leftBar.leftGlass;
	// 	}
	// 	return glass
	// }

	// private getRightGlassWithAllowableWidth (glass: Glass): Glass {
	// 	while(glass !== null) {
	// 		if (glass.locked || glass.width <= glass.stat.minWidth + 150) {
	// 			glass = glass.rightBar.rightGlass;
	// 		} else {
	// 			break
	// 		}
	// 	}
	// 	return glass
	// }

	public canMoveLeft(bar: BarProfile, diff: number): boolean {
		let leftGlass = bar.leftGlass;

		if (leftGlass.locked) {
			if (leftGlass.isFirst) {
				return false;
			}
			leftGlass = this.getLeftUnlockedGlass(leftGlass)
			if (leftGlass === null) {
				return false;
			}
		}

		// leftGlass = this.getLeftGlassWithAllowableWidth(leftGlass);
		if (leftGlass.width <= leftGlass.minWidth) {
			return false;
		}

		return true;

		// if (!leftGlass.locked) {
		// 	return true;
		// }
		// while (!leftGlass.isFirst) {
		// 	leftGlass = leftGlass.leftBar.leftGlass;
		// 	if (!leftGlass.locked) {
		// 		return true;
		// 	}
		// }
		// return leftGlass.rect.x + diff >= SvgParams.START_X;
	}

	public canMoveRight(bar: BarProfile, diff: number): boolean {

		let rightGlass = bar.rightGlass;

		if (rightGlass.locked) {
			if (rightGlass.isFirst) {
				return false
			}
			rightGlass = this.getRightUnlockedGlass(rightGlass)
			if (rightGlass === null) {
				return false
			}
		}

		// rightGlass = this.getRightGlassWithAllowableWidth(rightGlass);
		if (rightGlass.width <= rightGlass.minWidth) {
			return false
		}

		return true;

		// var gla = bar.rightGlass;
		// if (!gla.locked) {
		// 	return true;
		// }
		// while (!gla.isLast) {
		// 	gla = gla.rightBar.rightGlass;
		// 	if (!gla.locked) {
		// 		return true;
		// 	}
		// }
		// return gla.rect.x + gla.rect.w + diff <= SvgParams.START_X + this.width * SvgParams.SCALE - SideFinishProfile.DEFAULT_WIDTH;
	}

	createColumn(x: number, y: number, clickedProfile: Profile, currentToolInfo) {
		const frontH = this.template.getFrontSize().height;
		let column: ColumnProfile;

		var depthDiff = clickedProfile.type == ElementType.SideFinish ? 0: clickedProfile.rectOnRoof.h - currentToolInfo.size.depth * SvgParams.SCALE;
		var newColY = clickedProfile.type == ElementType.SideFinish ? 0 : clickedProfile.rectOnRoof.y + depthDiff;
		var ir = false;
		let clickedElement: FrontProfile | WallProfile = clickedProfile as FrontProfile;

		const columnsSortedByY = this.profileService.roofElements[ElementType.Column]
			.sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRoof.y > c2.rectOnRoof.y ? 1 : -1);
		const nestedColumn: ColumnProfile = columnsSortedByY
			.find((c: ColumnProfile) => c.rectOnRoof.y > y && c.rectOnRoof.y < y + currentToolInfo.size.depth * SvgParams.SCALE)
		if (nestedColumn) {
			y = nestedColumn.rectOnRoof.y - currentToolInfo.size.depth * SvgParams.SCALE
		}

		switch (clickedProfile.type) {
			case ElementType.WallProfile:
				ir = true;
				newColY = clickedProfile.rectOnRoof.y;
				clickedElement = clickedProfile as WallProfile;				
			case ElementType.Front:
				newColY = clickedProfile.rectOnRoof.y;
				const columnsSortedByX = this.profileService.roofElements[ElementType.Column]
					.filter(f => f.isRear == ir)
					.sort((c1: ColumnProfile, c2: ColumnProfile) => c1.rectOnRoof.x > c2.rectOnRoof.x ? 1 : -1);

				const nestedColumn: ColumnProfile = columnsSortedByX
					.filter(f => f.isRear == ir)
					.find((c: ColumnProfile) => c.rectOnRoof.x > x && c.rectOnRoof.x < x + currentToolInfo.size.width * SvgParams.SCALE)

				if (nestedColumn) {
					x = nestedColumn.rectOnRoof.x - currentToolInfo.size.width * SvgParams.SCALE
				}

				const colLenght = this.template.frontHeight;
				column = new ColumnProfile(this.currentArea, x, newColY, colLenght, AreaType.Roof, currentToolInfo.id, this.template);
				column.foundation = this.template.getDefaultFoundation();
				column.isFront = !ir;
				column.isRear = ir;
				column.tag = 'manual';
				column.assignFront(clickedProfile);

				if (column.isFront) {
					column.rectOnFront = {
						x: x,
						y: clickedProfile.rectOnFront.y + clickedProfile.rectOnFront.h,
						w: column.rectOnRoof.w,
						h: colLenght * SvgParams.SCALE,
					};
					this.profileService.frontElements[ElementType.Column].push(column);
				}
				this.profileService.roofElements[ElementType.Column].push(column);

				clickedElement.columns.push(column);
				clickedElement.columns = this.sortColumns(clickedElement.columns);
				
				break;
			case ElementType.SideFinish:
				const swHeight = this.template.getBarSize().height;

				if (clickedProfile.tag === Tags.LEFT_SIDEFINISH_TAG) {
					const newColX = clickedProfile.lineOnRoof.x1 - clickedProfile.lineOnRoof.w / 2;
					column = new ColumnProfile(this.currentArea, newColX, y, this.template.frontHeight, AreaType.Roof, currentToolInfo.id, this.template);
					column.foundation = this.template.getDefaultFoundation();
					this.profileService.roofElements[ElementType.Column].push(column);

					const xOnSide = y;
					const endColumnPositionDepthOnSide = (xOnSide + Math.round(column.rectOnRoof.h * SvgParams.SCALE) - SvgParams.START_X) / SvgParams.SCALE;
					this.setRectForColumnOnSide(column, xOnSide, frontH, endColumnPositionDepthOnSide, swHeight, 'rectOnLeft');
					this.profileService.leftSideElements[ElementType.Column].push(column);

				} else if (clickedProfile.tag === Tags.RIGHT_SIDEFINISH_TAG) {
					const widthDiff = clickedProfile.lineOnRoof.w - currentToolInfo.size.width * SvgParams.SCALE;
					const newColX = clickedProfile.lineOnRoof.x1 - clickedProfile.lineOnRoof.w / 2 + widthDiff;
					column = new ColumnProfile(this.currentArea, newColX, y, this.template.frontHeight, AreaType.Roof, currentToolInfo.id, this.template);
					column.foundation = this.template.getDefaultFoundation();
					this.profileService.roofElements[ElementType.Column].push(column);

					const xOnSide = SvgParams.START_X + (this.template.depth * SvgParams.SCALE) - (y - SvgParams.START_Y) - Math.round(column.rectOnRoof.h * SvgParams.SCALE);
					const endColumnPositionDepthOnSide = this.template.depth - (xOnSide - SvgParams.START_X) / SvgParams.SCALE;
					this.setRectForColumnOnSide(column, xOnSide, frontH, endColumnPositionDepthOnSide, swHeight, 'rectOnRight');
					this.profileService.rightSideElements[ElementType.Column].push(column);
				}

				break;
		}

		return column;
	}

	public checkConflictWithColumn(x: number, y: number, prof: Profile){
		var noConflict = true;
		switch(prof.type){
			case ElementType.Front:
				this.profileService.getVerticals(AreaType.Front).forEach(e => {				
					if(x > e.rectOnFront.x && x < e.rectOnFront.x + e.rectOnFront.w){
						noConflict = false;
					}
				});
				break;
			case ElementType.SideFinish:
				var area = prof.tag == Tags.LEFT_SIDEFINISH_TAG ? AreaType.Left : AreaType.Right;
				this.profileService.getVerticals(area).forEach(e => {
					if(y > e.rectOnFront.y && y < e.rectOnFront.y + e.rectOnFront.h){
						noConflict = false;
					}
				});
				break;
			case ElementType.WallProfile:
				this.profileService.getVerticals(AreaType.Rear).forEach(e => {					
					if(x > e.rectOnRear.x && x < e.rectOnRear.x + e.rectOnFront.w){
						noConflict = false;
					}
				});
				break;
		}
		
		if(!noConflict){
			const message = $localize`:Common|Message:Cannot place column here!`;
			this.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
		}
		return noConflict;
	}

	public setRectForColumnOnSide(column: ColumnProfile, xOnSide: number, front: number, endColumnPositionDepthOnSide: number, sideFinishHeight: number, rectOnSide: string) {

		const heightOfColumnPosition = this.getXPositionHeight(endColumnPositionDepthOnSide);
		const yOnSide = SvgParams.START_Y + (this.template.backHeight - heightOfColumnPosition + front + sideFinishHeight) * SvgParams.SCALE;

		column[rectOnSide] = {
			x: xOnSide,
			y: yOnSide,
			w: Math.round(column.depth * SvgParams.SCALE),
			h: Math.round(column.getLength() * SvgParams.SCALE)
		};
	}

	public setRectForFrontOnSide(front: Profile, xOnSide: number, endColumnPositionDepthOnSide: number, sideFinishHeight: number, rectOnSide: string) {

		const heightOfColumnPosition = this.getXPositionHeight(endColumnPositionDepthOnSide);
		const yOnSide = SvgParams.START_Y + (this.template.backHeight - heightOfColumnPosition + sideFinishHeight) * SvgParams.SCALE;

		front[rectOnSide] = {
			x: xOnSide,
			y: yOnSide,
			w: Math.round(front.depth * SvgParams.SCALE),
			h: Math.round(front.height * SvgParams.SCALE)
		};
	}

	createLedStripes(configId: string) {
		const allLedStripes = this.profileService.roofElements[ElementType.LedStripe];

		let stripeLength = 0;
		const stripeId = uuidv4();
		this.profileService.roofElements[ElementType.Bar].forEach((bar: BarProfile) => {
			const lenRounded: number = Math.round((bar.getLength() + 500) / 1000) * 1000; // rounding to half of meter
			if (!allLedStripes.find(led => led.bar.id == bar.id) && stripeLength + lenRounded <= LED_STRIP_MAX_LENGTH) {
				const led = new LedStripe(configId, bar, lenRounded - bar.getLength(), stripeId);
				this.profileService.roofElements[ElementType.LedStripe].push(led);
				stripeLength += lenRounded;
			}
		});
		if (!this.calculateRemote()) {
			this.emitChange();
		}
	}

	public addLedAndConnectorToBar(bars) {
		this._bars = bars;
		this.arrangeLeds();
	}

	public arrangeLeds() {
		const sideFins = this.profileService.roofElements[ElementType.SideFinish].map(b => b.lineOnRoof);
		const bars = this.profileService.roofElements[ElementType.Bar].filter(b => b.orientation == Orientation.Vertical).map(b => b.rectOnRoof);
		this._bars = [...bars, ...sideFins];

		this.calculatePattern();
	}

	calculatePattern() {
		this.profileService.roofElements[ElementType.Led] = [];
		this._bars = this._bars.sort((n1, n2) => { if (n1.x ?? n1.x1 > n2.x ?? n2.x1) { return 1; } if (n1.x ?? n1.x1 < n2.x ?? n2.x1) { return -1; } return 0; });
		if (this.ledPattern && this.ledPattern.pattern && this._bars && this.ledPattern.pattern.length == this._bars.length) {

			const rear = this.profileService.roofElements[ElementType.WallProfile][0];
			const front = this.profileService.roofElements[ElementType.Front][0];
			const rearLocation = rear.rectOnRoof.y + rear.rectOnRoof.h;
			const frontLocation = front.rectOnRoof.y;
			const space = frontLocation - rearLocation;

			const barsCount = this.ledPattern.pattern.length;

			for (var barNo = 0; barNo < barsCount; barNo ++) {
				const barDef = this.ledPattern.pattern[barNo];
				let dots: number[] = [];
				barDef.filter(b => b > 0).forEach((b: number) => { 
					dots.push(b);
				});
				var ledsCount = dots.length;

				if (ledsCount == 0) {
					continue;
				}
				var distance = space / (ledsCount + 1);
				var start = rearLocation + distance;
				
				for(var j = 0; j< ledsCount; j++) {
					const x = this._bars[barNo].x;
					const w = this._bars[barNo].w;
					var led = new LED(dots[j]);
					led.circle = {
						x: x + w / 2,
						y: start,
						r: 5
					}
					this.profileService.roofElements[ElementType.Led].push(led);
					start += distance;
				}
			}


			this.recalculateDimensions();
			if (!this.calculateRemote()) {
				this.emitChange();
			}
		}
	}

	calculateLEdsLocation(x, y, w, h, circle: LED): LED {
		circle.circle = {
			x: x + w / 2,
			y: y + h,
			r: 5
		}
		return circle
	}
	// ! checkers

	private checkProfileLength(profile: ColumnProfile, diff: number) {
		if (diff < 0) { // move left
			if (profile.rightProfile.getLength() - (diff / SvgParams.SCALE) > profile.rightProfile.maxLength) {
				this.errorSubject.next("Right profile max length achieved");
				return false;
			}
			if (profile.leftProfile.getLength() + (diff / SvgParams.SCALE) < profile.leftProfile.minLength) {
				this.errorSubject.next("Left profile min length achieved");
				return false;
			}
		} else {
			if (profile.leftProfile.getLength() + (diff / SvgParams.SCALE) > profile.leftProfile.maxLength) {
				this.errorSubject.next("Left profile max length achieved");
				return false;
			}
			if (profile.rightProfile.getLength() - (diff / SvgParams.SCALE) < profile.rightProfile.minLength) {
				this.errorSubject.next("Right profile min length achieved");
				return false;
			}
		}

		return true;
	}
	public checkStatics(profile: ColumnProfile, value: number) {
		var currentAreaRect = this.getCurrentAreaRect();
		var currentElements = this.getCurrentAreaElements();

		const template = this.template;

		const v = value * SvgParams.SCALE;
		const r = profile[currentAreaRect];
		const diff = v - (r.x - SvgParams.START_X);
		let fr: FrontProfile | WallProfile;

		const elemType = profile.isRear ? ElementType.WallProfile : ElementType.Front;

		if (profile.connector) {
			if (diff < 0) { // move left - right front is growing and should be checking
				fr = profile.rightProfile;
			} else { // left front is growing
				fr = profile.leftProfile;
			}
			if (!this.checkProfileLength(profile, diff)) {
				return new Observable<ICheckResult>();
			}
		} else {
			fr = currentElements[elemType].find((f: FrontProfile) => f.columns.find(c => c.id == profile.id));
		}
		const frc = fr[currentAreaRect];

		let cols: ColumnProfile[] = currentElements[ElementType.Column].filter(c => c.isRear === profile.isRear).sort((o1: Profile, o2: Profile) => {
			if (o1[currentAreaRect].x < o2[currentAreaRect].x) {
				return -1;
			}
			return 1;
		});

		var colsPos: number[] = [];
		cols.forEach(element => {
			const r = element.rectOnRoof;
			const rx = r.x;
			const half = (r.w / 2) / SvgParams.SCALE;
			var v = element.id == profile.id ? Math.round(value) : Math.round((rx - SvgParams.START_X) / SvgParams.SCALE);
			if (v >= 0 && (rx >= frc.x || rx + r.w > frc.x) && rx <= frc.x + frc.w) {
				colsPos.push(v + half);
			}
		});

		if(profile.isRear){
			colsPos.sort((c1, c2) => c1 - c2);
		}

		const bars = this.profileService.roofElements[ElementType.Bar].filter(b => b.rectOnRoof.x >= frc.x && b.rectOnRoof.x + b.rectOnRoof.w <= frc.x + frc.w).length;
		const standalone = template.isStandalone;

		const input: ICheckInput = {
			barsCount: bars + 2,
			depth: template.depth,
			width: fr.getLength(),
			backHeight: template.backHeight,
			frontId: template.frontId,
			glassId: template.glassId,
			rearId: template.rearId,
			barId: template.barId,
			windZone: template.windZone,
			snowZone: template.snowZone,
			frontColumns: profile.isRear ? null : colsPos,
			rearColumns: profile.isRear ? colsPos : [],
			isStandalone: standalone,
			dropAngle: template.dropAngle,
			useStatics: this.useStatics
		};

		return this.api.checkConstruction(template.productId, template.brandName, input);
	}

	public checkConnectorStatics(profile: ColumnProfile, side: ElementSide) {
		const template = this.template;

		let fr: FrontProfile | WallProfile;
		let target: ColumnProfile;

		switch(side) {
			case ElementSide.Left:
				fr = profile.rightProfile; // right profile is growing
				target = profile.leftProfile.columns[profile.leftProfile.columns.findIndex(c => c.id == profile.id) - 1];
				break;
			case ElementSide.Right:
				fr = profile.leftProfile; // left profile is growing
				target = profile.rightProfile.columns[1];
				break;
			default:
				return false;
		}

		if (target.tag == Tags.LEFT_COLUMN_TAG || target.tag == Tags.RIGHT_COLUMN_TAG) {
			this.errorSubject.next("Cannot move to corner");
			return false;
		}

		var l = fr.getLength();
		l += (side == ElementSide.Left ? -1 : 1) * (target.rectOnRoof.x - profile.rectOnRoof.x) / SvgParams.SCALE;
		if (l > fr.maxLength) {
			this.errorSubject.next("Profile length can't be greater than " + fr.maxLength);
			return false;
		}
		if (l < fr.minLength) {
			this.errorSubject.next("Profile length can't be less than " + fr.minLength);
			return false;
		}

		return true;
	}

	// ! dimensions

	public recalculateDimensions() {
		this._markers = [];
		this._realDepth = 0;
		if (this.isShowDimensions) {
			this.clearCollections(ElementType.Dimension);
			this.showDimensionsSubj.next(true);
		}
	}

	//#region  gutter visibility

	private setGutterVisibility(front: FrontProfile, show: boolean) {
		this.profileService.roofElements[ElementType.Gutter].filter(f => f.frontId == front.id).forEach(g => { g.visible = show });
		this.profileService.frontElements[ElementType.Gutter].filter(f => f.frontId == front.id).forEach(g => { g.visible = show });
		this.profileService.leftSideElements[ElementType.Gutter].filter(f => f.frontId == front.id).forEach(g => { g.visible = show });
		this.profileService.rightSideElements[ElementType.Gutter].filter(f => f.frontId == front.id).forEach(g => { g.visible = show });
	}
	public hideGutter(front: FrontProfile) {
		this.setGutterVisibility(front, false);
	}

	public showGutter(front: FrontProfile) {
		this.setGutterVisibility(front, true);
	}

	//#endregion

	// ! deleting

	public deleteElement(element: any, emit: boolean = true) {
		if (!element) {
			return;
		}

		if (element instanceof VerticalElement) {
			this.profileService.deleteVertical(element);
		} else {
			switch (element.type) {
				case ElementType.Column:
					const el = element as ColumnProfile;
					if (el.isFront || (el.isRear && this.template.isStandalone)) {
						this.checkStatics(el, -1).subscribe(res => {
							if (res.ok) {
								if (element.isFront && res.deflections.frontDeflection && this.profileService.roofElements[ElementType.Column].filter(c => c.isFront).length == 3) { // will be 2 after removing
									this.template.deflections.frontDeflection = res.deflections.frontDeflection;
									this.template.deflections.snowZeroFrontDeflection = res.deflections.snowZeroFrontDeflection
								}
								this.deleteFromCollections(el);
							} else {
								this.errorSubject.next($localize`:Column|Validation message:Column can't be deleted`);
							}
						});
					} else {
						this.deleteFromCollections(el);
					}
					break;
				case ElementType.MarquiseTopUp:
				case ElementType.MarquiseTopBottom:
					this.deleteFromCollections(element.engine);
					this.deleteFromCollections(element);
					break;
				case ElementType.Led:
					this.profileService.roofElements[ElementType.Led] = [];
					break;
				case ElementType.ExtraOption:
					this.profileService.extraElements = this.profileService.extraElements.filter(e => e.id != element.id);
					break;
				case ElementType.WallGutter:
					var wg = element as WallGutterProfile;
					wg.rear.wallGutters = wg.rear.wallGutters.filter(g => g.id != wg.id);
					this.profileService.roofElements[ElementType.WallGutter] = this.profileService.roofElements[ElementType.WallGutter].filter(e => e.id != element.id);
					break;
				default:
					this.deleteFromCollections(element);
					break;
			}
		}
		var rem = this.calculateRemote();
		this.elementDeleted.next(element);
		if (emit && !rem) {
			this.emitChange();
		}
	}

	private deleteFromCollections(element: Profile | Glass | Marquise | ColumnProfile | Door) {
		this.deleteElementFromCollection(element, this.profileService.roofElements);
		this.deleteElementFromCollection(element, this.profileService.leftSideElements);
		this.deleteElementFromCollection(element, this.profileService.rightSideElements);
		this.deleteElementFromCollection(element, this.profileService.frontElements);
		this.deleteElementFromCollection(element, this.profileService.rearElements);
	}

	private deleteElementFromCollection(profile: Profile | Glass | GlassWall | Marquise | GlassPart | GlassPartDiamond | ColumnProfile | Door, collection: any[]) {
		let type = profile.type;
		const id = profile.id;

		if (collection && collection[type]) {
			collection[type] = collection[type].filter(f => f.id != id);
			let pg = profile as GlassWall;

			if (pg && (pg.type === ElementType.GlassWall || pg.type === ElementType.GlassWallDiamond)) {
				collection[ElementType.Frame] = collection[ElementType.Frame].filter(f => f.id != pg.topFrame?.id);
				collection[ElementType.Frame] = collection[ElementType.Frame].filter(f => f.id != pg.bottomFrame?.id);
				collection[ElementType.Frame] = collection[ElementType.Frame].filter(f => f.id != pg.rightFrame?.id);
				collection[ElementType.Frame] = collection[ElementType.Frame].filter(f => f.id != pg.leftFrame?.id);

				const gWallElemsLength = this.getCurrentAreaElements()[ElementType.GlassWall].length;
				const gWallDiamondElemsLength = this.getCurrentAreaElements()[ElementType.GlassWallDiamond].length;
				if (gWallElemsLength < 1 && gWallDiamondElemsLength < 1) this.getCurrentAreaElements()[ElementType.Frame] = [];
			}
		}
	}

	public clearAll() {
		this.profileService.elementsInit();
		this.readOnly = true;
	}

	public clearCollections(elementType: ElementType) {
		if (!this.profileService.roofElements) return;

		this.profileService.roofElements[elementType] = [];
		this.profileService.frontElements[elementType] = [];
		this.profileService.rearElements[elementType] = [];
		this.profileService.leftSideElements[elementType] = [];
		this.profileService.rightSideElements[elementType] = [];
	}

	public static getPath(points: IPoint[]) {
		if(!points || points.length == 0){
			return "";
		}
		var path = "M";
		points.forEach(p => {
			path += p.x + "," + p.y + " L";
		});
		path = path.substring(0, path.length - 2);
		path += "Z";

		return path;
	}

	public canAdjustMarquise(col: Profile) {
		var message = "";
		this.profileService.getMarquisesVertical(AreaType.Front).every((m: MarquiseVertical) => {

			if (m.leftProfile.id == col.id) {
				message = MarquiseVertical.validate(m.marquiseInfo, col, m.rightProfile, AreaType.Front, m.montage);
			}
			else if (m.rightProfile.id == col.id) {
				message = MarquiseVertical.validate(m.marquiseInfo, m.leftProfile, col, AreaType.Front, m.montage);
			}

			return message == "";

		});

		if (message != "") {
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return false;
		}
		return true;
	}

	private getMaxHorMarqDepth(vertMarq: MarquiseVertical, horMarq: MarquiseTop) {
		const start = horMarq.lineOnRoof.y2 - SvgParams.START_X - horMarq.correctionBack;
		return ((vertMarq.leftProfile.rectOnRoof.y - vertMarq.marquiseInfo.boxSize.depth * SvgParams.SCALE - SvgParams.START_Y) / SvgParams.SCALE) + start;
	}

	public adjustBottomMarquise(vertMarq: MarquiseVertical) {
		if (!vertMarq.isFront || vertMarq.montage != Montage.Inside) {
			return;
		}
		this.profileService.roofElements[ElementType.MarquiseTopBottom].forEach((horMarq: MarquiseTop) => {
			var cms = new ChosenMarquiseService(this);
			cms.setChosenMarquise([horMarq]);
			const maxDepth = this.getMaxHorMarqDepth(vertMarq, horMarq);
			const depth = horMarq.getErpDepth();
			const diff = depth - maxDepth;
			if (diff > 0) {
				cms.setDepth(Math.round(depth - diff));
			}
		});
	}

	public adjustMaxMarquiseDepth(horMarq: MarquiseTop, maxDepth: number) {
		const vertMarq: MarquiseVertical = this.profileService.getMarquisesVertical()[0] as MarquiseVertical;

		if(vertMarq) {		
			maxDepth = this.getMaxHorMarqDepth(vertMarq, horMarq);
		}

		return maxDepth;
	}

	public getBackImage() {
		if (this._currentColor != null) {
			return this._currentColor.imageBase64;
		}
		return null;
	}

	public getBackColor() {
		var c: string;
		if (this._currentColor != null) {
			c = this._currentColor.imageUrl;
		}
		if (c) {
			return `url(#colorBack)`;
		} else {
			return "#" + this._currentColor?.rgbColor;
		}
	}

	private getViewPort(sideService: IAreaService): IRect {
		const sp = sideService.getShapeAreaPoints();
		var x = Math.round(sp[SvgParams.LEFT_TOP_POINT_NUM].x);
		var y = Math.round(sp[SvgParams.LEFT_TOP_POINT_NUM].y);
		var w = Math.round(sp[SvgParams.RIGHT_BOTTOM_POINT_NUM].x - x);
		var h = Math.round(sp[SvgParams.RIGHT_BOTTOM_POINT_NUM].y - y);

		var r = { x, y, w, h };
		return r;
	}

	public calculateViewPort(area: AreaType, srv: IAreaService, channel: PrintChannel = PrintChannel.Browser): IRect {
		var p = this.getViewPort(srv);

		switch (area) {
			case AreaType.Left:
			case AreaType.Right:
				p.x -= 80; // for left dimension lines
				p.w += 160; // right margin
				p.y -= 50;
				p.h += 150;
				break;
			case AreaType.Front:
				if (this.profileService.roofElements[ElementType.Column].find(c => c.tag == Tags.RIGHT_COLUMN_TAG && c.outflow)) {
					p.w += 25;
				}
				if (this.profileService.roofElements[ElementType.Column].find(c => c.tag == Tags.LEFT_COLUMN_TAG && c.outflow)) {
					p.x -= 25;
					p.w += 25;
				}
				p.h += 175;
			case AreaType.Rear:
				p.x -= 70; // for left dimension lines
				p.w += 120; // right margin
				p.y -= 50; // for top dimension lines
				p.h += 250; // for foundations
				break;
			case AreaType.Roof:
				p.x -= 80; // for left dimension lines
				p.w += 120; // right margin
				p.y -= 100; // for top dimension lines
				p.h += 200; // for bottom dimension lines
				break;
		}

		if (channel != PrintChannel.CRM) {
			p.h += CUSTOMER_PLATE_HEIGHT;
		} else {
			var neww = Math.round(p.h * (297 / 210));
			if (neww > p.w) {
				var wdiff = (neww - p.w) / 2;
				p.w = neww;
				p.x -= wdiff;
			} else {
				var newh = Math.round(p.w / (297 / 210));
				var hdiff = (newh - p.h) / 2;
				p.y -= hdiff;
				p.h = newh;
			}	
		}
		
		return p;
	}

	public calculateDoorVariant(creator: DoorCreator, dtype: DoorType, startPosY: number = null) {

		if (creator.isWinkelOnLeft || creator.isWinkelOnRight) {
			message = $localize`:Door|Validation message:Cannot mount door to a winkel!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return null;
		}

		var message = creator.validateHeight(startPosY);
		if (message != "") {
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return null;
		}

		var sel1 = creator.calculateVariant(this.currentArea, dtype);
		let sel2: IDoorInfo;
		if (!sel1 && dtype == DoorType.SingleSide) {
			sel2 = creator.calculateVariant(this.currentArea, DoorType.DoubleSide);
		}

		if (!sel1 && !sel2) {
			message = $localize`:Door|Create error message:Cannot determine door variant!`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return null;
		}

		if (!sel1 && sel2) {
			message = $localize`:Door|Create warning message:Cannot apply single side door. Double side door was used.`;
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "warning" });
			sel1 = sel2;
		}

		var message = creator.validateWidth(sel1);
		if (message != "") {
			this.showTemporaryMessageSubj.next({ message, hideAfter: 2000, style: "error" });
			return null;
		}

		return sel1;
	}

	public createWall(sideService: IAreaService, crp: Space, currentTool: ToolboxItem, diamond: Shape = null) {
		if (this.profileService.checkVerticalExists(ElementType.Wall, this.currentArea, crp) != null) {
			return;
		}
		const def = this.template.walls.find(w => w.id == currentTool.id);
		var creator = new WallCreator(this, crp, this.currentArea, sideService);
		let d: Wall;
		if (diamond?.isDiamond) {
			d = creator.createDiamondWall(def, diamond);
		} else {
			d = creator.createWall(def, null, crp.startPosY);
		}
		this.profileService.addVertical(d, true);
		const neibTop = d.getNeib(ElementSide.Top);
		if (neibTop?.type == ElementType.Door) {
			(neibTop as Door).isExpandedBottom = false;
		}
		this.freezeProjProp();
		this.emitChange();
	}

	public getSpace(ptr: IPointer, self: VerticalElement = null, area: AreaType = AreaType.None): Space {		
		if (area == AreaType.None) {
			if (self != null) {
				area = self.area;
			} else {
				area = this.currentArea;
			}
		}
		const columns: ColumnProfile[] = this.profileService.getSortedColumns(CollectionName.get(area), area);

		const sn = RectName.get(area);
		let leftProfile: Profile;
		let rightProfile: Profile;

		for(var i = 0; i < columns.length; i++) {
			var c = columns[i];
			if (c[sn].x <= ptr.offsetX) {
				leftProfile = c;
			}
			if (c[sn].x >= ptr.offsetX) {
				rightProfile = c;
			}
			if (leftProfile && rightProfile) {
				break;
			}
		}

		// console.debug('getSpace left, right', leftProfile, rightProfile);

		var topProfile: FrameProfile;
		var bottomProfile: FrameProfile;

		var elements = this.getElementsForCheck(self, area);

		const lefts = elements[ElementType.Frame]
			.filter((c: FrameProfile) => c.orientation == Orientation.Vertical && (c.getRect(area).y + c.getRect(area).h > ptr.offsetY))
			.sort((c1: FrameProfile, c2: FrameProfile) => c1.getRect(area).x < c2.getRect(area).x ? 1 : -1);

		const left: FrameProfile = lefts.find((c: FrameProfile) => Math.round(c.getRect(area).x) < Math.round(ptr.offsetX));
		if (left && (!leftProfile || left[sn].x > leftProfile[sn].x + leftProfile[sn].w)) {
			leftProfile = left;
		}
		
		const rights = elements[ElementType.Frame]
			.filter((c: FrameProfile) => c.orientation == Orientation.Vertical && (c.getRect(area).y + c.getRect(area).h > ptr.offsetY))
			.sort((c1: FrameProfile, c2: FrameProfile) => c1.getRect(area).x > c2.getRect(area).x ? 1 : -1);

		const right: FrameProfile = rights.find((c: FrameProfile) => Math.round(c.getRect(area).x) > Math.round(ptr.offsetX));
		if (right && (!rightProfile || right[sn].x < rightProfile[sn].x)) {
			rightProfile = right;
		}
		
		const bottoms = elements[ElementType.Frame]
			.filter((c: FrameProfile) => c.orientation == Orientation.Horizontal)
			.sort((c1: FrameProfile, c2: FrameProfile) => c1.rect.y > c2.rect.y ? 1 : -1);

		const bottom = bottoms.find((c: FrameProfile) => Math.round(c.rect.y) >= Math.round(ptr.offsetY)
			&& (c.rect.x + c.rect.w >= ptr.offsetX && c.rect.x <= ptr.offsetX));
		
		// bottoms.forEach((c: FrameProfile) => {
		// 	console.debug('bottom ', c.id, Math.round(c.rect.y) >= Math.round(ptr.offsetY), (c.rect.x + c.rect.w >= ptr.offsetX), (c.rect.x <= ptr.offsetX))
		// });

		if (bottom) {
			bottomProfile = bottom;
		}

		const tops = elements[ElementType.Frame]
			.filter((c: FrameProfile) => c.orientation == Orientation.Horizontal)
			.sort((c1: FrameProfile, c2: FrameProfile) => c1.rect.y < c2.rect.y ? 1 : -1);

		const top = tops.find((c: FrameProfile) => Math.round(c.rect.y) <= Math.round(ptr.offsetY)
			&& (c.rect.x + c.rect.w >= ptr.offsetX && c.rect.x <= ptr.offsetX));
		if (top) {
			topProfile = top;
		}

		// console.debug('getSpace ptr', ptr.offsetX, ptr.offsetY);
		// console.debug('getSpace tops', tops, top);
		// console.debug('getSpace bottoms', bottoms, bottom);
		// console.debug('getSpace lefts', lefts, left);
		// console.debug('getSpace rights', rights, right);

		// if ((top || bottom) && (left || right)) {

		// 	if (top) {
		// 		if (left?.type == ElementType.Frame && left.rect.y + left.rect.h < top.rect.y) {
		// 			leftProfile = null;
		// 		}
		// 		if (right?.type == ElementType.Frame && right.rect.y + right.rect.h < top.rect.y) {
		// 			rightProfile = null;
		// 		}
		// 	}

		// 	if (bottom) {
		// 		if (left?.type == ElementType.Frame && left.rect.y > bottom.rect.y) {
		// 			leftProfile = null;
		// 		}
		// 		if (right?.type == ElementType.Frame && right.rect.y > bottom.rect.y) {
		// 			rightProfile = null;
		// 		}
		// 	}
		// }
		
		// console.debug('getSpace top', top);
		// console.debug('getSpace bottom', bottom);
		// console.debug('getSpace left', left);
		// console.debug('getSpace right', right);

		// console.debug('getSpace topProfile', topProfile);
		// console.debug('getSpace bottomProfile', bottomProfile);
		// console.debug('getSpace leftProfile', leftProfile);
		// console.debug('getSpace rightProfile', rightProfile);

		return new Space(leftProfile, rightProfile, topProfile, bottomProfile, this);
	}

	applySideLayout(area: AreaType, layout: ISideLayout, secondSide: boolean, currentTool: ToolboxItem, leftSideService: LeftSideService, rightSideService: RightSideService) {
		this._applySideLayout(area, layout, currentTool, leftSideService, rightSideService);
		if (secondSide) {
			const op = area == AreaType.Left ? AreaType.Right : AreaType.Left;
			this._applySideLayout(op, layout, currentTool, leftSideService, rightSideService);
		}
		this.emitChange();
	}
	
	private _applySideLayout(area: AreaType, layout: ISideLayout, currentTool: ToolboxItem, leftSideService: LeftSideService, rightSideService: RightSideService) {
		this.profileService.removeVerticals(area);
		var srv = area == AreaType.Left ? leftSideService : rightSideService;
		let door: Door = null;
		let gwd: GlassWallDiamond = null;
		let gw: GlassWall = null;

		if (layout.bottom != null) {
			switch (ElementType[layout.bottom]) {
				case ElementType.Wall:
					srv.createWallFromPopup(area, ElementSide.Bottom, currentTool);
					break;
			}
		}

		if (layout.middle != null) {
			switch (ElementType[layout.middle]) {
				case ElementType.Door:
					door = srv.createDoorFromPopup();
					break;
				case ElementType.Wall:
					srv.createWallFromPopup(area, ElementSide.None, currentTool);
					break;
				case ElementType.GlassWall:
					gw = srv.createGlassWallFromPopup(currentTool);
					break;
				case ElementType.GlassWallDiamond:
					srv.createGlassWallDiamondFromPopup(true, false, currentTool);
					break;	
			}
		}

		if (layout.top != null) {
			switch (ElementType[layout.top]) {
				case ElementType.GlassWallDiamond:
					gwd = srv.createGlassWallDiamondFromPopup(false, door != null || gw != null);
					break;
				case ElementType.Wall:
					srv.createWallFromPopup(area, ElementSide.Top, currentTool);
					break;
			}
		}

		if (door) {
			door.refreshSurround(this.profileService);
			if (gwd) {
				gwd.refreshSurround(this.profileService);
				new GlassWallDiamondService(this).setHeight(gwd, gwd.maxHeight);
			}
			DoorCreator.fromInstance(this, door, srv).setHeight(door, door.maxHeight, false);
		}


	}

	applyDoorHeight(current: Door, frontService: FrontService, leftService: LeftSideService, rightService: RightSideService, rearService: RearService) {
		var doors = this.profileService.getDoors().filter(d => d.id != current.id) as Door[];

		var changed = false;

		doors.forEach(d => {
			d.refreshSurround(this.profileService);

			if (d.height == current.height && d.locationY == current.locationY) {
				return; // nothing to do
			}
			
			var h = current.height;
			if (current.isExpandedBottom && !d.isExpandedBottom) {
				h -= DoorParams.EXPANSION_BOTTOM;
			}

			if (d.height < current.height) {
				if (d.minLocationY > current.locationY || d.maxLocationY < current.locationY) {
					return;
				}
			}
			if (d.maxHeight + (d.maxLocationY - d.minLocationY) < h) {
				return;
			}

			var srv = this.getAreaService(d.area, leftService, rightService, frontService, null, rearService);
			var creator = DoorCreator.fromInstance(this, d, srv);
			if (d.height < current.height) {
				creator.setLocationY(d, current.locationY, false);
				creator.setHeight(d, h, false);
			} else {
				creator.setHeight(d, h, false);
				creator.setLocationY(d, current.locationY, false);
			}
			d.setExpansion(current.isExpandedTop, current.isExpandedBottom);
			
			changed = true;

		});

		return changed;
	}

	public getAreaService(area: AreaType, leftService: IAreaService, rightService: IAreaService, frontService: IAreaService, roofService: IAreaService, rearService: IAreaService) {
		switch (area) {
			case AreaType.Left:
				return leftService;
			case AreaType.Right:
				return rightService;
			case AreaType.Front:
				return frontService;
			case AreaType.Rear:
				return rearService;
			case AreaType.Roof:
				return roofService;
			}
		return null;
	}

	calculateRemote() {
		var ch = 0;
		var elements = [];

		for (const type in ElementType) {
			const collections = ["roofElements", "frontElements", "rearElements", "leftSideElements", "rightSideElements"];
			collections.forEach(cn => {
				this.profileService[cn][ElementType[type]].forEach(e => {
					if (e.type == ElementType.LedStripe) {
						var element = elements.find((f : LedStripe) => f?.stripeId == e?.stripeId);
						if(element == null) {
							elements.push(e);
						}
					} else if (e?.id) {
						var element = elements.find(f => f?.id == e?.id);
						if(element == null) {
							elements.push(e);
						}
					}
				});
			});
		}
		elements = elements.concat(this.profileService.getMarquisesVertical());
		
		elements.forEach(c => {
			if (c["channels"] > 0) {
				ch += c["channels"];
			}
		});

		if (this.ledPattern != null){
			ch += this.ledPattern.channels;
		}

		this.channelsTotal = ch;

		if (this.userRemote == true && this.remote == null) {
			return;
		}

		if (ch == 0){
			var oldRemoteId = this.remote?.id;
			this.remote = null;
			this.userRemote = false;
			if (oldRemoteId != this.remote?.id) {
				this.emitChange();
				return true;
			} else {
				return false;
			}

		}

		if(this.remote == null || (this.userRemote && this.remote.channels < ch) || this.userRemote == false) {
			var oldRemoteId = this.remote?.id;
			this.remote = this.template.remotes.sort((r1, r2) => r1.channels - r2.channels).find(r => r.channels >= ch);
			if (oldRemoteId != this.remote?.id) {
				this.emitChange();
				return true;
			}
		}

		return false;
	}
}
