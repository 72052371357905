import { Injectable, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { MarquisesParams, SvgParams } from '../../constants/constants';
import { Glass } from '../../models/glasses/glass.model';
import { MarquiseTop } from '../../models/marquises/marquise-top.model';
import { ProjectService } from '../project.service';
import { ElementType } from '../../models/ToolboxModel';
import { BarProfile } from '../../models/profiles/bar.model';
import { ElementSide } from '../../interfaces/IElementSide';
import { IMarquiseInfo } from '../../interfaces/IMarquiseInfo.interface';
import { Common } from '../../models/common';
import { SideFinishProfile } from '../../models/profiles/side-finish.model';
import { FrontProfile } from '../../models/profiles/front.model';
import { WallProfile } from '../../models/profiles/wall.model';
import { IMarquiseHandler } from '../../interfaces/IMarquiseHandler.interface';

@Injectable({
	providedIn: 'root'
})
export class ChosenMarquiseService {	

	chosenMarquiseSubj: Subject<MarquiseTop[]> = new Subject<MarquiseTop[]>();

	private chosenMarquise: MarquiseTop = null;
	private currentMarquiseInfo: IMarquiseInfo;
	public leftHandlers: IMarquiseHandler[] = [];
	public rightHandlers: IMarquiseHandler[] = [];

	constructor(private projectService: ProjectService) { }

	getChosenMarquise(): MarquiseTop {
		return this.chosenMarquise;
	}

	setLeftHandler(handlerId: string) {
		this.chosenMarquise.leftHandler = this.leftHandlers.find(i => i.id == handlerId);
		this.repaint(this.chosenMarquise);

		this.rightHandlers = this.chosenMarquise.getAvalilableRightHandlers(this.projectService);

		if (this.chosenMarquise.leftHandler.double === true && this.chosenMarquise.leftHandler.widthCorrection == 0) {
			var leftNeib = this.chosenMarquise.getMarquiseOnSide(ElementSide.Left, this.projectService.profileService.roofElements);
			leftNeib.rightHandler = this.chosenMarquise.leftHandler;
			this.repaint(leftNeib);
		}

		this.projectService.emitChange();

	}

	setRightHandler(handlerId: string) {
		this.chosenMarquise.rightHandler = this.rightHandlers.find(i => i.id == handlerId);
		this.repaint(this.chosenMarquise);

		this.leftHandlers = this.chosenMarquise.getAvalilableLeftHandlers(this.projectService);

		if (this.chosenMarquise.rightHandler.double === true && this.chosenMarquise.rightHandler.widthCorrection == 0) {
			var rightNeib = this.chosenMarquise.getMarquiseOnSide(ElementSide.Right, this.projectService.profileService.roofElements);
			rightNeib.leftHandler = this.chosenMarquise.rightHandler;
			this.repaint(rightNeib);
		}

		this.projectService.emitChange();
	}

	setChosenMarquise(marquises: MarquiseTop[] | null): void {
		this.chosenMarquiseSubj.next(marquises)
		this.chosenMarquise = marquises ? marquises[0] : null;
		if (!this.chosenMarquise) {
			return;
		}

		this.leftHandlers = this.chosenMarquise.getAvalilableLeftHandlers(this.projectService);
		this.rightHandlers = this.chosenMarquise.getAvalilableRightHandlers(this.projectService);
	}

	getRawWidth() {
		return this.chosenMarquise.getRawWidth();
	}
	getWidth() {
		return this.chosenMarquise.getWidth();
	}
	setWidth(length: number) {
		// read only
	}
	setRailsForMarquise(){
		this.chosenMarquise.setRailsForMarquise();
	}

	getEngineId(): string {
		return this.chosenMarquise.engine.configId;
	}
	setEngineId(id: string) {
		const newEngine = this.currentMarquiseInfo.engines.find(e => e.id === id);
		this.chosenMarquise.engine.setNewEngineParameters(newEngine);
	}

	setDepth(length: number) {
		this.chosenMarquise.setErpDepth(length);
		this.reDraw(this.getYLocation());
	}

	getXLocation(): number {
		return (this.chosenMarquise.lineOnRoof.x2 - SvgParams.START_X) / SvgParams.SCALE;
	}
	setXLocation(location: number) {
		// read only
	}

	getYLocation(): number {
		return (this.chosenMarquise.lineOnRoof.y2 - SvgParams.START_Y) / SvgParams.SCALE;
	}
	setYLocation(location: number) {
		const diff = this.chosenMarquise.lineOnRoof.y2 - (SvgParams.START_Y + location * SvgParams.SCALE);

		this.chosenMarquise.lineOnLeft.x1 -= diff;
		this.chosenMarquise.lineOnLeft.x2 -= diff;
		this.chosenMarquise.lineOnLeft.x3 -= diff;
		this.chosenMarquise.lineOnLeft.x4 -= diff;

		this.chosenMarquise.lineOnRight.x1 += diff;
		this.chosenMarquise.lineOnRight.x2 += diff;
		this.chosenMarquise.lineOnRight.x3 += diff;
		this.chosenMarquise.lineOnRight.x4 += diff;

		this.reDraw(location);
		this.setEngineYLocation();
	}

	private reDraw(locationY: number) {
		this.chosenMarquise = ChosenMarquiseService.setCoordinates(this.chosenMarquise, locationY, this.projectService);
		this.setNewCoverAndExtension();
	}

	public static setCoordinates(marq: MarquiseTop, location: number, prjSrv: ProjectService) {
		const depth = marq.getErpDepth();

		const corrBack = marq.isExpandedBack ? 0 : marq.correctionBack;
		
		marq.lineOnRoof.y2 = SvgParams.START_Y + location * SvgParams.SCALE;
		if (prjSrv.template.isStandalone && (marq.type == ElementType.MarquiseTopBottom || marq.type == ElementType.MarquiseTopBottomMouseover)) {
			const rea = prjSrv.profileService.roofElements[ElementType.WallProfile][0] as WallProfile;
			const y2 = rea.rectOnRoof.y + rea.rectOnRoof.h;
			if (marq.lineOnRoof.y2 < y2) {
				marq.lineOnRoof.y2 = y2;
			}
		}

		marq.lineOnRoof.y3 = marq.lineOnRoof.y2;
		marq.lineOnRoof.y1 = marq.lineOnRoof.y2 + depth * SvgParams.SCALE;
		marq.lineOnRoof.y4 = marq.lineOnRoof.y1;

		// const w = (location + depth) * SvgParams.SCALE - corrBack;
		const w = depth * SvgParams.SCALE - corrBack;


		const sfl = prjSrv.profileService.leftSideElements[ElementType.SideFinish][0] as SideFinishProfile;
		var yl = sfl.rectOnLeft.y - MarquisesParams.TOP_HEIGHT;
		const sfr = prjSrv.profileService.rightSideElements[ElementType.SideFinish][0] as SideFinishProfile;
		var yr = sfr.rectOnRight.y - MarquisesParams.TOP_HEIGHT;
		const fro = prjSrv.profileService.frontElements[ElementType.Front][0] as FrontProfile;
		var yf = fro.rectOnFront.y - MarquisesParams.TOP_HEIGHT;

		if (marq.type == ElementType.MarquiseTopBottom) {
			const sd = sfl.height * SvgParams.SCALE + MarquisesParams.TOP_HEIGHT;
			yl += sd;
			yr += sd;
			yf += sd;
		}

		const r = (location * Math.tan(Common.toRadians(prjSrv.template.dropAngle))) * SvgParams.SCALE;

		marq.lineOnRight.x1 = SvgParams.START_X + (prjSrv.template.depth - depth) * SvgParams.SCALE + corrBack - location * SvgParams.SCALE;
		marq.lineOnRight.x2 = marq.lineOnRight.x1;

		marq.rectOnLeft = { x: marq.lineOnLeft.x1, y: yl + r, w: w, h: MarquisesParams.TOP_HEIGHT };
		marq.anchorLeft = { x: marq.rectOnLeft.x, y: yl + r + MarquisesParams.TOP_HEIGHT };
		marq.rotationLeft = sfl.rotation;

		marq.rectOnRight = { x: marq.lineOnRight.x1, y: yr + r, w: w, h: MarquisesParams.TOP_HEIGHT };
		marq.anchorRight = { x: marq.rectOnRight.x + w, y: yr + r + MarquisesParams.TOP_HEIGHT };
		marq.rotationRight = sfr.rotation;

		this.setRectOnFront(marq, prjSrv);
		return marq;
	}

	public static setRectOnFront(marq: MarquiseTop, prjSrv: ProjectService) {
		const fro = prjSrv.profileService.frontElements[ElementType.Front][0] as FrontProfile;
		var yf = 0;

		if (marq.type == ElementType.MarquiseTopBottom) {
			yf = fro.rectOnFront.y + fro.rectOnFront.h;
		} else {
			yf = fro.rectOnFront.y - MarquisesParams.TOP_HEIGHT;
		}

		marq.rectOnFront = { x: marq.lineOnRoof.x1, y: yf, w: marq.lineOnRoof.x3 - marq.lineOnRoof.x1, h: MarquisesParams.TOP_HEIGHT };
	}

	private setNewCoverAndExtension() {
		const depthLength = this.chosenMarquise.getErpDepth();
		this.chosenMarquise.setCover(depthLength);
		this.chosenMarquise.setExtension(0);

		const endYPointOfMarquise = this.chosenMarquise.lineOnRoof.y1;
		const endYPointOfProject = this.projectService.template.depth * SvgParams.SCALE + SvgParams.START_Y;
		if (endYPointOfMarquise > endYPointOfProject) {
			const newExtension = (endYPointOfMarquise - endYPointOfProject) / SvgParams.SCALE;
			this.chosenMarquise.setExtension(newExtension);
			const newCover = depthLength - this.chosenMarquise.getExtension();
			this.chosenMarquise.setCover(newCover);
		}
	}

	changePosition(side: ElementSide) {

		const sside = side.toString().toLowerCase();
		const newFirstGlass: Glass = this.chosenMarquise.firstGlass[sside + "Bar"][sside + "Glass"];
		const newLastGlass: Glass = this.chosenMarquise.lastGlass[sside + "Bar"][sside + "Glass"];

		this.chosenMarquise.leftBar = newFirstGlass.leftBar;
		this.chosenMarquise.rightBar = newLastGlass.rightBar;

		this.adjustHandlers();
		this.repaint(this.chosenMarquise);
	}

	private repaint(marq: MarquiseTop) {
		const leftX = ChosenMarquiseService.getXPointsOnRoof(marq.firstGlass, ElementSide.Left, marq.leftHandler.widthCorrection);
		const rightX = ChosenMarquiseService.getXPointsOnRoof(marq.lastGlass, ElementSide.Right, marq.rightHandler.widthCorrection);
		marq.lineOnRoof.x1 = leftX;
		marq.lineOnRoof.x2 = leftX;
		marq.lineOnRoof.x3 = rightX;
		marq.lineOnRoof.x4 = rightX;
		this.setEngineXLocation(marq);

		marq.lineOnFront.x1 = leftX;
		marq.lineOnFront.x2 = leftX;
		marq.lineOnFront.x3 = rightX;
		marq.lineOnFront.x4 = rightX;

		ChosenMarquiseService.setRectOnFront(marq, this.projectService);
		marq.calculateMarkers();
		marq.setRailsForMarquise();
	}

	changeWidth(side: ElementSide) {

		const sside = side.toString().toLocaleLowerCase();
		const newLastGlass: Glass = this.chosenMarquise.lastGlass[sside + "Bar"][sside + "Glass"];
		this.chosenMarquise.rightBar = newLastGlass.rightBar;

		this.adjustHandlers();
		this.repaint(this.chosenMarquise);
	}

	adjustHandlers() {
		this.leftHandlers = this.chosenMarquise.getAvalilableLeftHandlers(this.projectService);
		this.rightHandlers = this.chosenMarquise.getAvalilableRightHandlers(this.projectService);

		var leftNeib = this.chosenMarquise.getMarquiseOnSide(ElementSide.Left, this.projectService.profileService.roofElements);
		if (leftNeib != null) {
			if (this.chosenMarquise.leftHandler.double === false) {
				this.chosenMarquise.leftHandler = this.leftHandlers.find(h => h.double === true);
			}
		}

		var rightNeib = this.chosenMarquise.getMarquiseOnSide(ElementSide.Right, this.projectService.profileService.roofElements);
		if (rightNeib != null) {
			if (this.chosenMarquise.rightHandler.double === false) {
				this.chosenMarquise.rightHandler = this.rightHandlers.find(h => h.double === true);
			}
		}

	}

	relocateEngine(location: ElementSide) {
		if (location == this.chosenMarquise.engine.location) {
			return;
		}
		this.chosenMarquise.engine.location = location;
		this.setEngineXLocation(this.chosenMarquise);
	}

	setEngineXLocation(marq: MarquiseTop) {
		if (marq.engine.location == ElementSide.Left) {
			marq.engine.rect.x = marq.lineOnRoof.x2;
		} else {
			marq.engine.rect.x = marq.lineOnRoof.x3 - marq.engine.rect.w;
		}
	}

	setEngineYLocation() {
		this.chosenMarquise.engine.rect.y = this.chosenMarquise.lineOnRoof.y2;
	}

	public static getXPointsOnRoof(glass: Glass, side: ElementSide, correction: number): number {
		const hcorr = correction * SvgParams.SCALE;
		const bar: BarProfile = glass[side.toLowerCase() + 'Bar'];
		if (side == ElementSide.Left)
			return bar.middleX + hcorr;
		else
			return bar.middleX - hcorr;
	}

	public expandBack(status: boolean) {
		var y = status ? 0 : this.chosenMarquise.correctionBack / SvgParams.SCALE;
		this.chosenMarquise.isExpandedBack = status;
		this.setYLocation(y);
	}	

	public getBackExpansion() {
		return this.chosenMarquise.isExpandedBack ? 0 : this.chosenMarquise.correctionBack / SvgParams.SCALE;
	}

}
