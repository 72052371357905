import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CardType } from '../_core/interfaces/IPropertyCard';
import { ChosenGlassService } from '../_core/services/chosen-elements/chosen-glass.service';
import { Glass } from '../_core/models/glasses/glass.model';
import { Profile } from '../_core/models/profiles/profile.model';
import { ChosenProfileService } from '../_core/services/chosen-elements/chosen-profile.service';
import { ElementType } from '../_core/models/ToolboxModel';
import { ChosenMarquiseService } from '../_core/services/chosen-elements/chosen-marquise.service';
import { Marquise } from '../_core/models/marquises/marquise.model';
import { GlassWall } from '../_core/models/glasses/glassWall.model';
import { GlassPart } from '../_core/models/glasses/glassPart.model';
import { ChosenExtraOptionService } from '../_core/services/chosen-elements/chosen-extra-option.service';
import { ExtraOption } from '../_core/models/extra-option.model';
import { MarquiseVertical } from '../_core/models/marquises/marquise-vertical.model';
import { CardColumnComponent } from './card-profile/card-column.component';
import { WallMuntinProfile } from '../_core/models/walls/wall-muntin.model';
import { CrmService } from '../_core/services/crm.service';
import { GlassWallDiamond } from '../_core/models/glasses/glassWallDiamond.model';
import { ProjectService } from '../_core/services/project.service';
import { Common } from '../_core/models/common';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.css']
})
export class AppCardComponent implements OnInit {
	@ViewChild('columnCard', { static: false }) columnCard: CardColumnComponent;

	public type: CardType;
	public items: any[];
	public showComment: boolean = true;

	get showCommon(){
		if (this.showComment) {
			return true;
		}
		return Common.channelsCount(this.projectService, this.items[0]);
	}

	constructor(public chosenGlassService: ChosenGlassService,
		public chosenProfileService: ChosenProfileService,
		public chosenMarquiseService: ChosenMarquiseService,
		public chosenExtraOptionService: ChosenExtraOptionService,
		private crmService: CrmService,
		private projectService: ProjectService
	){}

	ngOnInit(): void {
		this.chosenGlassService.chosenGlassSubj.subscribe((glasses: Glass[] | GlassWall[] | GlassPart[] | GlassWallDiamond[] | null) => {
			if (glasses === null) {
				this.type = CardType.None;
			} else {
				this.type = CardType.Glass;
				const t = glasses[0].type;
				if (t == ElementType.GlassWallDiamond || t == ElementType.GlassWall) {
					this.type = CardType.GlassWall;
				}
			}

			this.showComment = this.crmService.isCrmPresent;
			this.items = glasses;
		})

		this.chosenProfileService.chosenProfileSubj.subscribe((profiles: Profile[] | null) => {
			if (profiles === null) {
				this.type = CardType.None;
			} else {
				this.showComment = this.crmService.isCrmPresent;
				const pt = profiles[0].type;
				switch(pt) {
					case ElementType.Column:
						this.type = CardType.Column;
						break;
					case ElementType.Led:
						this.type = CardType.Led;
						break;
					case ElementType.LedStripe:
						this.type = CardType.LedStripe;
						break;
					case ElementType.Front:
						this.type = CardType.Front;
						break;
					case ElementType.Door:
						this.type = CardType.Door;
						break;
					case ElementType.Wall:
						this.type = CardType.Wall;
						break;
					default:
						if (pt == ElementType.Muntin || profiles[0] instanceof WallMuntinProfile) {
						}
						this.type = CardType.Profile;
						break;
				}
			}

			this.items = profiles;
		})

		this.chosenMarquiseService.chosenMarquiseSubj.subscribe((marquises: Marquise[]) => {
			if (marquises === null) {
				this.type = CardType.None;
			} else {
				if (marquises[0] instanceof MarquiseVertical) {
					this.type = CardType.MarquiseVertical;
				} else {
					this.type = CardType.Marquise;
				}
			}
			this.showComment = this.crmService.isCrmPresent;
			this.items = marquises;
		})

		this.chosenExtraOptionService.choseOptionSubj.subscribe((option: ExtraOption | null) => {
			if (option === null) {
				this.type = CardType.None;
			} else {
				this.type = CardType.ExtraOption;
			}

			this.showComment = this.crmService.isCrmPresent;
			this.items = [option];
		})

		this.chosenProfileService.resetChosenProfileSubj.subscribe(() => {
			this.showComment = false;
			this.type = CardType.None;
			this.items = null;
		});
	}

	setColumnConfigId(configId: string) {
		// this.columnCard.setConfigId(configId);
	}
}
