import { ElementType } from "src/app/_core/models/ToolboxModel";
import { IMarquiseLine } from "../../interfaces/IMarquiseLine.interface";
import { SvgParams } from '../../constants/constants';
import { MarquisesEngine } from './marquises-engine.model';
import { Color, ColorOfPalette } from '../colors/color.model';
import { IMarquiseHandler } from "../../interfaces/IMarquiseHandler.interface";
import { IMarquise } from "../../interfaces/IMarquise";
import { IMarquiseInfo } from "../../interfaces/IMarquiseInfo.interface";
import { IRect } from "../../interfaces/IRect";
import { IPoint } from "../../interfaces/IPoint";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { IElement } from "../../interfaces/IElement";
import { ILine } from 'src/app/_core/interfaces/ILine';

export abstract class Marquise implements IMarquise, IElement {

	public id: string;
	get type(): ElementType {
		return ElementType[this.id.slice(0 , this.id.indexOf("_"))]
	};

	get name(): string {
		return "Marquise";
	};

	abstract get layer(): LayerLevel;


	private width: number;
	protected erpDepth: number;
	protected uiDepth: number;
	private cover: number;
	private extension: number;

	public leftHandler: IMarquiseHandler;
	public rightHandler: IMarquiseHandler;
	public paletteId: string;
	public fabric:  Color | ColorOfPalette;
	public marquiseInfo: IMarquiseInfo;
	public marquiseTopBottomCorrection: number;
	public drop: number;

	public engine = new MarquisesEngine(this);
	public rails : IRect[] = [];
	public textOnMarquize : ILine;

	private _lineOnRoof: IMarquiseLine;
	public get lineOnRoof(): IMarquiseLine {
		return this._lineOnRoof;
	};
	public set lineOnRoof(value: IMarquiseLine) {
		if (this.type === ElementType.MarquiseTopBottom || this.type === ElementType.MarquiseTopUp) {
			this.engine.rect = {
				x: value.x2,
				y: value.y2,
				w: this.engine.size.width * SvgParams.SCALE,
				h: this.engine.size.depth * SvgParams.SCALE,
			}
		}
		this._lineOnRoof = value;

		this.setRailsForMarquise();
	};

	public setRailsForMarquise(){
		this.rails = [];
		this.rails.push({
			x: this.lineOnRoof.x2,
			y: this.lineOnRoof.y2,
			w: 3,
			h: this.lineOnRoof.y1 - this.lineOnRoof.y2,
		});

		this.rails.push({
			x: this.lineOnRoof.x3 - 3,
			y: this.lineOnRoof.y3,
			w: 3,
			h: this.lineOnRoof.y1 - this.lineOnRoof.y2,
		});

		this.rails.push({
			x: this.lineOnRoof.x2,
			y: this.lineOnRoof.y2,
			w: this.lineOnRoof.x3 - this.lineOnRoof.x2,
			h: this.engine.size.depth * SvgParams.SCALE,
		});

		var w = this.getWidth() * SvgParams.SCALE;
		var t : string;

		if(this.type === ElementType.MarquiseTopBottom){
			t = "Unterdachbeschattung - ";
		}
		else {
			t = "Aufdachbeschattung - ";
		}

		this.textOnMarquize = {
			x: w / 2 + this.lineOnRoof.x2,
			y: this.engine.size.depth * SvgParams.SCALE / 2 + this.lineOnRoof.y2 + 5,
			text: this.marquiseInfo.name.replace(t, "")
		};
	}
	
	public lineOnFront: IMarquiseLine;
	public lineOnLeft: IMarquiseLine;
	public lineOnRight: IMarquiseLine;

	public rectOnLeft: IRect;
	public rectOnRight: IRect;
	public rectOnFront: IRect;
	public anchorLeft: IPoint;
	public anchorRight: IPoint;
	public rotationLeft: number;
	public rotationRight: number;

	private _configId: string;
	public get configId() {
		return this._configId;
	}

	constructor(configId: string) {
		this._configId = configId;
	}

	public getRawWidth(): number {
		return (this.lineOnRoof.x3 - this.lineOnRoof.x2) / SvgParams.SCALE;
	}

	public getWidth(): number {
		this.width = Math.round(this.getRawWidth());
		return this.width;
	}

	public getErpDepth(): number {
		return this.erpDepth
	}
	public setErpDepth(length: number): void {
		this.erpDepth = length;
		this.uiDepth = this.calculateUIDepth();
	}

	public getUIDepth(): number {
		return this.uiDepth;
	}
	public setUIDepth(length: number): void {
		this.uiDepth = length;		
		this.erpDepth = this.calculateERPDepth()
		if (this.erpDepth > this.marquiseInfo.maxDepth) {
			this.erpDepth = this.marquiseInfo.maxDepth;
			this.uiDepth = this.calculateUIDepth();
		}
	}

	public getCover(): number {
		return this.cover;
	}
	public setCover(length: number): void {
		this.cover = length;
	}

	public getExtension(): number {
		return this.extension;
	}
	public setExtension(length: number): void {
		this.extension = length;
	}

	public abstract get correctionBack(): number;
	public calculateMarkers() {}
	protected abstract calculateUIDepth(): number;
	protected abstract calculateERPDepth(): number;
}
