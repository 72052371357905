import {Glass} from '../glasses/glass.model';
import {ElementType, ToolboxItemType} from '../ToolboxModel';
import {IPoint} from '../../interfaces/IPoint';
import {SvgParams, MarquisesParams} from '../../constants/constants';
import {MarquiseTop} from './marquise-top.model';
import {IMarquiseInfo} from '../../interfaces/IMarquiseInfo.interface';
import {Marquise} from './marquise.model';
import {ProjectService} from '../../services/project.service';
import {ProjectTemplate} from '../../template';
import {ProfileService} from '../../services/profile.service';
import { ChosenMarquiseService } from '../../services/chosen-elements/chosen-marquise.service';
import { ElementSide } from '../../interfaces/IElementSide';
import { Common } from '../common';
import { IMarquiseHandler } from '../../interfaces/IMarquiseHandler.interface';
import { BarProfile } from '../profiles/bar.model';

export class MarquiseCreator {

	private get template(): ProjectTemplate {
		return this.projectService.template;
	}

	private get profileService(): ProfileService {
		return this.projectService.profileService;
	}

	private get width(): number {
		return this.projectService.template.width;
	}

	private get depth(): number {
		return this.projectService.template.depth;
	}

	private get backHeight(): number {
		return this.projectService.template.backHeight;
	}

	constructor(private projectService: ProjectService) { }

	private checkMarqOverGlass(marquiseType: ElementType, glass: Glass) {
		var marquises: MarquiseTop[] = this.profileService.roofElements[marquiseType];
		var over = false;
		marquises.every(m => {
			if (m.lineOnRoof.x3 >= glass.rect.x + glass.rect.w && m.lineOnRoof.x1 <= glass.rect.x) {
				over = true;
				return false;
			} else {
			}
			return true;
		});

		return over;
	}

	private getFreeGlasses(marquiseType: ElementType, configId: string, ptr: MouseEvent) {
		const info = this.template.marquises.find(m => m.id == configId);
		var width = 0;
		var gls: Glass[] = [];

		var glass: Glass = this.profileService.roofElements[ElementType.Glass].find((g: Glass) => g.isFirst);
		while (glass.rect.x + glass.rect.w < ptr.offsetX) {
			glass = glass.rightBar?.rightGlass;
		}
		while (glass && this.checkMarqOverGlass(marquiseType, glass)) {
			glass = glass.rightBar?.rightGlass;
		}
		while (glass && !this.checkMarqOverGlass(marquiseType, glass) && width < info.maxWidth) {
			if (width + glass.width <= info.maxWidth) {
				gls.push(glass);
			}
			width += glass.width;
			glass = glass.rightBar?.rightGlass;
		}

		glass = gls[0];
		while (glass && glass.leftBar?.leftGlass) {
			glass = glass.leftBar?.leftGlass;
			if (this.checkMarqOverGlass(marquiseType, glass)) {
				break;
			}
			if (width + glass.width <= info.maxWidth) {
				width += glass.width;
				gls.splice(0, 0, glass);
			} else {
				break;
			}
		}

		return gls;
	}
	
	public createMarquiseTop(selectedGlasses: Glass[], configId: string,
		marquiseType: ElementType, roofAreaPoints: IPoint[], frontAreaPoints: IPoint[],
		leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
		click: boolean, hover: boolean, ptr: MouseEvent): void {

		if (selectedGlasses.length == 0) {
			if (click) {
				this.projectService.clearCollections(ElementType.MarquiseTopBottomMouseover);
				this.projectService.clearCollections(ElementType.MarquiseTopUpMouseover);
			}
			
			var glasses: Glass[] = [];
			var firstGlass: Glass;

			if (this.profileService.roofElements[marquiseType].length == 0){
				firstGlass = this.profileService.roofElements[ElementType.Glass].find((g: Glass) => g.isFirst);
			} else {
				var prevLast = this.profileService.roofElements[marquiseType].sort((m1, m2) => m1.rectOnRoof.x1 - m2.rectOnRoof.x1)[0];
				firstGlass = prevLast.lastGlass.rightBar.rightGlass;
			}

			var lastGlass: Glass = this.profileService.roofElements[ElementType.Glass].find((g: Glass) => g.isLast);
			const info = this.template.marquises.find(m => m.id == configId);
			var points = MarquiseCreator.calculatePoints(this.projectService, marquiseType, info, firstGlass, lastGlass, false);
			var simWidth = points.width;
			var secondMarq: boolean = true;

			var marqs: MarquiseTop[] = [];

			while (secondMarq) {
				if (simWidth <= info.maxWidth && lastGlass.isLast) {
					secondMarq = false;
				}
				if (simWidth > info.maxWidth) {
					secondMarq = true;
					while (simWidth > info.maxWidth) {
						lastGlass = lastGlass.leftBar?.leftGlass;
						points = MarquiseCreator.calculatePoints(this.projectService, marquiseType, info, firstGlass, lastGlass, secondMarq);
						simWidth = points.width;
					}
				}
				var newmarq = this.createMarquise(firstGlass, lastGlass, configId, marquiseType, roofAreaPoints, frontAreaPoints, leftAreaPoints, rightAreaPoints, click, hover, secondMarq, points.leftHandler, points.rightHandler);
				const leftX = ChosenMarquiseService.getXPointsOnRoof(newmarq.firstGlass, ElementSide.Left, newmarq.leftHandler.widthCorrection);
				const rightX = ChosenMarquiseService.getXPointsOnRoof(newmarq.lastGlass, ElementSide.Right, newmarq.rightHandler.widthCorrection);
				newmarq.lineOnRoof.x1 = leftX;
				newmarq.lineOnRoof.x2 = leftX;
				newmarq.lineOnRoof.x3 = rightX;
				newmarq.lineOnRoof.x4 = rightX;

				if (newmarq.lineOnFront) {
					newmarq.lineOnFront.x1 = leftX;
					newmarq.lineOnFront.x2 = leftX;
					newmarq.lineOnFront.x3 = rightX;
					newmarq.lineOnFront.x4 = rightX;
				}
				marqs.push(newmarq);
				
				if (secondMarq) {
					firstGlass = lastGlass.rightBar.rightGlass;
					lastGlass = this.profileService.roofElements[ElementType.Glass].find((g: Glass) => g.isLast);
					simWidth = MarquiseCreator.calculatePoints(this.projectService, marquiseType, info, firstGlass, lastGlass, secondMarq).width;
				}
			}
		} else {
			var glasses: Glass[] = selectedGlasses;
			this.createMarquiseTopOnSelectedGlasses(glasses, configId, marquiseType, roofAreaPoints, frontAreaPoints, leftAreaPoints, rightAreaPoints, click, hover, true);
		}
		
		if (click) {
			marqs.forEach(m => {
				const leftHandlers = m.getAvalilableLeftHandlers(this.projectService);
				const rightHandlers = m.getAvalilableRightHandlers(this.projectService);
		
				var leftNeib = m.getMarquiseOnSide(ElementSide.Left, this.projectService.profileService.roofElements);
				if (leftNeib != null) {
					if (m.leftHandler.double === false) {
						m.leftHandler = leftHandlers.find(h => h.double === true);
					}
				} else {
					if (m.leftHandler.double === true) {
						m.leftHandler = leftHandlers.find(h => h.double === false);
					}
				}
		
				var rightNeib = m.getMarquiseOnSide(ElementSide.Right, this.projectService.profileService.roofElements);
				if (rightNeib != null) {
					if (m.rightHandler.double === false) {
						m.rightHandler = rightHandlers.find(h => h.double === true);
					}
				} else {
					if (m.rightHandler.double === true) {
						m.rightHandler = rightHandlers.find(h => h.double === false);
					}
				}

				m.setRailsForMarquise();
				ChosenMarquiseService.setRectOnFront(m, this.projectService);

		
			});

			this.projectService.calculateRemote();
			this.projectService.emitChange();
		}
	}

	private createMarquiseTopOnSelectedGlasses(selectedGlasses: Glass[], configId: string, marquiseType: ElementType,
		roofAreaPoints: IPoint[], frontAreaPoints: IPoint[], leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
		click: boolean, hover: boolean, double: boolean): MarquiseTop {

		const firstSelectedGlass = selectedGlasses.reduce((prev, curr) => prev.rect.x < curr.rect.x ? prev : curr);
		const lastSelectedGlass = selectedGlasses.reduce((prev, curr) => prev.rect.x > curr.rect.x ? prev : curr);
		const info = this.template.marquises.find(m => m.id == configId);

		const leftHandler = info.handlers.find(h => h.double === true);
		const rightHandler = leftHandler;

		return this.createMarquise(firstSelectedGlass, lastSelectedGlass, configId, marquiseType, roofAreaPoints, frontAreaPoints, leftAreaPoints, rightAreaPoints, click, hover, double, leftHandler, rightHandler);
	}

	private createMarquise(firstGlass: Glass, lastGlass: Glass, configId: string, marquiseType: ElementType,
		roofAreaPoints: IPoint[], frontAreaPoints: IPoint[], leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
		click: boolean, hover: boolean, double: boolean, leftHandler: IMarquiseHandler, rightHandler: IMarquiseHandler) {
		
		const info = this.template.marquises.find(m => m.id == configId);
		var p = MarquiseCreator.calculatePoints(this.projectService, marquiseType, info, firstGlass, lastGlass, double);

		const error = this.showErrorMessageForMarquises(p.roofLeftPointX, p.roofRightPointX, info, marquiseType);
		if (error) {
			return null;
		}

		const rear = this.projectService.template.getDefaultRear();
		const front = this.profileService.roofElements[ElementType.Front][0];
		const dc = this.calculateDepth(info, marquiseType, p);

		const newMarquiseTop: MarquiseTop = new MarquiseTop(rear, dc.marquiseDepth, info, this.projectService.template.isStandalone, this.template.dropAngle, marquiseType, firstGlass.leftBar, lastGlass.rightBar, leftHandler, rightHandler);

		if (marquiseType === ElementType.MarquiseTopUp) {
			this.linesOfNewMarquiseTopUpOnAllAreas(newMarquiseTop, roofAreaPoints, frontAreaPoints, leftAreaPoints, rightAreaPoints, p.roofLeftPointX, p.roofRightPointX,
				dc.topY, newMarquiseTop.getUIDepth(), click, hover);
		} else if (marquiseType === ElementType.MarquiseTopBottom) {
			this.linesOfNewMarquiseTopBottomOnAllAreas(newMarquiseTop, frontAreaPoints, leftAreaPoints, rightAreaPoints, p.roofLeftPointX, p.roofRightPointX,
				dc.topY, p.roofBottomPointY, newMarquiseTop.getUIDepth(), click, hover);
		}
		if (click) {
			newMarquiseTop.calculateMarkers();

			if (marquiseType === ElementType.MarquiseTopBottom) {
				const currDepth = newMarquiseTop.getErpDepth();
				var md = (front.rectOnRoof.y - newMarquiseTop.lineOnRoof.y2) / SvgParams.SCALE;
				const maxDepth = this.projectService.adjustMaxMarquiseDepth(newMarquiseTop, md);				
				const cms = new ChosenMarquiseService(this.projectService);
				cms.setChosenMarquise([newMarquiseTop]);
				if (currDepth != maxDepth) {
					cms.setDepth(maxDepth);
				} else {
					cms.setDepth(currDepth);
				}
			}
		}

		return newMarquiseTop;
	}

	public static calculatePoints(projectService: ProjectService, type: ElementType, info: IMarquiseInfo, firstGlass: Glass, lastGlass: Glass, double: boolean,
			leftHandler: IMarquiseHandler = null, rightHandler: IMarquiseHandler = null) {
		
		var roofWindows = projectService.profileService.roofElements[ElementType.RoofWindow];
		if (leftHandler == null) {
			leftHandler = MarquiseCreator.calculateHandler(double, info, firstGlass.leftBar, lastGlass.rightBar, roofWindows.length > 0);
			rightHandler = leftHandler;
		}

		let roofLeftPointX: number = ChosenMarquiseService.getXPointsOnRoof(firstGlass, ElementSide.Left, leftHandler.widthCorrection);
		let roofRightPointX: number = ChosenMarquiseService.getXPointsOnRoof(lastGlass, ElementSide.Right, rightHandler.widthCorrection);

		const rear = projectService.profileService.roofElements[ElementType.WallProfile][0];
		let roofTopPointY: number = projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_TOP_POINT_NUM].y;
		if (projectService.template.isStandalone && (type == ElementType.MarquiseTopBottom || type == ElementType.MarquiseTopBottomMouseover)) {
			roofTopPointY = rear.rectOnRoof.y + rear.rectOnRoof.h;
		}
		let roofBottomPointY: number = projectService.profileService.roofElements[ElementType.Front][0].rectOnRoof.y;

		var width = this.calculateWidth(firstGlass.leftBar, lastGlass.rightBar, leftHandler, rightHandler);

		return { roofLeftPointX, roofTopPointY, roofRightPointX, roofBottomPointY, width, leftHandler, rightHandler}; 
	}

	private static calculateWidth(left: BarProfile, right: BarProfile, leftHandler: IMarquiseHandler, rightHandler: IMarquiseHandler) {
		const xl = left.middleX;
		const xr = right.middleX;

		const w = (xr - xl) / SvgParams.SCALE;

		var tw = w - leftHandler.widthCorrection - rightHandler.widthCorrection;

		if (left.type == ElementType.SideFinish) {
			tw -= 30;
		}

		if (right.type == ElementType.SideFinish) {
			tw -= 30;
		}

		return tw;
	}

	public static calculateHandler(double: boolean, info: IMarquiseInfo, left: BarProfile, right: BarProfile, roofWindows: boolean) {
		if (info.type == ToolboxItemType.MarquiseTopBottom) {
			roofWindows = false;
		}
		var handlers: IMarquiseHandler[] = info.handlers.filter(h => (h.double == double && h.roofWindow == roofWindows));
		var hnd: IMarquiseHandler = null;
		handlers.every(h => {
			var tw = this.calculateWidth(left, right, h, h);
			if (tw <= info.maxWidth) {
				hnd = h;
				return false;
			}
		})

		return hnd ?? info.handlers.find(h => h.widthCorrection == 0);
	}

	private calculateDepth(info: IMarquiseInfo, type: ElementType, p: { roofLeftPointX, roofTopPointY, roofRightPointX, roofBottomPointY, width }) {
		let marquiseDepth: number;
		
		if (type === ElementType.MarquiseTopUp) {
			marquiseDepth = this.depth;
		} else {
			marquiseDepth = (p.roofBottomPointY - p.roofTopPointY) / SvgParams.SCALE;
		}

		var topY = p.roofTopPointY;
		if (marquiseDepth > info.maxDepth) {
			marquiseDepth = info.maxDepth;
			topY += (marquiseDepth - info.maxDepth) * SvgParams.SCALE;
		}

		// marquiseDepth = Common.calculateDepth(marquiseDepth, this.projectSrvc.template.dropAngle);

		return { marquiseDepth, topY };
	}

	private showErrorMessageForMarquises(roofLeftPointX: number, roofRightPointX: number, currentMarquiseInfo: IMarquiseInfo, marquiseType: ElementType) {
		if (this.checkOtherMarquisesTopUpNearNewOne(roofLeftPointX, roofRightPointX, 'between', marquiseType)) {
			const message = $localize`:Marquise|Validation message:Cannot place new marquise outside existed one!`;
			this.projectService.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
			return true;
		}
		if (this.checkOtherMarquisesTopUpNearNewOne(roofLeftPointX, roofRightPointX, 'outside', marquiseType)) {
			const message = $localize`:Marquise|Validation message:You can't place new marquise between existed one!`;
			this.projectService.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
			return true;
		}
		const newMarquiseTopUpWidth = Math.round((roofRightPointX - roofLeftPointX) / SvgParams.SCALE);
		if (newMarquiseTopUpWidth < currentMarquiseInfo.minWidth) {
			if (this.profileService.roofElements[ElementType.MarquiseTopBottomMouseover].length == 0 &&
				this.profileService.roofElements[ElementType.MarquiseTopUpMouseover].length == 0) {
					const message = $localize`:Marquise|Validation tooltip:The new width of the marquise is less than it's minimum allowable width!`;
					this.projectService.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
				}
			return true;
		}
		if (newMarquiseTopUpWidth > currentMarquiseInfo.maxWidth) {
			const message = $localize`:Marquise|Validation tooltip:The new width of the marquise is greater then it's maximum allowable width!`;
			this.projectService.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
			return true;
		}

		return false;
	}

	private checkOtherMarquisesTopUpNearNewOne(roofLeftPointX: number, roofRightPointX: number, place: string, marquiseType: ElementType): Marquise {
		return this.profileService.roofElements[marquiseType].find((marquise: Marquise) => {
			if (place === 'between') {
				return marquise.lineOnRoof.x1 >= roofLeftPointX && marquise.lineOnRoof.x3 <= roofRightPointX;
			} else if (place === 'outside') {
				return marquise.lineOnRoof.x1 <= roofLeftPointX && marquise.lineOnRoof.x3 >= roofRightPointX;
			} else if (place === 'over') {
				return marquise.lineOnRoof.x1 <= roofLeftPointX && marquise.lineOnRoof.x3 >= roofLeftPointX ||
					marquise.lineOnRoof.x1 <= roofRightPointX && marquise.lineOnRoof.x3 >= roofRightPointX;
			}
		});
	}

	private linesOfNewMarquiseTopUpOnAllAreas(newMarquiseTop: MarquiseTop, roofAreaPoints: IPoint[],
		frontAreaPoints: IPoint[], leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
		roofLeftPointX: number, roofRightPointX: number, roofTopPointY: number, depth: number,
		click: boolean, hover: boolean): void {

		const corrLeft = newMarquiseTop.leftBar.type == ElementType.SideFinish ? 0 : newMarquiseTop.leftHandler.widthCorrection * SvgParams.SCALE;
		const corrRight = newMarquiseTop.rightBar.type == ElementType.SideFinish ? 0 : newMarquiseTop.rightHandler.widthCorrection * SvgParams.SCALE;
		const dpt = depth * SvgParams.SCALE;
		newMarquiseTop.lineOnRoof = {
			x1: roofLeftPointX + corrLeft,
			y1: roofTopPointY + dpt,
			x2: roofLeftPointX + corrLeft,
			y2: roofTopPointY,
			x3: roofRightPointX - corrRight,
			y3: roofTopPointY,
			x4: roofRightPointX - corrRight,
			y4: roofTopPointY + dpt
		}
		if (click) {
			this.profileService.roofElements[ElementType.MarquiseTopUp].push(newMarquiseTop);
		} else if (hover) {
			this.profileService.roofElements[ElementType.MarquiseTopUpMouseover].push(newMarquiseTop);
		}

		if (click) {
			newMarquiseTop.lineOnFront = {
				x1: roofLeftPointX + corrLeft,
				y1: frontAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y,
				x2: roofLeftPointX + corrLeft,
				y2: frontAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y - MarquisesParams.TOP_HEIGHT,
				x3: roofRightPointX - corrRight,
				y3: frontAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y - MarquisesParams.TOP_HEIGHT,
				x4: roofRightPointX - corrRight,
				y4: frontAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y,
			}
			this.profileService.frontElements[ElementType.MarquiseTopUp].push(newMarquiseTop);
		}

		const ph = this.projectService.getXPositionHeight((roofTopPointY - SvgParams.START_X) / SvgParams.SCALE);
		if (click) {
			newMarquiseTop.lineOnLeft = {
				x1: roofTopPointY,
				y1: SvgParams.START_Y + (this.backHeight - ph) * SvgParams.SCALE,
				x2: roofTopPointY,
				y2: SvgParams.START_Y + (this.backHeight - ph) * SvgParams.SCALE - MarquisesParams.TOP_HEIGHT,
				x3: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].x,
				y3: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y - MarquisesParams.TOP_HEIGHT,
				x4: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].x,
				y4: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y,
			}
			this.profileService.leftSideElements[ElementType.MarquiseTopUp].push(newMarquiseTop);
		}

		if (click) {
			const xOnSide = SvgParams.START_X + (this.depth * SvgParams.SCALE) - roofTopPointY;
			newMarquiseTop.lineOnRight = {
				x1: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].x,
				y1: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y,
				x2: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].x,
				y2: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y - MarquisesParams.TOP_HEIGHT,
				x3: xOnSide + SvgParams.START_X,
				y3: SvgParams.START_Y + (this.backHeight - ph) * SvgParams.SCALE - MarquisesParams.TOP_HEIGHT,
				x4: xOnSide + SvgParams.START_X,
				y4: SvgParams.START_Y + (this.backHeight - ph) * SvgParams.SCALE,
			}			
			newMarquiseTop = ChosenMarquiseService.setCoordinates(newMarquiseTop, 0, this.projectService);
			this.profileService.rightSideElements[ElementType.MarquiseTopUp].push(newMarquiseTop);
		}
	}

	private linesOfNewMarquiseTopBottomOnAllAreas(newMarquiseTopBottom: MarquiseTop,
			frontAreaPoints: IPoint[], leftAreaPoints: IPoint[], rightAreaPoints: IPoint[],
			roofLeftPointX: number, roofRightPointX: number, roofTopPointY: number, roofBottomPointY: number, depth: number,
			click: boolean, hover: boolean): void {

		const corrLeft = newMarquiseTopBottom.leftHandler.widthCorrection * SvgParams.SCALE;
		const corrRight = newMarquiseTopBottom.rightHandler.widthCorrection * SvgParams.SCALE;
		const corrBack = this.projectService.template.isStandalone ? 0 : newMarquiseTopBottom.correctionBack;
		const dpt = depth * SvgParams.SCALE;

		newMarquiseTopBottom.lineOnRoof = {
			x1: roofLeftPointX + corrLeft,
			y1: roofTopPointY + corrBack + dpt,
			x2: roofLeftPointX + corrLeft,
			y2: roofTopPointY + corrBack,
			x3: roofRightPointX - corrRight,
			y3: roofTopPointY + corrBack,
			x4: roofRightPointX - corrRight,
			y4: roofTopPointY + corrBack + dpt,
		}
		if (click) {
			this.profileService.roofElements[ElementType.MarquiseTopBottom].push(newMarquiseTopBottom);
		} else if (hover) {
			this.profileService.roofElements[ElementType.MarquiseTopBottomMouseover].push(newMarquiseTopBottom);
		}

		if (click) {
			newMarquiseTopBottom.lineOnFront = {
				x1: roofLeftPointX + corrLeft,
				y1: frontAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y,
				x2: roofLeftPointX + corrLeft,
				y2: frontAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y + MarquisesParams.TOP_HEIGHT,
				x3: roofRightPointX - corrRight,
				y3: frontAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y + MarquisesParams.TOP_HEIGHT,
				x4: roofRightPointX - corrRight,
				y4: frontAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y,
			}
			this.profileService.frontElements[ElementType.MarquiseTopBottom].push(newMarquiseTopBottom);
		}

		const positionHeight = this.projectService.getXPositionHeight((roofTopPointY - SvgParams.START_X) / SvgParams.SCALE)
		const sideFinScaleH = this.template.getBarSize().height * SvgParams.SCALE;

		if (click) {
			newMarquiseTopBottom.lineOnLeft = {
				x1: roofTopPointY + corrBack,
				y1: SvgParams.START_Y + (this.backHeight - positionHeight) * SvgParams.SCALE + sideFinScaleH,
				x2: roofTopPointY + corrBack,
				y2: SvgParams.START_Y + (this.backHeight - positionHeight) * SvgParams.SCALE + MarquisesParams.TOP_HEIGHT + sideFinScaleH,
				x3: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].x - corrBack,
				y3: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y + MarquisesParams.TOP_HEIGHT + sideFinScaleH,
				x4: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].x - corrBack,
				y4: leftAreaPoints[SvgParams.RIGHT_TOP_POINT_NUM].y + sideFinScaleH,
			}
			this.profileService.leftSideElements[ElementType.MarquiseTopBottom].push(newMarquiseTopBottom);
		}

		if (click) {
			const xOnSide = SvgParams.START_X + (this.depth * SvgParams.SCALE) - roofTopPointY - corrBack;
			newMarquiseTopBottom.lineOnRight = {
				x1: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].x,
				y1: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y + sideFinScaleH,
				x2: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].x,
				y2: rightAreaPoints[SvgParams.LEFT_TOP_POINT_NUM].y + MarquisesParams.TOP_HEIGHT + sideFinScaleH,
				x3: xOnSide + SvgParams.START_X,
				y3: SvgParams.START_Y + (this.backHeight - positionHeight) * SvgParams.SCALE + MarquisesParams.TOP_HEIGHT + sideFinScaleH,
				x4: xOnSide + SvgParams.START_X,
				y4: SvgParams.START_Y + (this.backHeight - positionHeight) * SvgParams.SCALE + sideFinScaleH,
			}

			newMarquiseTopBottom = ChosenMarquiseService.setCoordinates(newMarquiseTopBottom, Math.round(corrBack / SvgParams.SCALE), this.projectService);
			this.profileService.rightSideElements[ElementType.MarquiseTopBottom].push(newMarquiseTopBottom);
	
		}
	}

}
