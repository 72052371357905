import {Glass} from './glasses/glass.model';
import {ElementType, Orientation} from 'src/app/_core/models/ToolboxModel';
import {IColumnSpec} from '../interfaces/specs/IColumnSpec';
import { ColumnProfile} from './profiles/column.model';
import {FrontProfile} from './profiles/front.model';
import {WallProfile} from './profiles/wall.model';
import {IGlassSpec, IGlassWallDiamondSpec, IGlassWallSpec} from '../interfaces/specs/IGlassSpec';
import { ICommonSpec } from '../interfaces/specs/ICommonSpec';
import {ProfileService} from '../services/profile.service';
import {AreaType, CollectionName} from 'src/app/_core/interfaces/IAreaType';
import {Profile} from './profiles/profile.model';
import {GLASS_PART_PADDING, SvgParams, COLUMNS_AVALIABLE} from '../constants/constants';
import {GlassWall} from './glasses/glassWall.model';
import {FrameProfile} from './profiles/frame.model';
import {MuntinProfile} from './profiles/muntin.model';
import {v4 as uuidv4} from 'uuid';
import {GlassWallDiamond} from './glasses/glassWallDiamond.model';
import {IPoint} from '../interfaces/IPoint';
import { RoofWindow } from './glasses/roofWindow.model';
import { MarquiseVertical } from './marquises/marquise-vertical.model';
import { IMarquiseSpec } from '../interfaces/specs/IMarquiseSpec';
import { RoofService } from '../services/areas/roof.service';
import { RoofVentilator } from './glasses/roofVentilator.model';
import { Door } from './doors/door.model';
import { IDoorSpec } from '../interfaces/specs/IDoorSpec';
import { Wall } from './walls/wall.model';
import { IWallSpec } from '../interfaces/specs/IWallSpec';

export enum SpecMode {
	None = "None",
	Cutting = "CuttingOnSite",
	Spec = "Specification",
}

export class ProjectSpecification {
  public glasses: IGlassSpec[] = [];
  public glassWalls: IGlassWallSpec[] = [];
  public glassWallDiamonds: IGlassWallDiamondSpec[] = [];
  public bars: ICommonSpec[] = [];
  public columns: IColumnSpec[] = [];
  public rears: ICommonSpec[] = [];
  public fronts: ICommonSpec[] = [];
  public extras: ICommonSpec[] = [];
  public vents: ICommonSpec[] = [];
  public wallGuts: ICommonSpec[] = [];
  public marquises: IMarquiseSpec[] = [];
  public doors: IDoorSpec[] = [];
  public walls: IWallSpec[] = [];

  public uniqueGlassIds = new Set<string>();
  public uniqueGlassWallIds = new Set<string>();
  public uniqueDoorsIds = new Set<string>();
  public uniqueGlassWallDiamondIds = new Set<string>();
  public uniqueBarIds = new Set<string>();
  public uniqueColumnIds = new Set<string>();
  public uniqueRearIds = new Set<string>();
  public uniqueFrontIds = new Set<string>();
  public uniqueFrameIds = new Set<string>();
  public uniqueVentIds = new Set<string>();
  public uniqueWallsIds = new Set<string>();

  private mapElements = new Map<ElementType, Function>([
    [ElementType.Frame, this.addOrUpdateFrame.bind(this)],
    [ElementType.GlassWall, this.addOrUpdateGlassWall.bind(this)],
    [ElementType.GlassWallDiamond, this.addOrUpdateGlassWallDiamond.bind(this)],
    [ElementType.Glass, this.addOrUpdateGlass.bind(this)],
    [ElementType.Bar, this.addOrUpdateBar.bind(this)],
    [ElementType.SideFinish, this.addOrUpdateBar.bind(this)],
    [ElementType.Column, this.addOrUpdateColumns.bind(this)],
    [ElementType.WallProfile, this.addOrUpdateRears.bind(this)],
    [ElementType.Front, this.addOrUpdateFronts.bind(this)],
    [ElementType.RoofVentilator, this.addOrUpdateVentilator.bind(this)],
    [ElementType.Door, this.addOrUpdateDoor.bind(this)],
    [ElementType.Wall, this.addOrUpdateWall.bind(this)],
  ]);

  constructor(
    private roofService: RoofService,
    private profileService: ProfileService,
		private area: AreaType = AreaType.None,
		private mode: SpecMode = SpecMode.Spec
	) { 
  }

  collectElements(area: AreaType) {

    const cn = CollectionName.get(area);
    var elements = this.profileService[cn];

    this.collectSameElementsByType(elements, ElementType.Frame);
    this.collectSameElementsByType(elements, ElementType.GlassWall);
    this.collectSameElementsByType(elements, ElementType.GlassWallDiamond);
    this.collectSameElementsByType(elements, ElementType.Glass);
    this.collectSameElementsByType(elements, ElementType.Bar);
    this.collectSameElementsByType(elements, ElementType.SideFinish);
    this.collectSameElementsByType(elements, ElementType.Column);
    this.collectSameElementsByType(elements, ElementType.WallProfile);
    this.collectSameElementsByType(elements, ElementType.Front);
    this.collectSameElementsByType(elements, ElementType.RoofVentilator);
    this.collectSameElementsByType(elements, ElementType.Door);
    this.collectSameElementsByType(elements, ElementType.Wall);

    const wgCount = elements[ElementType.WallGutter].length;
    if (wgCount > 0) {
      const wg = elements[ElementType.WallGutter][0];
      this.wallGuts.push({ id: uuidv4(), configId: wg.configId, count: wgCount, length: 0 });
    }

    this.collectVerticalElements(area);

  }

  setup() {
		this.uniqueFrameIds.clear();
		this.uniqueGlassWallIds.clear();
    this.uniqueGlassWallDiamondIds.clear();
		this.uniqueGlassIds.clear();
		this.uniqueBarIds.clear();
		this.uniqueColumnIds.clear();
		this.uniqueRearIds.clear();
		this.uniqueFrontIds.clear();
    this.uniqueVentIds.clear();
    this.uniqueDoorsIds.clear();

    this.glasses = [];
    this.glassWalls = [];
    this.glassWallDiamonds = [];
    this.bars = [];
    this.columns = [];
    this.rears = [];
    this.fronts = [];
    this.extras = [];
    this.marquises = [];
    this.vents = [];
    this.wallGuts = [];
    this.doors = [];

    if (this.area == AreaType.None || this.area == AreaType.Roof) {
      this.collectElements(AreaType.Roof);
    }

    if (this.area == AreaType.None || this.area == AreaType.Front) {
      this.collectElements(AreaType.Front);
    }

    if (this.area == AreaType.None || this.area == AreaType.Left) {
      this.collectElements(AreaType.Left);
    }

    if (this.area == AreaType.None || this.area == AreaType.Right) {
      this.collectElements(AreaType.Right);
    }
    
    if (this.area == AreaType.None || this.area == AreaType.Rear) {
      this.collectElements(AreaType.Rear);
    }

  }

  private collectVerticalElements(area: AreaType) {
    this.profileService.getVerticals(area, ElementType.MarquiseVertical).forEach((m: MarquiseVertical) => {
      this.addOrUpdateMarquise(m);
    });
    this.profileService.getVerticals(area, ElementType.Door).forEach((m: Door) => {
      this.addOrUpdateDoor(m);
    });
    this.profileService.getVerticals(area, ElementType.Wall).forEach((m: Wall) => {
      this.addOrUpdateWall(m);
    });
    this.profileService.getVerticals(area, ElementType.GlassWallDiamond).forEach((m: GlassWallDiamond) => {
      this.addOrUpdateGlassWallDiamond(m);
    });
    this.profileService.getVerticals(area, ElementType.GlassWall).forEach((m: GlassWall) => {
      this.addOrUpdateGlassWall(m);
    });
  }

  private collectSameElementsByType(elements, elementType) {
    elements[elementType].forEach(elem => {
      this.mapElements.get(elementType)(elem)
    });
  }

  private addFrame(frames: ICommonSpec[], frame: FrameProfile) {
    if (!frame?.visible) {
      return;
    }

    var l = Math.round(frame.getLength());
    var f = frames.find(c => c.configId == frame.configId && c.length == l);
    if (f) {
      f.count++;
    } else {
      frames.push({ id: frame.id, configId: frame.configId, length: l, count: 1 })
    }
  }

  private addOrUpdateDoor(d: Door) {
    if (this.uniqueDoorsIds.has(d.id)) {
      return;
    }
    this.uniqueDoorsIds.add(d.id);

    const w = Math.round(d.rect.w / SvgParams.SCALE);
    const h = Math.round(d.rect.h / SvgParams.SCALE);

    let fr: ICommonSpec;
    if (d.topProfile && d.topProfile.rect.h > 0) {
      fr = { id: d.topProfile.id, configId: d.topProfile.configId, length: d.topProfile.getLength(), count: 1 };
    }

    var toBom: IDoorSpec =
    {
      id: d.id,
      configId: d.def.id,
      width: w,
      height: h,
      glassId: d.glassId,
      locks: d.getLocks(),
      extras: d.getExtras(),
      topFrame: fr,
      lockVariant: d.lockVariant.id
    };


    this.doors.push(toBom);

  }

  private addOrUpdateWall(wall: Wall) {
    if (this.uniqueWallsIds.has(wall.id)) {
      return;
    }
    this.uniqueWallsIds.add(wall.id);

    const w = Math.round(wall.width);
    const h = Math.round(wall.height);

    let tf: ICommonSpec;
    if (wall.topProfile && wall.topProfile.rect.h > 0) {
      tf = { id: wall.topProfile.id, configId: wall.topProfile.configId, length: wall.topProfile.getLength(), count: 1 };
    }

    let bf: ICommonSpec;
    if (wall.bottomProfile && wall.bottomProfile.rect.h > 0) {
      bf = { id: wall.bottomProfile.id, configId: wall.bottomProfile.configId, length: wall.bottomProfile.getLength(), count: 1 };
    }

    let lf: ICommonSpec;
    if (wall.leftProfile && wall.leftProfile.width > 0) {
      lf = { id: wall.leftProfile.id, configId: wall.leftProfile.configId, length: wall.leftProfile.getLength(), count: 1 };
    }

    let rf: ICommonSpec;
    if (wall.rightProfile && wall.rightProfile.width > 0) {
      rf = { id: wall.rightProfile.id, configId: wall.rightProfile.configId, length: wall.rightProfile.getLength(), count: 1 };
    }

    var toBom: IWallSpec =
    {
      id: wall.id,
      configId: wall.def.id,
      width: w,
      height: h,
      topFrame: tf,
      bottomFrame: bf,
      leftFrame: lf,
      rightFrame: rf
    };


    this.walls.push(toBom);

  }

  private addOrUpdateGlassWall(gw: GlassWall) {
    const isElemCutting = this.getIsElemCutting(gw);

    if (this.uniqueGlassWallIds.has(gw.id) || isElemCutting) {
      return;
    }
    this.uniqueGlassWallIds.add(gw.id);

    const w = Math.round(gw.width);
    const h = Math.round(gw.height);
    const padding = (GLASS_PART_PADDING * 2) / SvgParams.SCALE;

    var frames: ICommonSpec[] = [];
    this.addFrame(frames, gw.leftFrame);
    this.addFrame(frames, gw.rightFrame);
    this.addFrame(frames, gw.topFrame);
    this.addFrame(frames, gw.bottomFrame);

    var toBom: IGlassWallSpec = 
      { id: gw.id, configId: gw.configId,
        width: w, height: h, areaType: gw.area, parts: [],
        frames: frames, cuttingOnSite: gw.cuttingOnSite
      };

    for(var i = 0; i <= gw.parts.length - 1; i++) {
      const glass = gw.parts[i];
      const w = Math.round(glass.width + padding);
      const h = Math.round(glass.height + padding);
      const x = Math.round(glass.rect.x - SvgParams.START_X) * SvgParams.SCALE;
      const y = Math.round(glass.rect.y - SvgParams.START_Y) * SvgParams.SCALE;

      var l = "";
      var r = "";
      var t = "";
      var b = "";

      const leftMuntin: MuntinProfile = gw.muntins.find(i => i.leftPart.id == glass.id);
      if (leftMuntin) {
        r = "M";
      }

      const rightMuntin: MuntinProfile = gw.muntins.find(i => i.rightPart.id == glass.id);
      if (rightMuntin) {
        l = "M";
      }

      if (i == 0 && gw.leftFrame?.visible) {
        l = "F";
      }
      if (i == gw.parts.length - 1 && gw.rightFrame?.visible) {
        r = "F";
      }

      if (gw.topFrame?.visible) {
        t = "F";
      }
      if (gw.bottomFrame?.visible) {
        b = "F";
      }

      toBom.parts.push({ configId: glass.configId, width: w, height: h, x: x, y: y, onLeft: l, onRight: r, onTop: t, onBottom: b});
    };

    this.glassWalls.push(toBom);
  }

  private normalizePoints(gp: IPoint[]): IPoint[] {
    var points: IPoint[] = [];
    for (var i = 0; i <= 3; i++) {
      const x = gp[i].x - SvgParams.START_X;
      const y = gp[i].y - SvgParams.START_Y;
      points.push({ x: Math.round(x / SvgParams.SCALE), y: Math.round(y / SvgParams.SCALE) });
    }
    return points;
  }

  private addOrUpdateGlassWallDiamond(gwd: GlassWallDiamond) {
    const isElemCutting = this.getIsElemCutting(gwd);

    if (this.uniqueGlassWallDiamondIds.has(gwd.id) || isElemCutting) {
      return;
    }
    this.uniqueGlassWallDiamondIds.add(gwd.id);

    const w = Math.round(gwd.width);
    const h = Math.round(gwd.height);
    const lh = Math.round(gwd.lowerHeight);
    const padding = (GLASS_PART_PADDING * 2) / SvgParams.SCALE;

    var frames: ICommonSpec[] = [];
    this.addFrame(frames, gwd.leftFrame);
    this.addFrame(frames, gwd.rightFrame);
    this.addFrame(frames, gwd.bottomFrame);

    const toBom: IGlassWallDiamondSpec =
      { id: gwd.id, configId: gwd.configId,
        width: w, height: h, lowerHeight: lh,
        areaType: gwd.area, parts: [], points: this.normalizePoints(gwd.points),
        frames: frames, cuttingOnSite: gwd.cuttingOnSite
      };

    for(var i = 0; i <= gwd.parts.length - 1; i++) {
      const glass = gwd.parts[i];
      const w = Math.round(glass.width + padding);
      const h = Math.round(glass.height + padding);

      var l = "";
      var r = "";
      var b = "";

      const leftMuntin: MuntinProfile = gwd.muntins.find(i => i.leftPart.id == glass.id);
      if (leftMuntin) {
        r = "M";
      }

      const rightMuntin: MuntinProfile = gwd.muntins.find(i => i.rightPart.id == glass.id);
      if (rightMuntin) {
        l = "M";
      }

      if (gwd.bottomFrame?.visible) {
        b = "F";
      }

      var gp: IPoint[] = [
        { x: glass.points[0].x - GLASS_PART_PADDING, y: glass.points[0].y - GLASS_PART_PADDING},
        { x: glass.points[1].x + GLASS_PART_PADDING, y: glass.points[1].y - GLASS_PART_PADDING },
        { x: glass.points[2].x + GLASS_PART_PADDING, y: glass.points[2].y + GLASS_PART_PADDING },
        { x: glass.points[3].x - GLASS_PART_PADDING, y: glass.points[3].y + GLASS_PART_PADDING }
      ];


      toBom.parts.push({ id: glass.id, configId: glass.configId, width: w, height: h, points: this.normalizePoints(gp), onLeft: l, onRight: r, onBottom: b});
    };

    this.glassWallDiamonds.push(toBom);
  }

  private addOrUpdateGlass(glass: Glass) {
    if (this.uniqueGlassIds.has(glass.id) || this.mode === SpecMode.Cutting) {
      return
    }
    this.uniqueGlassIds.add(glass.id);

    const w = Math.round(glass.width);
    const h = Math.round(glass.getHeight(this.roofService));
    const wind = this.profileService.roofElements[ElementType.RoofWindow].find((r: RoofWindow) => r.bottomGlass?.id == glass.id || r.topGlass?.id == glass.id);
    const windId = wind?.id;

    const groups = this.glasses.filter(f => f.configId === glass.configId && Math.round(f.height) === h && Math.round(f.width) === w && (!windId || f.roofWindowId == windId));
    if (groups.length === 0) {
      this.glasses.push({ id: glass.id, configId: glass.configId, width: w, height: h, count: 1, color: glass.color, areaType: AreaType.Roof, roofWindowId: windId });
    } else {
      groups[0].count++;
    }
  }

	private getIsElemCutting(elem): boolean {
		return this.mode === SpecMode.Cutting ? !elem.cuttingOnSite : false;
	}

  private addOrUpdateBar(bar: Profile) {
		const isElemCutting = this.getIsElemCutting(bar);

    if (this.uniqueBarIds.has(bar.id) || isElemCutting) {
      return
    }
    this.uniqueBarIds.add(bar.id);

    const w = Math.round(bar.getLength());

		const gs = this.bars.filter(f => Math.round(f.length) === w && f.cuttingOnSite === bar.cuttingOnSite);
    if (gs.length === 0) {
      this.bars.push({ id: uuidv4(), configId: bar.configId, length: w, count: 1, cuttingOnSite: bar.cuttingOnSite });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateFrame(frame: FrameProfile) {
		const isElemCutting = this.getIsElemCutting(frame);

		if (this.uniqueFrameIds.has(frame.id) || isElemCutting) {
      return
    }
    this.uniqueFrameIds.add(frame.id);

    // const w1 = Math.round(frame.getLength());
    const w = frame.orientation == Orientation.Horizontal ? Math.round(frame.rect.w / SvgParams.SCALE) : Math.round(frame.rect.h / SvgParams.SCALE);

		const gs = this.extras.filter(f => Math.round(f.length) === w);
    if (gs.length === 0) {
      this.extras.push({ id: frame.id, configId: frame.configId, length: w, count: 1 });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateColumns(column: ColumnProfile) {
		const isElemCutting = this.getIsElemCutting(column);

		if (this.uniqueColumnIds.has(column.id) || isElemCutting) {
      return
    }
    this.uniqueColumnIds.add(column.id);

		let h = Math.round(column.getHeight());
    let eh = COLUMNS_AVALIABLE.find(c => c >= h);
		const gs = this.columns.filter(f => f.configId === column.configId && Math.round(f.length) === eh && f.cuttingOnSite === column.cuttingOnSite);
    if (gs.length === 0) {
      this.columns.push({ id: uuidv4(), configId: column.configId, length: eh, count: 1, isRear: column.isRear, cuttingOnSite: column.cuttingOnSite });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateRears(rear: WallProfile) {
		const isElemCutting = this.getIsElemCutting(rear);

		if (this.uniqueRearIds.has(rear.id) || isElemCutting) {
      return
    }
    this.uniqueRearIds.add(rear.id);
    const le = Math.round(rear.getLength());
    const gs = this.rears.filter(f => f.configId === rear.configId && f.length === le && f.cuttingOnSite === rear.cuttingOnSite);
    if (gs.length === 0) {
      this.rears.push({ id: uuidv4(), configId: rear.configId, length: le, count: 1, cuttingOnSite: rear.cuttingOnSite });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateFronts(front: FrontProfile) {
		const isElemCutting = this.getIsElemCutting(front);

		if (this.uniqueFrontIds.has(front.id) || isElemCutting) {
      return
    }
    this.uniqueFrontIds.add(front.id);

    const le = Math.round(front.getLength());
		const gs = this.fronts.filter(f => f.configId === front.configId && f.length === le && f.cuttingOnSite === front.cuttingOnSite);
    if (gs.length === 0) {
      this.fronts.push({ id: uuidv4(), configId: front.configId, length: le, count: 1, cuttingOnSite: front.cuttingOnSite });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateVentilator(v: RoofVentilator) {

    if (this.uniqueVentIds.has(v.id)) {
      return
    }
    this.uniqueFrontIds.add(v.id);

    const gs = this.vents.filter(f => f.configId === v.configId && Math.round(f.length) === Math.round(v.width));
    if (gs.length === 0) {
      this.vents.push({ id: uuidv4(), configId: v.configId, length: Math.round(v.width), count: 1 });
    } else {
      gs[0].count++;
    }
  }

  private addOrUpdateMarquise(mq: MarquiseVertical) {

    var frames: ICommonSpec[] = [];
    if (mq.leftProfile.type == ElementType.Frame) {
      this.addFrame(frames, mq.leftProfile as FrameProfile);
    }
    if (mq.rightProfile.type == ElementType.Frame) {
      this.addFrame(frames, mq.rightProfile as FrameProfile);
    }
    this.addFrame(frames, mq.topFrame);

    const color = mq.fabric ? (mq.fabric["name"] ? mq.fabric["name"] : mq.fabric["description"]) : null;
    this.marquises.push({
        id: uuidv4(),
        type: mq.type,
        width: Math.round(mq.widthCorrected),
        depth: Math.round(mq.height),
        configId: mq.configId,
        color: color?.trim(),
        engineId: mq.engine.configId,
        handlers: [ mq.handler.id, mq.handler.id ], // left and right handler
        frames: frames,
        isReserved: mq.isReversed
      });
  }
}
