import {Component, OnInit, ViewChild} from '@angular/core';
import {LeftSideService} from '../../_core/services/areas/left-side.service';
import {ProjectService} from '../../_core/services/project.service';
import {ProfileService} from '../../_core/services/profile.service';
import {RightSideService} from '../../_core/services/areas/right-side.service';
import {RoofService} from '../../_core/services/areas/roof.service';
import {FrontService} from '../../_core/services/areas/front.service';
import {ElementType} from '../../_core/models/ToolboxModel';
import {ColumnProfile} from '../../_core/models/profiles/column.model';
import {ChosenProfileService} from '../../_core/services/chosen-elements/chosen-profile.service';
import {Common} from '../../_core/models/common';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { SNOW_ZONES, SvgParams, WIND_ZONES } from 'src/app/_core/constants/constants';
import { Glass } from 'src/app/_core/models/glasses/glass.model';
import { LayerLevel } from 'src/app/_core/interfaces/LayerLevel';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';
import { GlassWallDiamondService } from 'src/app/_core/services/glass-walls/glass-wall-diamond.service';
import { GlassWallDiamond } from 'src/app/_core/models/glasses/glassWallDiamond.model';
import { RearService } from 'src/app/_core/services/areas/rear.service';
import { GlassWallService } from 'src/app/_core/services/glass-walls/glass-wall.service';
import { GlassWall } from 'src/app/_core/models/glasses/glassWall.model';
import { DoorCreator } from 'src/app/_core/models/doors/door-creator';
import { Door } from 'src/app/_core/models/doors/door.model';
import { AuthService } from 'src/app/_core/services/auth.service';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
	@ViewChild('numElongLeft', { static: true }) numElongLeft: NumTbxComponent;
	@ViewChild('numElongRight', { static: true }) numElongRight: NumTbxComponent;
	@ViewChild('numBack', { static: true }) numBack: NumTbxComponent;
	@ViewChild('numFront', { static: true }) numFront: NumTbxComponent;
	@ViewChild('numWidth', { static: true }) numWidth: NumTbxComponent;
	@ViewChild('numDepth', { static: true }) numDepth: NumTbxComponent;
	@ViewChild('numDrop', { static: true }) numDrop: NumTbxComponent;
	@ViewChild('numBars', { static: true }) numBars: NumTbxComponent;

	justifyBarsToolTip: string = $localize`:Project details|Bars justify tooltip:Justify bars`;
	typesToCheck: ElementType[] = [ElementType.Led, ElementType.LedStripe, ElementType.MarquiseTopUp, ElementType.MarquiseTopBottom, ElementType.RoofWindow, ElementType.RoofGutter, ElementType.RoofVentilator];
	toExecute: string;

	frontDiff: number = 0;

	showLoading: boolean = true;
	messageLoading: string = "Please wait";

	isConfirmDialogOpened: boolean = false;
	confirmDialogMessage: string;

	layerInside: boolean = true;
	layerMiddle: boolean = true;
	layerOutside: boolean = true;
	layerDimensions: boolean = false;

	public get useStatics(){
		return this.projectService.useStatics;
	}

	public set useStatics(s: boolean){
		this.projectService.useStatics = s;
		this.refresh();
	}

	public get canDisableStatics(): boolean {
		return this.authService.user?.canDisableStatics ?? false;
	}

	//#region Setters / getters

	get frontId() {
		return this.projectService.template.frontId;
	}
	set frontId(id: string) {
		this.roofService.applyTemplate(id, this.rearId, null, this.projectElongLeft, this.projectElongRight);
		this.profileService.removeVerticals(AreaType.Front);
		this.profileService.removeVerticals(AreaType.Roof);
		this.chosenProfileService.resetChosenProfileSubj.next();
		this.projectService.recalculateDimensions();
	}

	get rearId() {
		return this.projectService.template.rearId;
	}
	set rearId(id: string) {
		this.roofService.applyTemplate(this.frontId, id, null, this.projectElongLeft, this.projectElongRight);
		this.profileService.removeVerticals(AreaType.Front);
		this.profileService.removeVerticals(AreaType.Roof);
		this.chosenProfileService.resetChosenProfileSubj.next();
		this.projectService.recalculateDimensions();
	}


	get barId() {
		return this.projectService.template.barId;
	}
	set barId(id: string) {
		this.roofService.applyTemplate("", id, null, this.projectElongLeft, this.projectElongRight);
		this.chosenProfileService.resetChosenProfileSubj.next();
		this.projectService.recalculateDimensions();
	}

	get formSideId() {
		return this.projectService.template.formSideId;
	}
	set formSideId(id: string) {
		const fs = this.projectService.template.formSides.find(f => f.id == id);
		this.projectService.template.formSideId = id;
		this.projectService.template.setStandAlone(fs.isStandalone);
		this.roofService.applyTemplate("", null, null, this.projectElongLeft, this.projectElongRight);
		this.profileService.removeVerticals();
		this.chosenProfileService.resetChosenProfileSubj.next();
		this.projectService.recalculateDimensions();
	}

	get fasteningId() {
		return this.projectService.template.fasteningId;
	}
	set fasteningId(id: string) {
		this.projectService.template.fasteningId = id;
		this.projectService.emitChange();
	}

	get projectBackHeight() {
		return this.projectService.template.backHeight;
	}

	set projectBackHeight(backHeight: number) {
		const diff = backHeight - this.projectService.template.backHeight;
		if (!this.canChangeHeight(diff)) {
			this.numBack.rebind(this.projectService.template.backHeight);
			return;
		}
		const oldBottom = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		this.projectService.template.backHeight = backHeight;
		if (this.isAnyLockedColumn()) {
			const newDropAngle = Math.atan(this.projectService.template.frontHeight / this.projectService.template.depth) * 180 / Math.PI;
			this.projectService.template.dropAngle = newDropAngle;
		}
		this.refresh();
		this.adjustElements(oldBottom, diff);
	}

	get projectFrontHeight() {
		return this.projectService.template.frontHeight;
	}

	set projectFrontHeight(frontHeight: number) {
		const diff = Math.round(frontHeight - this.projectService.template.frontHeight);
		if (!this.canChangeHeight(diff)) {
			this.numBack.rebind(this.projectService.template.frontHeight);
			return;
		}
		const oldBottom = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		this.projectService.template.backHeight += diff;		
		this.projectService.template.frontHeight = frontHeight;
		this.refresh();
		this.adjustElements(oldBottom, diff);
	}

	get projectWidth() {
		return this.projectService.template.width;
	}
	set projectWidth(width: number) {
		this.profileService.removeVerticals(AreaType.Front);
		this.profileService.removeVerticals(AreaType.Roof);
		this.projectService.template.width = width;
		this.refresh();
	}

	get projectDepth() {
		return this.projectService.template.depth;
	}
	set projectDepth(newDepth: number) {
		this.projectService.template.depth = newDepth;
		if (this.isAnyLockedColumn()) {
			const newDropAngle = Math.atan(this.frontDiff / newDepth) * 180 / Math.PI
			this.projectService.template.dropAngle = newDropAngle;
		}
		this.profileService.removeVerticals();
		this.refresh();
	}

	get projectElongLeft() {
		return this.projectService.template.elongLeft;
	}

	set projectElongLeft(value: number) {
		this.roofService.applyTemplate("", "", null, value, this.projectService.template.elongRight);
	}

	get projectElongRight() {
		return this.projectService.template.elongRight;
	}

	set projectElongRight(value: number) {
		this.roofService.applyTemplate("", "", null, this.projectService.template.elongLeft, value);
	}

	private _projectBarsCount: number;
	get projectBarsCount() {
		this._projectBarsCount = this.profileService.roofElements == null ? 0 : this.profileService.roofElements[ElementType.Bar].length + 2;
		return Math.max(this._projectBarsCount, this.projectService.template.staticBarsCount);
	}
	set projectBarsCount(newCount: number) {
		var re = this.typesToCheck.reduce((acc, obj) => {
			return acc + this.profileService.roofElements[obj].length;
		}, 0);

		if (re == 0) {
			re = this.profileService.roofElements[ElementType.Glass].find((g: Glass) => g.configId != this.projectService.template.glassId ) ? 1 : 0;
		}
		if (re > 0) {
			this.toExecute = `this.setBarsCount(${newCount});`;
			this.isConfirmDialogOpened = true;
			this.confirmDialogMessage = $localize`:Messages|Bars confirmation:Are you sure? This operation will remove customization and additions on the roof!`;
			return;
		} else {
			this.setBarsCount(newCount);
		}

	}

	private setBarsCount(newCount: number) {
		this.roofService.setBars(newCount);
		this.projectService.clearCollections(ElementType.ProfileConnector);
		this.projectService.addBarsConnectors();

		this.projectService.ledPattern = undefined;
		this.typesToCheck.forEach(t => { this.projectService.clearCollections(t) });
		this.profileService.removeVerticals(AreaType.Front);
		this.profileService.removeVerticals(AreaType.Roof);

		this.chosenProfileService.resetChosenProfileSubj.next();
		this.projectService.recalculateDimensions();
		this.projectService.emitChange();
	}

	get projectDropAngle() {
		return this.projectService.template.dropAngle;
	}
	set projectDropAngle(newDropAngle: number) {
		const previousFrontHeight = this.projectService.template.frontHeight;
		this.frontDiff = Math.round(this.projectService.template.depth * Math.tan(Common.toRadians(newDropAngle)));
		if (this.isAnyLockedColumn()) {
			this.projectService.template.backHeight = previousFrontHeight + this.frontDiff;
		}
		this.projectService.template.dropAngle = newDropAngle;
		this.profileService.removeVerticals();
		this.refresh();
	}

	get snowZones() {
		return SNOW_ZONES;
	}

	get windZones() {
		return WIND_ZONES;
	}

	//#endregion

	constructor(
		public projectService: ProjectService,
		public roofService: RoofService,
		public frontService: FrontService,
		public leftSideService: LeftSideService,
		public rightSideService: RightSideService,
		public profileService: ProfileService,
		public chosenProfileService: ChosenProfileService,
		private rearService: RearService,
		private leftService: LeftSideService,
		private rightService: RightSideService,
		private authService: AuthService
	) { }

	ngOnInit(): void {

		this.projectService.elementsChanged.subscribe(flag => {
			if (!flag) {
				return;
			}
			this.frontDiff = this.projectService.template.backHeight - this.projectService.template.frontHeight;

			this.projectService.recalculateDimensions();
			this.numBack.rebind(this.projectBackHeight, false);
			this.numFront.rebind(this.projectFrontHeight, false);
			this.numWidth.rebind(this.projectWidth, false);
			this.numDrop.rebind(this.projectDropAngle, false);
			this.numBars.rebind(this.projectService.template.staticBarsCount, false);
		});

		this.projectService.projectReady.subscribe(item => {
			this._projectBarsCount = this.projectService.template.barsCount + 2;
			this.frontDiff = this.projectService.template.backHeight - this.projectService.template.frontHeight;

			setTimeout(() => {
				this.showLoading = false;
			}, 1000);
		}, error => {
			this.showLoading = false
		});

		this.numFront.valueChanged.subscribe(v => {
			this.projectFrontHeight = v;
		});

		this.numBack.valueChanged.subscribe(v => {
			this.projectBackHeight = v;
		});

		this.numFront.valueChanged.subscribe(v => {
			this.projectFrontHeight = v;
		});

		this.numWidth.valueChanged.subscribe(v => {
			this.projectWidth = v;
		});

		this.numDepth.valueChanged.subscribe(v => {
			this.projectDepth = v;
		});

		this.numDrop.valueChanged.subscribe(v => {
			this.projectDropAngle = v;
		});

		this.numBars.valueChanged.subscribe(v => {
			this.projectBarsCount = v;
		});

		// if (this.numElongLeft && this.numElongRight) {
		// 	this.numElongLeft.valueChanged.subscribe(v => {
		// 		this.projectElongLeft = v;
		// 	});
		// 	this.numElongRight.valueChanged.subscribe(v => {
		// 		this.projectElongRight = v;
		// 	});
		// }
	}

	onAfterValueChange(): void {
		this.projectService.recalculateDimensions();
	}

	isAnyLockedColumn() {
		return this.profileService.roofElements[ElementType.Column].find((col: ColumnProfile) => col.locked === true)
	}

	recalculate() {
		this.roofService.applyTemplate("", "");
	}

	setFreeze(status: boolean) {
		if (status) {
			this.projectService.freezeProjProp();
		} else {
			this.openConfirmDialog();
		}
	}

	setStandalone(stadalone: boolean) {
		if (stadalone == this.projectService.template.isStandalone) {
			return;
		}
		this.projectService.template.setStandAlone(stadalone);
		this.roofService.applyTemplate("", null, null, this.projectElongLeft, this.projectElongRight);
		// this.refresh();
	}

	openConfirmDialog() {
		this.toExecute = "this.projectService.unfreezeProjProp();";
		this.isConfirmDialogOpened = true;
		this.confirmDialogMessage = $localize`:Messages|Unfreeze confirmation:Are you sure? The project will be rebuild and all additions will be lost!`;
	}

	onConfirmDialogAction(status: any) {
		if (status.status) {
			eval(this.toExecute);
			this.toExecute = "";
		}
		this.isConfirmDialogOpened = false;
	}

	refresh() {
		this.roofService.changeArea();
		this.chosenProfileService.resetChosenProfileSubj.next();
		this.roofService.calculatePoints();
		this.frontService.calculatePoints();
		this.rearService.calculatePoints();
		this.leftService.calculatePoints();
		this.rightService.calculatePoints();
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}

	justifyBars() {
		this.projectBarsCount = this.numBars.value;
	}

	justifyColumns() {
		this.projectService.clearCollections(ElementType.Dimension);
		this.profileService.justifyColumns(this.projectService.template.width, true);
		this.profileService.justifyColumns(this.projectService.template.width, false);
		this.projectService.emitChange();
	}

	changeLayer() {
		var lvls: LayerLevel[] = [LayerLevel.Construction];
		if (this.layerInside) {
			lvls.push(LayerLevel.Inside);
		}
		if (this.layerMiddle) {
			lvls.push(LayerLevel.Middle);
		}
		if (this.layerOutside) {
			lvls.push(LayerLevel.Outside);
		}
		this.projectService.layersChanged.next(lvls);
	}

	changeDimensions() {
		this.projectService.showDimensionsSubj.next(this.layerDimensions);
	}

	canChangeHeight(diff: number) {
		if (diff > 0) {
			return true;
		}
		const bottom = this.projectService.CurrentAreaService.getShapeAreaPoints()[SvgParams.LEFT_BOTTOM_POINT_NUM].y;
		var verts = this.profileService.findVerticalsOnBottom(bottom);
		var tooSmall = verts.find(v => v.height + diff < v.defMinHeight);

		return tooSmall == null;
	}
	adjustElements(oldBottom: number, diff: number){
		var verts = this.profileService.findVerticalsOnBottom(oldBottom);

		verts.forEach(v => {
			var newHeight = v.height + diff;
			if (newHeight > v.defMaxHeight) {
				newHeight = v.defMaxHeight;
			}
			if (newHeight > v.maxHeight) {
				newHeight = v.maxHeight;
			}
			switch(v.type) {
				case ElementType.GlassWallDiamond:
					new GlassWallDiamondService(this.projectService).alongHeight(v as GlassWallDiamond, diff);
					break;					
				case ElementType.GlassWall:
					new GlassWallService(this.projectService).alongHeight(v as GlassWall, diff);
					break;					
				case ElementType.Door:
					var d = v as Door;
					var creator = DoorCreator.fromInstance(this.projectService, d, this.projectService.CurrentAreaService);
					creator.setHeight(d, newHeight, false);
					break;					
				default:
					v.height = newHeight;
					break;
			}
			v.refreshSurround(this.profileService);
		});
		
	}

}
