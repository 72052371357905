import { Component, Input, ViewChild } from '@angular/core';
import { CardType, IPropertyCard } from '../../_core/interfaces/IPropertyCard';
import { ProjectService } from '../../_core/services/project.service';
import { RoofService } from '../../_core/services/areas/roof.service';
import { ElementType, Orientation } from '../../_core/models/ToolboxModel';
import { LocationService } from '../../_core/services/location.service';
import { ProfileService } from '../../_core/services/profile.service';
import { WizardParameter } from 'src/app/_core/models/wizard/wizard-parameter.model';
import { ChosenProfileService } from '../../_core/services/chosen-elements/chosen-profile.service';
import { SvgParams, Tags } from '../../_core/constants/constants';
import { NumTbxComponent } from 'src/app/_shared/numeric-textbox/num-tbx.component';
import { BarProfile } from 'src/app/_core/models/profiles/bar.model';
import { ProfileConnector } from 'src/app/_core/models/profiles/connector.model';
import { ExtraOptionService } from 'src/app/_core/services/extra-option.service';
import { ColumnProfile } from 'src/app/_core/models/profiles/column.model';
import { WallMuntinProfile } from 'src/app/_core/models/walls/wall-muntin.model';
import { GlassPartDiamond } from 'src/app/_core/models/glasses/glassDiamondPart';
import { AreaType } from 'src/app/_core/interfaces/IAreaType';

@Component({
	selector: 'card-profile',
	templateUrl: './card-profile.component.html',
	styleUrls: ['../card.component.css']
})
export class CardProfileComponent implements IPropertyCard {
	@ViewChild('numLoc', { static: false }) numLoc: NumTbxComponent;

	@Input() public set items(items: any[] | null) {
		if (items !== null) {
			this.initItem(items[0]);

			switch (this.current.type) {
				case ElementType.Front:
					this.profileTypes = this.projectService.template.fronts;
					break;
				case ElementType.Bar:
					this.locXcorr = this.current.width / 2;
					// do not 'break' here!
				default:
					this.getLocationLimits();
					break;
			}
		}
	}

	private locXcorr: number = 0;
	public type: CardType = CardType.Profile;
	public current: any;
	public minXLocation: number;
	public maxXLocation: number;
	public profileTypes: WizardParameter[];
	public dialogOpened: boolean = false;
	public dialogMessage: string;
	public unfreezingMess = `All freezing elements will be deleted. Are you sure?`;

	constructor(
		public projectService: ProjectService,
		protected roofService: RoofService,
		protected locationService: LocationService,
		protected profileService: ProfileService,
		protected chosenProfileService: ChosenProfileService,
		private extraService: ExtraOptionService
	) { }

	protected initItem(item: any) {
		this.current = item;
		setTimeout(() => {
			if (this.numLoc && this.numLoc.valueChanged.observers.length == 0) {
				this.numLoc.valueChanged.subscribe(v => {
					this.currentProfileLocation = v;
				});
			}
		}, 10);
	}

	//#region Muntins

	private getWallMuntinMinMaxXLocation() {
		const m = this.current as WallMuntinProfile;
		this.minXLocation = m.leftPart.locationX + m.leftPart.minWidth;
		this.maxXLocation = m.rightPart.locationX + m.rightPart.width - m.rightPart.minWidth;
	}

	private getMuntinMinMaxXLocation() {
		this.minXLocation = Math.round((this.current.rightPart && this.current.rightPart.muntinsRequired) ? this.currentProfileLocation : this.getMuntinNewMinXLocation());
		this.maxXLocation = Math.round((this.current.leftPart && this.current.leftPart.muntinsRequired) ? this.currentProfileLocation : this.getMuntinNewMaxXLocation());
	}

	private getMuntinNewMinXLocation(): number {
		switch (this.current.leftPart.type) {
			case ElementType.GlassPart:
				return (this.current.leftPart.rect.x - SvgParams.START_X) / SvgParams.SCALE;

			case ElementType.GlassPartDiamond:
				const lp = this.current.leftPart as GlassPartDiamond;
				return (lp.points[SvgParams.LEFT_TOP_POINT_NUM].x - SvgParams.START_X) / SvgParams.SCALE + lp.defMinWidth;

		}
	}

	private getMuntinNewMaxXLocation(): number {
		switch (this.current.leftPart.type) {
			case ElementType.GlassPart:
				return (this.current.rightPart.rect.x + this.current.rightPart.rect.w - SvgParams.START_X) / SvgParams.SCALE;

			case ElementType.GlassPartDiamond:
				const lp = this.current.leftPart as GlassPartDiamond;
				return (this.current.rightPart.points[SvgParams.RIGHT_TOP_POINT_NUM].x - SvgParams.START_X - this.current.rect.w) / SvgParams.SCALE - lp.defMinWidth;

		}
	}

	//#endregion

	get currentProfileLocation() {
		return this.locationService.getLocation(this.current) + this.locXcorr;
	}

	set currentProfileLocation(location) {
		if (location - this.locXcorr < 0) {
			return;
		}
		this.locationService.setLocation(this.current, location - this.locXcorr, null, false);
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
		this.numLoc.rebind(this.currentProfileLocation);
		this.projectService.emitChange();
		this.getLocationLimits();
	}

	// Profile

	get currentConfigId() {
		return this.current.configId;
	}
	set currentConfigId(id: string) {
		this.current.configId = id;
		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();
	}

	private getBarMinMaxXLocation() {
		const c = this.current as BarProfile;
		if (c.orientation == Orientation.Horizontal) {
			return 0;
		}
		const correction = (c.width / 2) * SvgParams.SCALE;
		this.minXLocation = Math.round((c.leftGlass.leftBar.rightX - SvgParams.START_X + correction) / SvgParams.SCALE);
		if (this.currentProfileLocation - this.minXLocation <= 1) {
			this.minXLocation = this.currentProfileLocation;
		}


		this.maxXLocation = Math.trunc((c.rightGlass.rightBar.leftX - SvgParams.START_X + correction) / SvgParams.SCALE) - c.width;
		if (this.maxXLocation - this.currentProfileLocation <= 1) {
			this.maxXLocation = this.currentProfileLocation;
		}
	}

	private getConnectorMinMaxYLocation() {
		const c = this.current as ProfileConnector;
		if (c.orientation != Orientation.Horizontal) {
			return 0;
		}
		const correction = (c.width / 2) * SvgParams.SCALE;
		const minh = c.topGlass.minWidth * SvgParams.SCALE;
		this.minXLocation = Math.round((c.topGlass.topY - SvgParams.START_Y + correction + minh) / SvgParams.SCALE);
		this.maxXLocation = Math.trunc((c.bottomGlass.bottomY - SvgParams.START_Y + correction - minh) / SvgParams.SCALE) - c.width;

		const frontY = (this.profileService.roofElements[ElementType.Front][0].rectOnRoof.y - SvgParams.START_Y) / SvgParams.SCALE - c.topGlass.minWidth * 2;
		if (this.maxXLocation >= frontY) {
			this.maxXLocation = frontY;
		}
	}

	getLocationLimits() {
		switch(this.current.type) {
			case ElementType.Frame:
				this.getWallMuntinMinMaxXLocation();
				break;
			case ElementType.Muntin:
				this.getMuntinMinMaxXLocation();
				break;
			case ElementType.Bar:
				this.getBarMinMaxXLocation();
				break;
			case ElementType.ProfileConnector:
				this.getConnectorMinMaxYLocation();
				break;
			case ElementType.WallGutter:
				const leftCol: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((c: ColumnProfile) => c.isRear && c.tag == Tags.RIGHT_REAR_COLUMN_TAG);
				this.minXLocation = leftCol.rectOnRoof.w;
				const rightCol: ColumnProfile = this.profileService.roofElements[ElementType.Column].find((c: ColumnProfile) => c.isRear && c.tag == Tags.LEFT_REAR_COLUMN_TAG);
				const x = this.locationService.getLocation(rightCol);
				this.maxXLocation = this.projectService.template.width - x - rightCol.width / 2;
				break;
			default:
				this.minXLocation = null;
				this.maxXLocation = null;
		}
	}

	changeLocationDisabled(): boolean {
		if (this.current.type == ElementType.Muntin || this.current.type == ElementType.Frame) {
			return false;
		}

		if (this.current.type == ElementType.WallGutter) {
			return false;
		}

		if ((this.current.type !== ElementType.Bar && this.current.type !== ElementType.ProfileConnector) || this.projectService.currentArea !== this.current.initPosition) {
			return true;
		}

		return this.current.locked;
	}

	checkFreezingElements(): boolean {
		return this.projectService.readOnly || this.projectService.checkMarquise() || this.roofService.checkRoofWindow();
	}

	openDialog(message: string): void {
		this.dialogOpened = true;
		this.dialogMessage = message;
	}

	onDialogAction(info: {status: boolean, message: string}) {
		this.dialogOpened = false;

		if (!info.status) return;

		switch (info.message) {
			case this.unfreezingMess:
				this.clearFreezingElements();
				break;
		}
	}

	clearFreezingElements() {
		if (this.roofService.checkRoofWindow()){
			this.roofService.applyTemplate("", "");
		} else if (this.projectService.checkMarquise()) {
			this.projectService.clearCollections(ElementType.MarquiseTopUp);
			this.projectService.clearCollections(ElementType.MarquiseTopBottom);
		}
	}

	onLock() {
		this.current.locked = !this.current.locked;
		this.projectService.freezeProjProp();
	}

	deleteElement() {
		var ok = true;
		if (this.current.type == ElementType.Bar || this.current.type == ElementType.ProfileConnector) {
			const b = this.current as BarProfile;
			if (b.orientation == Orientation.Vertical) {
				ok = this.deleteVerticalBar(b);
			} else {
				this.deleteHorizontalBar(b);
			}
		}
		if (ok) {
			this.projectService.deleteElement(this.current);		
		}
	}

	deleteHorizontalBar(bar: BarProfile) {
		const bottomGlassYEndPoint = bar.bottomGlass.rect.y + bar.bottomGlass.rect.h;
		const topGlassYStartPoint = bar.topGlass.rect.y;
		const newHeight = (bottomGlassYEndPoint - topGlassYStartPoint) / SvgParams.SCALE;

		bar.topGlass.height = newHeight;
		bar.topGlass.length = this.projectService.template.glassLength;

		bar.topGlass.bottomBar = null;
		

		this.chosenProfileService.setChosenProfile(null);
		this.projectService.deleteElement(bar.bottomGlass, false);
		this.projectService.deleteElement(bar, false);
		this.extraService.addElevator();
		this.projectService.emitChange();

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();		
	}

	deleteVerticalBar(bar: BarProfile) {
		const rightGlassXEndPoint = bar.rightGlass.rect.x + bar.rightGlass.rect.w;
		const leftGlassXStartPoint = bar.leftGlass.rect.x;
		const newWidth = (rightGlassXEndPoint - leftGlassXStartPoint) / SvgParams.SCALE;
		const maxWidth = bar.leftGlass.maxWidth;

		if (newWidth > maxWidth) {
			const message = $localize`:Glass|Validation message:The width of the new glass is greater than maximum allowable!`;
			this.projectService.showTemporaryMessageSubj.next({message, hideAfter: 2000, style: "error"});
			return false;
		} else {
			bar.rightGlass.rightBar.leftGlass = bar.leftGlass;
			bar.leftGlass.rightBar = bar.rightGlass.rightBar;
			bar.leftGlass.width = newWidth;

			this.chosenProfileService.setChosenProfile(null);
			this.projectService.deleteElement(bar.rightGlass);
			this.projectService.deleteElement(bar);
		}

		this.projectService.freezeProjProp();
		this.projectService.recalculateDimensions();

		return true;
	}

	get currLng(): string {
		return window.document.documentElement.lang;
	}

}
