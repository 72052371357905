import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Profile} from '../../models/profiles/profile.model';
import {LED} from '../../models/profiles/leds.model';
import {SvgParams} from '../../constants/constants';
import {ProfileService} from '../profile.service';
import {ElementType, Orientation} from '../../models/ToolboxModel';
import {BarProfile} from '../../models/profiles/bar.model';
import {ProjectService} from '../project.service';
import {LedStripe} from '../../models/profiles/led-stripe.model';
import { ElementSide } from '../../interfaces/IElementSide';

@Injectable({
  providedIn: 'root'
})
export class ChosenProfileService {
  public chosenProfileSubj: Subject<Profile[]> = new Subject<Profile[]>();
  public resetChosenProfileSubj: Subject<Profile[]> = new Subject<any>();

  private chosenProfile: Profile[] | LED[] = null;

	private _ledsTooltip: string = '';

	get ledsTooltip() {
		return this._ledsTooltip;
	}

  constructor(
		private profileService: ProfileService,
		private projectService: ProjectService,
	) { }

  public getChosenProfile(): Profile[] {
    return this.chosenProfile;
  }

  public setChosenProfile(profiles: Profile[] | null): void {
    this.chosenProfileSubj.next(profiles)

    this.chosenProfile = profiles;

    if (profiles === null) {
      	this.resetChosenProfileSubj.next()
    }
  }

	// ! LEDs region

	public changeLedStripesLocationX(side: ElementSide) {
		if (this.canChangeLedStripesLocationX(side)){
			var glassStr = side.toLowerCase() + 'Glass';
			var barStr = side.toLowerCase() + 'Bar';

			this.chosenProfile.forEach((l: LedStripe) => {
				l.bar = l.bar[glassStr][barStr];
			})
		}
	}

	public canChangeLedStripesLocationX(side: ElementSide) {
		var chosenLeds = this.chosenProfile.sort((i1: LedStripe, i2: LedStripe) => i2.rect.x - i1.rect.x) as LedStripe[];
		if (side == ElementSide.Left) {
			chosenLeds = chosenLeds.sort((i1: LedStripe, i2: LedStripe) => i1.rect.x - i2.rect.x);
		}
		var allLeds = this.profileService.roofElements[ElementType.LedStripe];
		var movableLedsBars: BarProfile[] = [];
		var bars: BarProfile[] = [];
		var glassStr = side.toLowerCase() + 'Glass';
		var barStr = side.toLowerCase() + 'Bar';

		allLeds.forEach(l => {
			bars.push(l.bar);
		})
		
		chosenLeds.forEach(l => {
			let nextBar = l.bar[glassStr][barStr];
			if (movableLedsBars.includes(nextBar) || !bars.includes(nextBar)) {
				movableLedsBars.push(l.bar);
			}
		})
		
		if (movableLedsBars.length == chosenLeds.length) {
			return true;
		}
		return false;
	}


	public changeLedsLocationX(side: ElementSide) {
		const chosenLed = (this.chosenProfile[0] as LED);
		const barsSorted: BarProfile[] = this.profileService.roofElements[ElementType.Bar]
			.filter((bar: BarProfile) => {
				return bar.orientation === Orientation.Vertical
					&& bar.rectOnRoof.y < chosenLed.circle.y - chosenLed.circle.r
					&& bar.rectOnRoof.y + bar.rectOnRoof.h > chosenLed.circle.y + chosenLed.circle.r;
			})
			.sort((bar1, bar2) => bar1.rectOnRoof.x - bar2.rectOnRoof.x);

		let closestBar: BarProfile;
		switch (side) {
			case ElementSide.Left:
				closestBar = barsSorted
					.filter((bar: BarProfile) => bar.rectOnRoof.x + bar.rectOnRoof.w / 2 < chosenLed.circle.x)
					.reduce((prevBar, currBar) => {
						return (Math.abs(currBar.rectOnRoof.x - chosenLed.circle.x) < Math.abs(prevBar.rectOnRoof.x - chosenLed.circle.x) ? currBar : prevBar);
					}, barsSorted[0]);
				break;

			case ElementSide.Right:
				closestBar = barsSorted
					.filter((bar: BarProfile) => bar.rectOnRoof.x + bar.rectOnRoof.w / 2 > chosenLed.circle.x)
					.reduce((prevBar, currBar) => {
						return (Math.abs(currBar.rectOnRoof.x - chosenLed.circle.x) < Math.abs(prevBar.rectOnRoof.x - chosenLed.circle.x) ? currBar : prevBar);
					}, barsSorted[barsSorted.length - 1]);
				break;
		}

		chosenLed.circle.x = closestBar.rectOnRoof.x + closestBar.rectOnRoof.w / 2;
	}

	isLedStripesChangeLocationXBtnDisable(side: ElementSide): boolean {
		this.chosenProfile = this.chosenProfile.sort((i1: LedStripe, i2: LedStripe) => i1.rect.x - i2.rect.x);
		var c : LedStripe;
		if(side == ElementSide.Left){
			c = this.chosenProfile[0] as LedStripe;
		} else c = this.chosenProfile[this.chosenProfile.length - 1] as LedStripe;

		const chosenLed = c;
		let allLeds: LedStripe[] = this.profileService.roofElements[ElementType.LedStripe];

		const bar: BarProfile = chosenLed.bar;

		let led: LedStripe;
		this._ledsTooltip = side == ElementSide.Left ? $localize`:LEDs|Tooltip:Move left` : $localize`:LEDs|Tooltip:Move right`;
		switch (side) {
			case ElementSide.Left:
				led = allLeds.find(led => led.bar.id == bar.leftGlass.leftBar.id);
				break;

			case ElementSide.Right:
				led = allLeds.find(led => led.bar == bar.rightGlass.rightBar);
				break;
		}

		if (led && !this.canChangeLedStripesLocationX(side)) {
			this._ledsTooltip = $localize`:LEDs|Tooltip:Cannot move one LED on another.`;
			return true;
		} else {
			var sf = false;
			switch (side) {
				case ElementSide.Left:
					sf = bar.leftGlass.leftBar.type == ElementType.SideFinish;
					break;

				case ElementSide.Right:
					sf = bar.rightGlass.rightBar.type == ElementType.SideFinish;
					break;
			}
			if (sf) {
				this._ledsTooltip = $localize`:LEDs|Tooltip:Cannot move a LED to external bar.`;
				return true;
			}
		}

		return false;
	}

	isLedsChangeLocationXBtnDisable(side: ElementSide): boolean {
		const chosenLed = (this.chosenProfile[0] as LED);
		let allLeds: LED[] = this.profileService.roofElements[ElementType.Led];

		const bar: BarProfile = this.profileService.roofElements[ElementType.Bar]
			.find(bar => chosenLed.circle.x === bar.rectOnRoof.x + bar.rectOnRoof.w / 2);

		switch (side) {
			case ElementSide.Left:
				allLeds = allLeds.filter(led => led.circle.x === bar.leftGlass.leftBar.rectOnRoof?.x + bar.leftGlass.leftBar.rectOnRoof?.w / 2)
				break;

			case ElementSide.Right:
				allLeds = allLeds.filter(led => led.circle.x === bar.rightGlass.rightBar.rectOnRoof?.x + bar.rightGlass.rightBar.rectOnRoof?.w / 2)
				break;
		}

		this._ledsTooltip = side == ElementSide.Left ? $localize`:LEDs|Tooltip:Move left` : $localize`:LEDs|Tooltip:Move right`;

		let isLedConflict = true;
		
		isLedConflict = Boolean(
			allLeds.find(led => {
				return Math.round(led.circle.y) > Math.round(chosenLed.circle.y - chosenLed.circle.r - led.circle.r)
					&& Math.round(led.circle.y) < Math.round(chosenLed.circle.y + chosenLed.circle.r + led.circle.r)
				})
		);
		if (isLedConflict) {
			this._ledsTooltip = $localize`:LEDs|Tooltip:You can't move one LED on another`;
		} else {
			var sf = false;
			switch (side) {
				case ElementSide.Left:
					sf = bar.leftGlass.leftBar.type == ElementType.SideFinish;
					break;

				case ElementSide.Right:
					sf = bar.rightGlass.rightBar.type == ElementType.SideFinish;
					break;
				}
				if (sf) {
					this._ledsTooltip = $localize`:LEDs|Tooltip:Cannot move a LED to external bar.`;
					return true;
				}
		}

		return isLedConflict;
	}

	getLedsMinYLocation() {
		const chosenLed = (this.chosenProfile[0] as LED);

		let ledsBeforeChosenLedOnTheSameBar = this.getLedsOnTheSameBar().filter((led: LED) => led.circle.y < chosenLed.circle.y)
		let closestLed: LED = this.getClosestLed(ledsBeforeChosenLedOnTheSameBar);

		if (closestLed) {
			return (closestLed.circle.y + closestLed.circle.r + chosenLed.circle.r - SvgParams.START_Y) / SvgParams.SCALE;
		} else {
			const rd = this.projectService.template.getRearSize().depth;
			return rd + chosenLed.circle.r / SvgParams.SCALE;
		}
	}

	getLedsMaxYLocation() {
		const chosenLed = (this.chosenProfile[0] as LED);

		let ledsAfterChosenLedOnTheSameBar = this.getLedsOnTheSameBar().filter((led: LED) => led.circle.y > chosenLed.circle.y)
		let closestLed: LED = this.getClosestLed(ledsAfterChosenLedOnTheSameBar);

		if (closestLed) {
			return (closestLed.circle.y - closestLed.circle.r - chosenLed.circle.r - SvgParams.START_Y) / SvgParams.SCALE;
		} else {
			const fd = this.projectService.template.getFrontSize().depth;
			return this.projectService.template.depth - fd - chosenLed.circle.r / SvgParams.SCALE;
		}
	}

	private getLedsOnTheSameBar(): LED[] {
		const chosenLed = (this.chosenProfile[0] as LED);
		const allLeds: LED[] = this.profileService.roofElements[ElementType.Led];

		const bar: BarProfile = this.profileService.roofElements[ElementType.Bar]
			.find(bar => chosenLed.circle.x === bar.rectOnRoof.x + bar.rectOnRoof.w / 2);

		return allLeds.filter((led: LED) => led.circle.x === bar.rectOnRoof?.x + bar.rectOnRoof?.w / 2);
	}

	private getClosestLed(ledsBeforeChosenLed: LED[]): LED {
		const chosenLed = (this.chosenProfile[0] as LED);

		if (ledsBeforeChosenLed.length > 0) {
			return ledsBeforeChosenLed.reduce((prevLed, currLed) => {
				return (Math.abs(currLed.circle.y - chosenLed.circle.y) < Math.abs(prevLed.circle.y - chosenLed.circle.y) ? currLed : prevLed);
			})
		}

		return null;
	}

	// ! end LEDs region
}
