import { Injectable } from '@angular/core';
import { ElementIconType, ElementType, ToolboxItemType, ToolboxGroup, ToolboxItem } from 'src/app/_core/models/ToolboxModel';
import { ChosenGlassService } from './chosen-elements/chosen-glass.service';
import { ChosenProfileService } from './chosen-elements/chosen-profile.service';
import { ChosenMarquiseService } from './chosen-elements/chosen-marquise.service';
import { Subject } from 'rxjs';
import { ChosenExtraOptionService } from './chosen-elements/chosen-extra-option.service';
import { ProjectService } from './project.service';
import { AreaType } from '../interfaces/IAreaType';

@Injectable({
	providedIn: 'root'
})
export class ToolboxItemService {
	private currentTool: ToolboxItem;
	public currentTool$: Subject<ToolboxItem> = new Subject<ToolboxItem>();

	private mapToolboxItemTypeIcons = new Map<ToolboxItemType, ElementIconType>([
		[ToolboxItemType.None, ElementIconType.None],
		[ToolboxItemType.RoofWindow, ElementIconType.Glass],
		[ToolboxItemType.Glass, ElementIconType.Glass],
		[ToolboxItemType.GlassWall, ElementIconType.Glass],
		[ToolboxItemType.GlassWallDiamond, ElementIconType.Glass],
		[ToolboxItemType.MarquiseTopUp, ElementIconType.Marquise],
		[ToolboxItemType.MarquiseTopBottom, ElementIconType.Marquise],
		[ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ToolboxItemType.LedSpot, ElementIconType.LedSpot],
		[ToolboxItemType.LedStripBar, ElementIconType.LedStripBar],
		[ToolboxItemType.Connector, ElementIconType.Connector],
		[ToolboxItemType.Column, ElementIconType.Column],
		[ToolboxItemType.Extra, ElementIconType.Extras],
		[ToolboxItemType.WallGutter, ElementIconType.WallGutter],
		[ToolboxItemType.RoofVentilator, ElementIconType.WallGutter],
		[ToolboxItemType.RoofGutter, ElementIconType.WallGutter],
		[ToolboxItemType.Door, ElementIconType.Glass],
		[ToolboxItemType.Wall, ElementIconType.Wall]
	]);

	private mapElementTypeIcons = new Map<string, ElementIconType>([
		[ElementType.Shape + ToolboxItemType.Glass, ElementIconType.Glass],
		[ElementType.Shape + ToolboxItemType.GlassWall, ElementIconType.Glass],
		[ElementType.Shape + ToolboxItemType.GlassWallDiamond, ElementIconType.Glass],
		[ElementType.Shape + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.Shape + ToolboxItemType.MarquiseTopUp, ElementIconType.Marquise],
		[ElementType.Shape + ToolboxItemType.MarquiseTopBottom, ElementIconType.Marquise],
		[ElementType.Glass + ToolboxItemType.RoofWindow, ElementIconType.Glass],
		[ElementType.Glass + ToolboxItemType.RoofGutter, ElementIconType.RoofGutter],
		[ElementType.Glass + ToolboxItemType.Glass, ElementIconType.Glass],
		[ElementType.Glass + ToolboxItemType.MarquiseTopUp, ElementIconType.Marquise],
		[ElementType.Glass + ToolboxItemType.MarquiseTopBottom, ElementIconType.Marquise],
		[ElementType.SideFinish + ToolboxItemType.Column, ElementIconType.Column],
		[ElementType.Front + ToolboxItemType.Column, ElementIconType.Column],
		[ElementType.WallProfile + ToolboxItemType.Column, ElementIconType.Column],
		[ElementType.Grid + ToolboxItemType.Extra, ElementIconType.Extras],
		[ElementType.Glass + ToolboxItemType.Connector, ElementIconType.IconBarH],
		[ElementType.MarquiseVertical + ToolboxItemType.GlassWall, ElementIconType.Glass],
		[ElementType.MarquiseVertical + ToolboxItemType.GlassWallDiamond, ElementIconType.Glass],
		[ElementType.MarquiseVertical + ToolboxItemType.Door, ElementIconType.Glass],
		[ElementType.GlassPart + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.GlassPartDiamond + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.GlassWall + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.GlassWallDiamond + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.MarquiseVertical + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.Muntin + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.Shape + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.Door + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.Wall + ToolboxItemType.MarquiseVertical, ElementIconType.Marquise],
		[ElementType.WallProfile + ToolboxItemType.WallGutter, ElementIconType.WallGutter],
		[ElementType.Glass + ToolboxItemType.RoofVentilator, ElementIconType.Glass],
		[ElementType.Shape + ToolboxItemType.Door, ElementIconType.Glass],
		[ElementType.Shape + ToolboxItemType.Wall, ElementIconType.Wall],
		[ElementType.Bar + ToolboxItemType.LedSpot, ElementIconType.LedSpot],
		[ElementType.Bar + ToolboxItemType.LedStripBar, ElementIconType.LedStripBar],
		[ElementType.Glass + ToolboxItemType.LedSpot, ElementIconType.LedSpot],
		[ElementType.Glass + ToolboxItemType.LedStripBar, ElementIconType.LedStripBar],
	]);

	constructor(
		public chosenGlassService: ChosenGlassService,
		public chosenProfileService: ChosenProfileService,
		public chosenMarquiseService: ChosenMarquiseService,
		public chosenExtraOptionService: ChosenExtraOptionService,
		private projectService: ProjectService
	) { 

		this.projectService.elementDeleted.subscribe(profile => {
			this.onBgClick();
		});

	}

	public setCurrTool(currentTool: ToolboxItem | null) {
		this.currentTool = currentTool;
		this.currentTool$.next(this.currentTool);
	}

	getCurrTool() {
		return this.currentTool;
	}

	getPanelDisabled(item: ToolboxGroup) {
		if (this.projectService.readOnly) {
			return true;
		}
		if (this.projectService.getFreezeStatusOfProjProp()) {
			return item.type == ToolboxItemType.Glass;
		}
		return this.currentTool;
	}

	iconClass(elementType: string, areaType: string): string {
		if (this.currentTool) {
			var style = this.mapToolboxItemTypeIcons.get(this.currentTool.type)?.toString();
			var alowed = this.mapElementTypeIcons.get(elementType + this.currentTool.type) != null;

			if (!alowed) {
				style += "_prohibited";
			}
			return style ?? "";

		} else {
			this.setCurrTool(null);
			if (!this.projectService.readOnly) {
				const at = AreaType[areaType];
				if ((at == AreaType.Left || at == AreaType.Right) &&
					this.projectService.profileService.getVerticals(at).length == 0 && elementType == ElementType.Shape) {
					return ElementIconType.AddElements;
				}
				return ElementIconType.None;
			} else {
				return ElementIconType.None;
			}
		}
	}

	onBgClick(clearTool: boolean = true) {

		if (this.currentTool && clearTool) {
			this.setCurrTool(null);
		}

		const chosenGlass = this.chosenGlassService.getChosenGlass();
		const chosenProfile = this.chosenProfileService.getChosenProfile();
		const chosenMarquise = this.chosenMarquiseService.getChosenMarquise();
		const chosenExtraOption = this.chosenExtraOptionService.getChosenOption();

		if (chosenGlass !== null) {
			this.chosenGlassService.setChosenGlass(null);
		} else if (chosenProfile !== null) {
			this.chosenProfileService.setChosenProfile(null);
		} else if (chosenMarquise !== null) {
			this.chosenMarquiseService.setChosenMarquise(null);
		} else if (chosenExtraOption !== null) {
			this.chosenExtraOptionService.setChosenOption(null);
		}
		this.chosenProfileService.chosenProfileSubj.next(null);
		if (this.projectService.profileService.roofElements) {
			this.projectService.profileService.roofElements[ElementType.MarquiseTopUpMouseover] = [];
			this.projectService.profileService.roofElements[ElementType.MarquiseTopBottomMouseover] = [];
		}

	}
}
