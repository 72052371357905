import { IRect } from "src/app/_core/interfaces/IRect";
import { Orientation, ElementType } from 'src/app/_core/models/ToolboxModel';
import { AreaType, RectName } from "src/app/_core/interfaces/IAreaType";
import { ILine } from '../../interfaces/ILine.interface';
import { ProjectTemplate } from "../../template";
import { ISize } from "../wizard/wizard-parameter.model";
import { ICuttingable } from "../../interfaces/ICuttingable";
import { LayerLevel } from "../../interfaces/LayerLevel";
import { IElement } from "../../interfaces/IElement";
import { Common } from "../common";

export abstract class Profile implements ICuttingable, IElement {

	abstract get type(): ElementType;
	abstract get name(): string;
	abstract get layer(): LayerLevel;

	public get middleX(): number {
		return 0;
	}
	
	public get cuttingable(): boolean {
		return true;
	}

	private _length: number;
	public get length(): number {
		return this._length;
	}
	public set length(value: number) {
		this._length = value;
	}

	private _height: number;
	public get height(): number {
		return this._height;
	}
	public set height(value: number) {
		this._height = value;
	}

	private _width: number;
	public get width(): number {
		return this._width;
	}
	public set width(value: number) {
		this._width = value;
	}

	public depth: number;
	public color: string;


	private _configId: string;
	get configId(): string {
		return this._configId
	}
	set configId(v: string) {
		this._configId = v;
	}

	id: string;
	orientation: Orientation;

	locked: boolean;
	frozen: boolean;
	toolTip: string;

	rectOnRoof: IRect;
	private _rectOnFront: IRect;
	private _rectOnLeft: IRect;
	private _rectOnRight: IRect;
	private _rectOnRear: IRect;

	public get rectOnFront(): IRect {
		return this._rectOnFront;
	}
	public set rectOnFront(value: IRect) {
		this._rectOnFront = value;
	}
	public get rectOnLeft(): IRect {
		return this._rectOnLeft;
	}
	public set rectOnLeft(value: IRect) {
		this._rectOnLeft = value;
	}
	public get rectOnRight(): IRect {
		return this._rectOnRight;
	}
	public set rectOnRight(value: IRect) {
		this._rectOnRight = value;
	}
	public get rectOnRear(): IRect {
		return this._rectOnRear;
	}
	public set rectOnRear(value: IRect) {
		this._rectOnRear = value;
	}
	public getRect(areaType: AreaType): IRect {
		const rc = RectName.get(areaType);
		return this[rc];
	}

	lineOnRoof: ILine;
	lineOnFront: ILine;
	lineOnLeft: ILine;
	lineOnRight: ILine;

	tag: string = "";
	initPosition: AreaType;

	cuttingOnSite: boolean = false;

	constructor(orientation: Orientation = Orientation.Vertical, size: ISize = null, color: string = "") {
		this.orientation = orientation;
		if (size != null) {
			this.width = size.width;
			this.depth = size.depth;
			this.height = size.height;
		}
		this.color = color;
	}

	public getLength(): number {
		return Common.round(this.length)
	}

	public setLength(value: number): void {
		this.length = Common.round(value);
	}

	// Default properties of bars

	getBarsDefaultWidth(template: ProjectTemplate): number {
		return this.width;
	}

	getBarsDefaultHeight(template: ProjectTemplate): number {
		return this.height;
	}

	getBarsDefaultDepth(template: ProjectTemplate): number {
		return this.depth;
	}

	// Default properties of fronts

	getFrontsDefaultWidth(template: ProjectTemplate): number {
		const profile = template.fronts.find(item => item.id === this.configId);
		return profile.size.width
	}

	getFrontsDefaultHeight(template: ProjectTemplate): number {
		const profile = template.fronts.find(item => item.id === this.configId);
		return profile.size.height
	}

	getFrontsDefaultDepth(template: ProjectTemplate): number {
		const profile = template.fronts.find(item => item.id === this.configId);
		return profile.size.depth
	}

}
